import React from 'react';

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'

import { DataContext } from "../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";


class ItemViewScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _getItemId = () => {
        return this.props.params.id;
    }

    _getItemName = () => {
        return this.props.params.name;
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getItemById(this._getItemId());
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { dataByItemId } = dataContext;
                if(!this.state.isLoading && dataByItemId !== null) {
                    return(
                        <>
                            <div>Item View Screen</div>
                        </>
                    )
                }
                if(this.state.isLoading && dataByItemId === null) {
                    return(
                        <>
                            <LoadingSpinner />
                        </>
                    )
                }
            }}</DataContext.Consumer>
        );
    };
};

ItemViewScreen.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();

    return <ItemViewScreen {...props} navigation={navigation} params={params} />;
}
