const CONFIG = {
    env: {
        app_env: 'localhost',
        defaultAPIVersion: 1,
        app_name: 'Elis PWA - POC v1.0'
    },
    host: {
        production : '',
        staging : '',
        localhost: 'http://elisapp.test'
    },
    assets: {
        production: null,
        staging: null
    },
    hero: {
        words:['Workwear','Flat linen', 'Washroom', 'Cleanroom']
    }
}

export default CONFIG;
