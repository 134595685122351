import React from 'react';
import './ProductGridLayout.scoped.css'
import GridItem from "./ProductGridItem";

import {Link} from "react-router-dom";

// context
import { DataContext } from "../../context/DataContext";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

class ProductGridLayout extends React.Component {

    _isMounted = false;

    constructor( props ) {
        super( props );
        this.state = {
            isLoading: true
        };
    }
    _init = async () => {
        if( this._isMounted ) {
            await this.context.getProductsByMenuId(this.props.parentId);
            this.setState({isLoading: false});
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <DataContext.Consumer>{( dataContext ) => {
                const { productsByMenuId } = dataContext;
                if(this.state.isLoading && productsByMenuId == null) {
                    return(
                        <>
                            <LoadingSpinner />
                        </>
                    )
                }
                if(!this.state.isLoading  && productsByMenuId != null){
                    return (
                        <div className="gridlayout-container container scrollable-div">
                            <div className="row gx-2">
                                {
                                    productsByMenuId.map((gridItem) => {
                                        if(gridItem) {
                                            return (
                                                <div className="col-6 col-md-3" key={gridItem.Id}>
                                                    <Link
                                                        to={(gridItem?.children === true ? `/category-group/${gridItem.Id}/` : `/product/${gridItem.Id}/`)}>
                                                        <GridItem
                                                            navPath={(gridItem.children === true ? `/category-group/${gridItem.Id}/` : `/product/${gridItem.Id}/`)}
                                                            gridItem={gridItem} showButtons={this.props.showButtons}
                                                            showDescription={this.props.showDescription}/>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            }}</DataContext.Consumer>
        );
    }
};

ProductGridLayout.contextType = DataContext;

export default function( props ) {
    return <ProductGridLayout {...props} />;
}
