/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
import * as Api from './api';
// lodash - https://lodash.com/
import _ from 'lodash';

/*******************************************************************************
 * Responses
 ******************************************************************************/

const _onSuccess = ( data ) => {
    if( _.get( data, 'data.data' ) )
        return _.get( data, 'data.data' );

    if( _.get( data, 'data' ) )
        return _.get( data, 'data' );

    return data;
};

const _onFailed = ( error ) => new Promise(( resolve, reject ) => {
    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error;
    reject( _.get( error_obj, 'data', null ) || error_obj );
});

/**
 * login success
 *
 * @param {obj} data
 * @return {obj} le data
 */
const _onLoginSuccess = ( response ) => {
    if( !response )
        return _onFailed({ error: 'Login failed' });

    const data = {

    };

    return data;

};

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * login
 *
 * @return {promise}
 */
export const login = payload =>
    Api.login( payload )
        .then( _onLoginSuccess )
        .catch( _onFailed );

/**
 * get currently authenticated user
 *
 * @return {promise}
 */
// export const user = () =>
//     Api.user()
//         .then( _onSuccess )
//         .catch( _onFailed );
