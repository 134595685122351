/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
import db from '../../db/application-db';

import Dexie from "dexie";

import { assetSeed } from "../../data-exports/assets";
import { menuSeed } from "../../data-exports/menu";
import { productMenuManagerSeed } from '../../data-exports/product_menu_management';
import { productSeed } from  '../../data-exports/product';
import { productAttributeSeed } from  '../../data-exports/product_attributes';
import { colourSeed } from "../../data-exports/colours";
import { assetManagementSeed } from "../../data-exports/asset_management";
import { filterManagerSeed } from "../../data-exports/filters_polymorphic";
import { productFilterSeed } from '../../data-exports/product_filters';
import { colourAttributeSeed } from "../../data-exports/colour_attributes";
// import { colorSeed } from "../../data-exports/colours";





// import { rankingSeed } from  '../../data-exports/ranking';

// import { categorySeed } from  '../../db/seeds/Categories'
// import { groupSeed } from  '../../db/seeds/Groups'
// import { lockerSeed } from "../../db/seeds/Locker";
// import { customerSeed } from "../../db/seeds/Customers";
// import { assetManagerSeed } from "../../db/seeds/AssetManager";
// import { attributeSeed } from "../../db/seeds/attributeSeed";
//
// import { filterSeed } from "../../db/seeds/filter";
import React from "react";


/*******************************************************************************
 * Responses
 ******************************************************************************/

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

export const _isOnline = () => {
    return navigator.onLine;
}

/*******************************************************************************
 * Public methods
 ******************************************************************************/

export const getAssets = async () => {
    let data = db.assets.toArray();

    if(data.length === undefined) {
        await seedAssets();
        data = db.assets?.toArray();
    }
    return data
}
/**
 * Gets root Menu from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getRootMenu = async () => {
    let data = db.menu.toArray();

    if(data.length === undefined) {
        await seedMenu();
        data = db.menu?.where('parentId').equals(0).toArray();
    }
    return data
}
/**
 * Gets products assigned to Menu from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getMenuAssignedProducts = async () => {
    let data = db.productMenuManagement.toArray();

    if(data.length === undefined) {
        await seedMenuAssignedProducts();
        data = db.productMenuManagement?.toArray();
    }
    return data
}
/**
 * Gets products  from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProducts = async () => {
    let data = db.product.toArray();

    if(data.length === undefined) {
        await seedProducts();
        data = db.product?.toArray();
    }
    return data
}
/**
 * Gets products  from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getColourAttributes = async () => {
    let data = db.colourAttributes.toArray();

    if(data.length === undefined) {
        await seedColourAttributes();
        data = db.colourAttributes?.toArray();
    }
    return data
}
/**
 * Gets products  from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getAssetManagement = async () => {
    let data = db.assetManagement.toArray();

    if(data.length === undefined) {
        await seedAssetManagement();
        data = db.assetManagement?.toArray();
    }
    return data
}
/**
 * Gets filter Manager from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getFilterManagement = async () => {
    let data = db.filterPolymorphic.toArray();

    if(data.length === undefined) {
        await seedFilterManagement();
        data = db.filterPolymorphic?.toArray();
    }
    return data
}
/**
 * Gets color Managment  from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getColours = async () => {
    let data = db.colours.toArray();

    if(data.length === undefined) {
        await seedColours();
        data = db.colours?.toArray();
    }
    return data
}
/**
 * Gets products  from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductAttributes = async () => {
    let data = db.productAttributes.toArray();

    if(data.length === undefined) {
        await seedProductAttributes();
        data = db.productAttributes?.toArray();
    }
    return data
}
/**
 * Gets products  from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductFilters = async () => {
    let data = db.productFilters.toArray();

    if(data.length === undefined) {
        await seedProductFilters();
        data = db.productFilters?.toArray();
    }
    return data
}
/**
 * Gets filter by Id from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getFilterById = filterId => {
    return db.productFilters.where('Id').equals(parseInt(filterId)).toArray();
}
/**
 * Check if Menu has Children from the local IndexedDB
 * @returns {Promise<*>}
 */
export const checkMenuForChildren = async menuId => {
        let data = db.menu?.where('parentId').equals(parseInt(menuId));

        if(data.length === undefined) {
            return false;
        }
        return true;
    }
/**
 * Gets root Menu Products with assets from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getRootMenuProductsWithAssets = async () => {
    return processCollection(0);
}
/**
 * Gets child Menu by MenuId from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getChildMenuByMenuId = menuId => {
    return processCollection(menuId);
}
/**
 * Gets products by MenuId from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductsByMenuId = async menuId => {
    const productMenuAssignedList:Array = await db.productMenuManagement.where('menuId').equals(parseInt(menuId)).toArray()

    let productData = [];
    await Promise.all(
        productMenuAssignedList.map(async (product) => {
            productData.push(await db.product.where('Id').equals(parseInt(product.productId)).first());
        })
    );

    return productData;
}
/**
 * Gets product by productId from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductById = async productId => {
    return await db.product.where('Id').equals(parseInt(productId)).first()
}
/**
 * Gets product colours by productId from the local IndexedDB
 * @returns {Promise<*>}
 */
// export const getProductColoursById = async productId => {
//     return await db.colours.where('productId').equals(parseInt(productId)).toArray()
// }
/**
 * Gets product colours by productId from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductColoursById = async productId => {
    const productColoursAssignedList:Array = await db.colours.where('productId').equals(parseInt(productId)).toArray()

    await Promise.all(
        productColoursAssignedList.map(async (colour) => {
            colour.productCodes = await db.colourAttributes.where('colourId').equals(parseInt(colour.Id)).toArray();
        })
    );

    return productColoursAssignedList;
}
// /**
//  * Gets product assets by productId from the local IndexedDB
//  * @returns {Promise<*>}
//  */
// export const getAssetImagesByProductId = async productId => {
//     return await db.asset.where('productId').equals(parseInt(productId)).toArray()
// }
/**
 * Gets product attributes by productId from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductAttributesById = async productId => {
    return await db.productAttributes.where('productId').equals(parseInt(productId)).toArray()
}
/**
 * Gets product attributes by productId from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductAssetsById = async productId => {

    const productAssetAssignedList:Array = await db.assetManagement.where('productId').equals(parseInt(productId)).toArray()



    let assetData = [];
    await Promise.all(
        productAssetAssignedList.map(async (asset) => {
            assetData.push(await db.assets.where('Id').equals(parseInt(asset.assetId)).first());
        })
    );

    return assetData;
}
/**
 * Gets product attributes by productId from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getMenuAssetsById = async menuId => {
    const menuAssetAssignedList:Array = await db.assetManagement.where('menuId').equals(parseInt(menuId)).toArray()

    let assetData = [];
    await Promise.all(
        menuAssetAssignedList.map(async (asset) => {
            assetData.push(await db.assets.where('Id').equals(parseInt(asset.assetId)).first());
        })
    );

    return assetData;
}
export const processCollection = (menuId= 0) =>  {
    let all = Dexie.Promise.all;

    let menuCollection = db.menu.where('parentId').equals(parseInt(menuId));

    return menuCollection.toArray(function(menus) {

        let assetPromises = menus.map(function (menu) {
            return db.assetManagement.where({ menuId: menu.Id }).first()
        });

        return all ([
            all(assetPromises),
        ]).then(function (assets) {
            menus.forEach(function (menu, i) {
                menu.image = assets[0][i];
            });
            return menus;
        });
    });
}
/**
 * Gets product Filters by ProductIds from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductFilter = async (productId) => {
    return await db.filterPolymorphic.where('productId').equals(productId).first();
    // const data = await db.filterPolymorphic.where('categoryId').equals(parseInt(categoryId)).toArray()
    //
    // const promises = await Promise.all(data.map(async (filters) => {
    //     const _filter = await getFilterById(filters.filterId)
    //     return {
    //         name: _filter[0].name,
    //         Id: _filter[0].Id
    //     };
    // }));
    //
    // return [...new Map(promises.map(item => [item['Id'], item])).values()];
}

/**
 * Gets product Filters by ProductIds from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductFiltersByMenuId = async (menuId) => {
    const productList = await getProductsByMenuId(menuId);


    let filterData = [];
    await Promise.all(
        productList.map(async (product) => {
            let filter = await getProductFilter(product.Id);
            if(filter){
                filterData.push(filter);
            }
        })
    );

    const promises = await Promise.all(filterData.map(async (filter) => {
        const _filter = await getFilterById(filter.filterId)
        return {
            name: _filter[0].name,
            Id: _filter[0].Id,
            selected: false
        };
    }));

    return [...new Map(promises.map(item => [item['Id'], item])).values()];
}
/**
 * Gets menu Parent by menu Id from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getMenuParentByMenuId = async (menuId) => {
    return await db.menu.where('Id').equals(parseInt(menuId)).first();
}
/**
 * Gets menu Root name by menu Id and Product Id from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getMenuRootNameByMenuId = async (menuId, productId) => {
    return await db.menu.where({Id: parseInt(menuId), parentId: parseInt(productId)}).first();
}
/**
 * Gets products by groupId and using filters from the local IndexedDB
 * @returns {Promise<*>}
 */
export const getProductsByMenuId_Filtered = async (menuId, filters) => {
    /*
    Get All Product By Menu Id
    Get All Selected Filters
    Check Product has the filter
    Return products that match the filter
     */
    const productList = await getProductsByMenuId(menuId);

    let selectedFilters = filters.filter(function(filter) {
        return filter.selected === true;
    }).map(function(filter) {
        return filter.Id;
    });

    if(selectedFilters.length === 0) {
        return getProductsByMenuId(menuId);
    }


    const promises = await Promise.all(productList.map(async (product) => {
        const _filter = await getProductFilter(product.Id)
        let _return = {
            Id: null
        };

        if(_filter) {
            if(selectedFilters.includes(_filter.filterId)) {
                return {
                    Id: product.Id
                };
            }
        }

        return _return;
    }));

    const products = [...new Map(promises.map(item => [item['Id'], item])).values()];

    let productData = [];
    await Promise.all(
        products.map(async (product) => {
            if(product.Id) {
                productData.push(await db.product.where('Id').equals(parseInt(product.Id)).first());
            }
        })
    );

    return productData;
}
/*******************************************************************************
 * IndexedDB Methods
 ******************************************************************************/
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param name
 * @param path
 * @param alt
 * @returns {Promise<void>}
 */
export const addAsset = async ( Id, name, path, alt ) => {
    await db.assets.get({Id: Id})
        .then(asset => {
            !asset &&
            db.assets.add({Id: Id, name: name, path: path, alt: alt});
        });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param name
 * @param hex
 * @param productId
 * @param position
 * @returns {Promise<void>}
 */
export const addColours = async ( Id, name, hex, productId, position ) => {
    await db.colours.get( { Id: Id } )
    .then(colours => {
        !colours &&
        db.colours.add( {Id:Id, name: name, hex: hex, productId: productId, position: position } );
    });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param assetId
 * @param menuId
 * @param productId
 * @param position
 * @returns {Promise<void>}
 */
export const addAssetManagement = async ( Id, assetId, menuId, productId, position ) => {
    await db.assetManagement.get( { Id: Id } )
        .then(assetManagement => {
            !assetManagement &&
            db.assetManagement.add( {Id:Id, assetId: assetId, menuId: menuId, productId: productId, position: position } );
        });
}
/*******************************************************************************
 * IndexedDB Methods
 ******************************************************************************/
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param name
 * @param description
 * @param rankingId
 * @returns {Promise<void>}
 */
export const addProduct = async ( Id, name, description, rankingId ) => {
    await db.product.get( { Id: Id } )
        .then(product => {
            !product &&
            db.product.add( {Id:Id, name: name, description: description, rankingId: rankingId} );
        });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param title
 * @param description
 * @param parentId
 * @returns {Promise<void>}
 */
export const addMenu = async ( Id, title, description, parentId, children ) => {
    await db.menu.get( { Id: Id } )
        .then(menu => {
            !menu &&
            db.menu.add( {Id:Id, title: title, description: description, parentId: parentId, children:children} );
        });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param title
 * @param description
 * @param parentId
 * @returns {Promise<void>}
 */
export const addMenuManager = async ( Id, menuId, productId, position ) => {
    await db.productMenuManagement.get( { Id: Id } )
        .then(menu => {
            !menu &&
            db.productMenuManagement.add( { Id:Id, menuId: menuId, productId: productId, position: position } );
        });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param productId
 * @param name
 * @param data
 * @param position
 * @returns {Promise<void>}
 */
export const addProductAttribute = async ( Id, productId, name, data, position ) => {
    await db.productAttributes.get( { Id: Id } )
        .then(productAttribute => {
            !productAttribute &&
            db.productAttributes.add( { Id:Id, productId: productId, name: name, data: data, position: position } );
        });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param productId
 * @param filterId
 * @param position
 * @returns {Promise<void>}
 */
export const addFilterManager = async ( Id, productId, filterId, position ) => {
    await db.filterPolymorphic.get( { Id: Id } )
        .then(filterManager => {
            !filterManager &&
            db.filterPolymorphic.add( { Id:Id, productId: productId, filterId: filterId, position: position } );
        });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param name
 * @returns {Promise<void>}
 */
export const addProductFilter= async ( Id, name ) => {
    await db.productFilters.get( { Id: Id } )
        .then(filters => {
            !filters &&
            db.productFilters.add( { Id:Id, name: name } );
        });
}
/**
 * Adds data from the local JS data files or from being Invoked and adds them to the IndexedDB
 * @param Id
 * @param colourId
 * @param name
 * @param productcode
 * @param position
 * @returns {Promise<void>}
 */
export const addColourAttribute= async ( Id, colourId, name, productcode, position ) => {
    await db.colourAttributes.get( { Id: Id } )
        .then(attribute => {
            !attribute &&
            db.colourAttributes.add( { Id:Id, colourId: colourId, name: name, productcode:productcode, position:position } );
        });
}
/*******************************************************************************
 * Data Seeding Methods
 ******************************************************************************/
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedAssets = async () => {
    assetSeed.forEach((asset) => {
        addAsset(asset.Id, asset.name, asset.path, asset.alt);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedColours = async () => {
    colourSeed.forEach((colours) => {
        addColours(colours.Id, colours.name, colours.hex, colours.productId, colours.position);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedProductAttributes = async () => {
    productAttributeSeed.forEach((productAttribute) => {
        addProductAttribute(productAttribute.Id, productAttribute.productId, productAttribute.name, productAttribute.data, productAttribute.position);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedMenuAssignedProducts = async () => {
    productMenuManagerSeed.forEach((menuManager) => {
        addMenuManager(menuManager.Id, menuManager.menuId, menuManager.productId, menuManager.position);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedMenu= async () => {
    menuSeed.forEach((menu) => {
        addMenu(menu.Id, menu.title, menu.description, menu.parentId, menu.children);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedProducts= async () => {
    productSeed.forEach((product) => {
        addProduct(product.Id, product.name, product.description, product.rankingId);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedAssetManagement = async () => {
    assetManagementSeed.forEach((assetManagement) => {
        addAssetManagement(assetManagement.Id, assetManagement.assetId, assetManagement.menuId, assetManagement.productId, assetManagement.position);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedFilterManagement = async () => {
    filterManagerSeed.forEach((filterManager) => {
        addFilterManager(filterManager.Id, filterManager.productId, filterManager.filterId, filterManager.position);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedProductFilters = async () => {
    productFilterSeed.forEach((filter) => {
        addProductFilter(filter.Id, filter.name);
    })
}
/**
 * Fetches data from the local JS data files and imports them to the IndexedDB
 * @returns {Promise<void>}
 */
export const seedColourAttributes = async () => {
    colourAttributeSeed.forEach((attribute) => {
        addColourAttribute(attribute.Id, attribute.colourId, attribute.name, attribute.productcode, attribute.position);
    })
}
export const importData = async () => {
    await getAssets();
    await getRootMenu();
    await getMenuAssignedProducts();
    await getProducts();
    await getProductAttributes();
    await getColours();
    await getAssetManagement();
    await getFilterManagement();
    await getProductFilters();
    await getColourAttributes();
}

