import React from 'react';

// context
import { DataContext } from "../context/DataContext";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import AppContent from "../components/AppContent/AppContent";
import {useNavigate, useParams} from "react-router-dom";
import GridLayout from "../components/ProductGridLayout/ProductGridLayout";
import FilterItem from "../components/GridLayout/FilterItem";
import BackButton from "../components/BackButton/BackButton";

class ProductGroupItemScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
            pageTitle: '',
            menuId: null,
            filterList: [],
            parentDetails: '',
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getProductsByMenuId(this._getMenuId());
            const filterList:Array  = await this.context.getProductFiltersByMenuId(this._getMenuId());
            //let parentDetails = await this.context.getMenuParentByMenuId(this._getParentId(), true);
            //if(!parentDetails){
                let parentDetails = await this.context.getMenuRootNameByMenuId(this._getMenuId(), this._getParentId());
            //}
            this.setState({ loadFailed: false, isLoading: false, filterList: filterList, parentDetails: parentDetails });
        }
    }

    _getParentId = () => {
        this.setState({
            menuId: this.props.params.parent
        })
        return this.props.params.parent;
    }

    _getMenuId = () => {
        this.setState({
            menuId: this.props.params.id
        })
        return this.props.params.id;
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.state.menuId !== nextProps.params.id) {
            await this._init();
        }
    }

    _filterSelected = async filter => {
        const cfilterList = [...this.state.filterList];

        let objIndex = this.state.filterList.findIndex((obj => obj.Id === filter.Id));
        cfilterList[objIndex].selected = !cfilterList[objIndex].selected;

        await this.context.getProductsByMenuId_Filtered(this._getMenuId(), this.state.filterList);
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { productsByMenuId } = dataContext;

                if(this.state.isLoading || productsByMenuId == null){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading){
                    return (
                        <>
                            <BackButton />
                            <h1 className={'page-title text-center text-uppercase font-weight-bold mt-4'}>{this.state?.parentDetails?.title}</h1>
                            <blockquote className="text-center font-weight-normal mt-4 mb-4">
                                <p className={'page-description'}>
                                    {this.state.parentDetails?.description}
                                </p>
                                {
                                    this.state.filterList &&
                                        this.state.filterList.length > 0 &&
                                    <div className="d-flex flex-sm-row flex-column justify-content-center flex-wrap align-items-sm-stretch mb-4">
                                        {
                                            this.state.filterList.map((filter, index) => (
                                                <div className='mx-2 mb-2' key={index}>
                                                    <FilterItem filterSelected={this._filterSelected} filter={filter} selected={filter.selected} />
                                                </div>
                                            ))

                                        }
                                    </div>
                                }

                            </blockquote>
                            <GridLayout parentId={this.state.menuId} gridSource={productsByMenuId} showButtons={false} showDescription={false}  />
                        </>
                    )
                };
            }}</DataContext.Consumer>
        );
    };
};

ProductGroupItemScreen.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();
    return <ProductGroupItemScreen {...props} navigation={navigation} params={params} />;
}
