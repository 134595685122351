import React from 'react';
import './LoadingSpinner.scoped.css'

class LoadingSpinner extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { };
    }

    render() {
        return (
            <div className="loader"></div>
        )
    }
}

export default function( props ) {
    return <LoadingSpinner {...props} />;
}
