import React from 'react';
import { DataContext } from "../context/DataContext";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import CategoryGroup from "../components/CategoryGroup/CategoryGroup";

export default class CategoryIndexScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {
        super( props );
        this.state = {
            isLoading: true,
            loadFailed: false
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getCategoriesWithGroup();
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { categoryList, categoryListWithGroups } = dataContext;
                if(this.state._isLoading){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state._isLoading && categoryListWithGroups != null ) {
                    return (
                        <>
                            {
                                categoryListWithGroups.map((category, i) => {
                                  return(<CategoryGroup key={i} category={category} />)
                                })
                            }
                        </>
                    )
                }
            }}</DataContext.Consumer>
        );
    };
};

CategoryIndexScreen.contextType = DataContext;
