import React from 'react';
import './CategoryGroup.scoped.css'

import db from '../../db/application-db';

//https://sag1v.github.io/react-elastic-carousel
//https://www.npmjs.com/package/@itseasy21/react-elastic-carousel fork v18
import Carousel from '@itseasy21/react-elastic-carousel'
import Category from '../Category/Category';

class CategoryGroup extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    render() {
        if(this.props.category){
            return (
                <>
                    <h1>{this.props.category.name}</h1>
                    <Carousel className={'category-container'} itemsToShow={3.5} showArrows={false} pagination={false} itemPadding={[0,0,10,10]}>
                        {
                            this.props.category.group.map((group, index) => {
                                return(<Category className={'category-card'} key={index} group={group} />)
                            })
                        }
                    </Carousel>
                </>
            )
        }
    }
}

export default function( props ) {
    return <CategoryGroup {...props} />;
}
