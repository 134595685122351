import React from 'react';

// context
import { DataContext } from "../context/DataContext";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import AppContent from "../components/AppContent/AppContent";
import {Link, useNavigate, useParams} from "react-router-dom";
import GridLayout from "../components/GridLayout/GridLayout";
import GridItem from "../components/GridLayout/GridItem";
import {Button, Card} from "react-bootstrap";
import FilterItem from "../components/GridLayout/FilterItem";

class CategoryItemScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
            pageTitle: '',
            category: '',
            filterList: [],
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            //const productList2:Array = await this.context.getProductsByCategoryId(this._getCategoryId());
            const filterList:Array = await this.context.getCategoryFilters(this._getCategoryId());
            const productList:Array = await this.context.getProductsByCategoryId_Filters(this._getCategoryId(), filterList);
            const categoryName = await this.context.getCategoryById(this._getCategoryId());

            filterList.map((filter) => {
                filter.selected = false;
            })

            this.setState({ loadFailed: false, isLoading: false, category: categoryName[0], filterList: filterList, productList: productList });
        }
    }

    _getCategoryId = () => {
        return this.props.params.id;
    }

    _getCategoryName = () => {
        return this.state.category.name
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _isSelected = (filterId) => {
        return this.state.filterList.some(filter => {
            if (filter.Id === filterId && filter.selected === true) {
                return true;
            }
            return false;
        });
    }

    _filterSelected = async filter => {

        const cfilterList = [...this.state.filterList];
        let objIndex = this.state.filterList.findIndex((obj => obj.Id === filter.Id));

        cfilterList[objIndex].selected = !cfilterList[objIndex].selected;

        const productList:Array = await this.context.getProductsByCategoryId_Filters(this._getCategoryId(), this.state.filterList);

        this.setState({
            filterList: cfilterList,
            productList: productList
        })
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const {  } = dataContext;
                if(this.state.isLoading){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading && this.state.productList != null && this.state.filterList.length > 0){
                    return (
                        <>
                            <h1 className={'text-center text-uppercase font-weight-bold mt-4'}>{this._getCategoryName()}</h1>
                            <blockquote className="text-center font-weight-normal mt-4 mb-4">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.sss
                                </p>
                            </blockquote>

                                    <div className="d-flex flex-sm-row flex-column justify-content-center flex-wrap align-items-sm-stretch mb-4">
                                        {
                                            this.state.filterList.map((filter, index) => (
                                                <div className='mx-2 mb-2' key={filter.Id}>
                                                    <FilterItem filterSelected={this._filterSelected} filter={filter} selected={filter.selected} />
                                                </div>
                                            ))

                                        }
                                    </div>
                            <GridLayout navPath={'product'} gridSource={this.state.productList} showButtons={false} />
                        </>
                    )
                }
            }}</DataContext.Consumer>
        );
    };
};

CategoryItemScreen.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();
    return <CategoryItemScreen {...props} navigation={navigation} params={params} />;
}
