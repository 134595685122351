export const productFilterSeed = [
    {
        Id:1,
        name:"High Risk"
    },
    {
        Id:2,
        name:"Additional Protection"
    },
    {
        Id:3,
        name:"Arc"
    },
    {
        Id:4,
        name:"Aprons"
    },
    {
        Id:5,
        name:"Cantex"
    },
    {
        Id:6,
        name:"Plain Trousers"
    },
    {
        Id:7,
        name:"Check Trousers"
    },
    {
        Id:8,
        name:"Coloured"
    },
    {
        Id:9,
        name:"Chemical Splash Poly Cotton"
    },
    {
        Id:10,
        name:"Good"
    },
    {
        Id:11,
        name:"Best"
    },
    {
        Id:12,
        name:"Better"
    },
    {
        Id:13,
        name:"Endurance"
    },
    {
        Id:14,
        name:"Flame and Fire Plus Protection"
    },
    {
        Id:15,
        name:"Flame Protection"
    },
    {
        Id:16,
        name:"Flame Protection Cotton"
    },
    {
        Id:17,
        name:"Flamemaster "
    },
    {
        Id:19,
        name:"Industry Basics"
    },
    {
        Id:20,
        name:"Hi Vis Layering "
    },
    {
        Id:21,
        name:"Hi Vis Weather proof"
    },
    {
        Id:22,
        name:"Low Risk"
    },
    {
        Id:23,
        name:"Lumi"
    },
    {
        Id:24,
        name:"Max Risk"
    },
    {
        Id:25,
        name:"Molten Metal"
    },
    {
        Id:26,
        name:"Multisplash"
    },
    {
        Id:27,
        name:"Proban"
    },
    {
        Id:28,
        name:"Prolab"
    },
    {
        Id:29,
        name:"Reinforced Molten Metal"
    },
    {
        Id:30,
        name:"Revision"
    },
    {
        Id:31,
        name:"Sparkle"
    },
    {
        Id:32,
        name:"Welders"
    },
    {
        Id:33,
        name:"Zonal Marking"
    },
    {
        Id:34,
        name:"Additional Garments"
    },
    {
        Id:35,
        name:"Metal Industry"
    },
    {
        Id:36,
        name:"Graphic Industry"
    },
    {
        Id:37,
        name:"Automotive Industry"
    },
    {
        Id:38,
        name:"Phoenix"
    }
]