import React, {useState, useEffect} from 'react';
import './Layout.scoped.css'

// context
import { DataContext } from "../../context/DataContext";

import SidebarNav from "../SidebarNav/SidebarNav";

class Layout extends React.Component {

    _isMounted = false;

    constructor( props ) {
        super( props );
        this.state = {
            rootMenuWithAssets: null,
            isCollapsed: true,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            const rootMenuWithAssets = await this.context.getRootMenuProductsWithAssets();

            this.setState({
                rootMenuWithAssets: rootMenuWithAssets
            })
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    _closeMenu  = async () => {
        if(!this.context.sideBarClosed) {
            this.context.toggleSidebar()

            // let subCollections = document.getElementsByClassName("react-slidedown pro-inner-list-item");
            // for (let i = 0; i < subCollections.length; i++) {
            //     if(!subCollections[i].classList.contains('closed')){
            //         await this._sleep(500);
            //         subCollections[i].classList.add('closed');
            //     }
            // }
        }
    }

    render() {
        return (
            <main>
                <section>
                    <SidebarNav navSource={ this.state.rootMenuWithAssets } />
                    <section className={'appContent-Container'} onClick={this._closeMenu}>
                        {React.cloneElement(this.props.children, {...this.props})}
                    </section>
                </section>
            </main>
        )
    }
}

Layout.contextType = DataContext;

export default function( props ) {
    const [isOnline, setOnline] = useState(true);

    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });


    return <Layout {...props} isOnline={isOnline} />;
}
