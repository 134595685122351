import React from 'react';
import './Item.scoped.css'

import { Button, Card } from 'react-bootstrap';
import {Link} from "react-router-dom";

class Product extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { };
    }

    render() {
        return (
            <Card className={this.props.className}>
                {
                    this.props.itemData !== undefined &&
                        <>
                            <Card.Img variant="top" src={this.props.itemData.image} />
                            <Card.Body>
                                <Card.Title>{this.props.itemData.name}</Card.Title>
                                {/*<Card.Text>*/}
                                {/*    Some quick example text to build on the card title and make up the*/}
                                {/*    bulk of the card's content.*/}
                                {/*</Card.Text>*/}
                                <Button as={Link} to={'/item/' + this.props.itemData.productId + '/' + this.props.itemData.name } variant={'primary'} className={'button--green'}>Discover</Button>
                            </Card.Body>
                        </>
                }
            </Card>
        )
    }
}

export default function( props ) {
    return <Product {...props} />;
}
