import React from 'react';
import './AppContent.scoped.css'
// context
import { DataContext } from "../../context/DataContext";

// components
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import GridLayout from "../GridLayout/GridLayout";


class AppContent extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
            parentId: 0
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { childMenuByMenuId } = dataContext;
                if(this.state.isLoading){
                    return(
                        <>
                            <LoadingSpinner />
                        </>
                    )
                }
                if(!this.state.isLoading){
                    return (
                        <>
                            <h1 className={'text-center text-uppercase font-weight-bold mt-4'}>Welcome to Elis</h1>
                            <blockquote className="text-center font-weight-normal mt-4 mb-4">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                                </p>
                            </blockquote>
                            <GridLayout parentId={this.state.parentId} />
                        </>
                    )
                }
            }}</DataContext.Consumer>
        );
    };
};

AppContent.contextType = DataContext;

export default function( props ) {
    return <AppContent {...props} />;
}
