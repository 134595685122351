import React from 'react';
import './Intro.scoped.css';

import {Button, Col, Container, NavLink, Row} from "react-bootstrap";
import {Link} from "react-router-dom";



class Intro extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { };
    }

    render() {
        return (
            <>
                <Container>
                    <Row>
                        <Col xs={12} md={4}>
                            <h2 className="intro__title">
                                Supporting you every day
                            </h2>
                        </Col>
                        <Col xs={12} md={8}>
                            <p className="intro-text">
                                With more than a century of expertise, Elis is an international multi-service
                                provider, offering textile, hygiene and facility service solutions. Everyday, we
                                simplify our client's life thanks to customised solutions that free up time,
                                simplify processes and save money. We have one objective: to help our clients
                                focus on the core of their business so they may better welcome their customers,
                                assure the safety and well-being of their employees and ensure the quality of
                                their products.
                            </p>
                            {
                                this.props.categoryList != null &&
                                <ul className={'list'}>
                                    {
                                    this.props.categoryList.map((category, index) => {
                                        return(
                                            <li className="list__item" key={category.name}>
                                                <NavLink as={Link} to={ '/group/' + category.categoryId + '/' + category.name }  className="list__href">
                                                    {category.name}

                                                    <svg width="19" height="15" xmlns="http://www.w3.org/2000/svg">
                                                        <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd">
                                                            <path d="M0 7.5h17M18 7.998L11 1M18 7l-7 6.998"></path>
                                                        </g>
                                                    </svg>
                                                </NavLink>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </Col>
                    </Row>
                </Container>

                {/*<Button className={'button--green'} as={Link} to="/categories">View Products by Categories</Button>*/}
            </>
        )
    }
}

export default function( props ) {
    return <Intro {...props} />;
}
