import React from 'react';
import './Hero.scoped.css'

import ReactPlayer from 'react-player';
import { Button } from "react-bootstrap";
import {CSSTransition} from "react-transition-group";

import CONFIG from '../../Config';

class Hero extends React.Component {

    _isMounted = false;

    constructor( props ) {
        super( props );
        this.state = {
            interval: null,
            _timer: 5000,
            i: 0,
            wordCount: CONFIG.hero.words.length,
        };
    }

    _changeMessage = () => {
        if(this._isMounted){
            this.setState({i: this.state.i + 1});
            if (this.state.i >= 2)
                this.setState({i : 0});
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.state.interval = setInterval(() => {
            this._changeMessage();
        }, this.state._timer);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.state.interval)
    }

    render() {
        return (
            <section className={'hero__container'}>
                <ReactPlayer
                    url={this.props.video_url}
                    playing
                    loop
                    muted
                    width="100%"
                    height="100%"
                />
                <div className={'overlay'}>
                    <div className={'content-area'}>
                        <h1>We empower your day</h1>
                        <div style={{ height: '25px', overflow: 'hidden' }}>
                            { CONFIG.hero.words.map((word, index) => {
                                return(
                                    <CSSTransition key={index} in={this.state.i === index} classNames="animate__animated animate__slideInUp my-node" timeout={100} unmountOnExit>
                                        <div> {word} </div>
                                    </CSSTransition>
                                )
                            })}
                        </div>
                        {/*<Button>Click Me</Button>*/}
                    </div>
                </div>
            </section>
        )
    }
}

export default function( props ) {
    return <Hero {...props} />;
}
