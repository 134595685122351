export const filterManagerSeed = [
    {
        Id:2,
        productId:2,
        filterId:22,
        position:0
    },
    {
        Id:3,
        productId:3,
        filterId:22,
        position:0
    },
    {
        Id:4,
        productId:4,
        filterId:22,
        position:0
    },
    {
        Id:5,
        productId:5,
        filterId:1,
        position:0
    },
    {
        Id:6,
        productId:6,
        filterId:1,
        position:0
    },
    {
        Id:7,
        productId:7,
        filterId:1,
        position:0
    },
    {
        Id:8,
        productId:8,
        filterId:22,
        position:0
    },
    {
        Id:9,
        productId:9,
        filterId:1,
        position:0
    },
    {
        Id:10,
        productId:10,
        filterId:24,
        position:0
    },
    {
        Id:11,
        productId:11,
        filterId:24,
        position:0
    },
    {
        Id:12,
        productId:12,
        filterId:24,
        position:0
    },
    {
        Id:13,
        productId:13,
        filterId:24,
        position:0
    },
    {
        Id:14,
        productId:14,
        filterId:1,
        position:0
    },
    {
        Id:15,
        productId:15,
        filterId:24,
        position:0
    },
    {
        Id:16,
        productId:16,
        filterId:24,
        position:0
    },
    {
        Id:17,
        productId:17,
        filterId:24,
        position:0
    },
    {
        Id:18,
        productId:18,
        filterId:24,
        position:0
    },
    {
        Id:19,
        productId:19,
        filterId:33,
        position:0
    },
    {
        Id:20,
        productId:20,
        filterId:33,
        position:0
    },
    {
        Id:22,
        productId:21,
        filterId:8,
        position:0
    },
    {
        Id:23,
        productId:22,
        filterId:8,
        position:0
    },
    {
        Id:24,
        productId:23,
        filterId:8,
        position:0
    },
    {
        Id:25,
        productId:24,
        filterId:8,
        position:0
    },
    {
        Id:26,
        productId:25,
        filterId:34,
        position:0
    },
    {
        Id:27,
        productId:26,
        filterId:34,
        position:0
    },
    {
        Id:28,
        productId:27,
        filterId:34,
        position:0
    },
    {
        Id:29,
        productId:28,
        filterId:34,
        position:0
    },
    {
        Id:30,
        productId:29,
        filterId:34,
        position:0
    },
    {
        Id:31,
        productId:30,
        filterId:10,
        position:0
    },
    {
        Id:32,
        productId:31,
        filterId:10,
        position:0
    },
    {
        Id:33,
        productId:32,
        filterId:10,
        position:0
    },
    {
        Id:34,
        productId:33,
        filterId:10,
        position:0
    },
    {
        Id:35,
        productId:34,
        filterId:12,
        position:0
    },
    {
        Id:36,
        productId:35,
        filterId:12,
        position:0
    },
    {
        Id:37,
        productId:36,
        filterId:12,
        position:0
    },
    {
        Id:38,
        productId:37,
        filterId:11,
        position:0
    },
    {
        Id:39,
        productId:38,
        filterId:11,
        position:0
    },
    {
        Id:40,
        productId:39,
        filterId:6,
        position:0
    },
    {
        Id:41,
        productId:40,
        filterId:6,
        position:0
    },
    {
        Id:42,
        productId:41,
        filterId:8,
        position:0
    },
    {
        Id:43,
        productId:42,
        filterId:7,
        position:0
    },
    {
        Id:44,
        productId:43,
        filterId:7,
        position:0
    },
    {
        Id:45,
        productId:44,
        filterId:4,
        position:0
    },
    {
        Id:46,
        productId:45,
        filterId:4,
        position:0
    },
    {
        Id:47,
        productId:46,
        filterId:4,
        position:0
    },
    {
        Id:48,
        productId:47,
        filterId:34,
        position:0
    },
    {
        Id:49,
        productId:48,
        filterId:34,
        position:0
    },
    {
        Id:50,
        productId:49,
        filterId:34,
        position:0
    },
    {
        Id:51,
        productId:87,
        filterId:2,
        position:0
    },
    {
        Id:52,
        productId:88,
        filterId:2,
        position:0
    },
    {
        Id:53,
        productId:89,
        filterId:2,
        position:0
    },
    {
        Id:54,
        productId:90,
        filterId:2,
        position:0
    },
    {
        Id:55,
        productId:91,
        filterId:2,
        position:0
    },
    {
        Id:56,
        productId:92,
        filterId:3,
        position:0
    },
    {
        Id:57,
        productId:93,
        filterId:4,
        position:0
    },
    {
        Id:58,
        productId:94,
        filterId:5,
        position:0
    },
    {
        Id:59,
        productId:95,
        filterId:5,
        position:0
    },
    {
        Id:60,
        productId:96,
        filterId:5,
        position:0
    },
    {
        Id:61,
        productId:97,
        filterId:9,
        position:0
    },
    {
        Id:62,
        productId:98,
        filterId:9,
        position:0
    },
    {
        Id:63,
        productId:99,
        filterId:9,
        position:0
    },
    {
        Id:64,
        productId:102,
        filterId:35,
        position:0
    },
    {
        Id:65,
        productId:103,
        filterId:36,
        position:0
    },
    {
        Id:66,
        productId:104,
        filterId:37,
        position:0
    },
    {
        Id:67,
        productId:105,
        filterId:13,
        position:0
    },
    {
        Id:68,
        productId:106,
        filterId:13,
        position:0
    },
    {
        Id:69,
        productId:107,
        filterId:13,
        position:0
    },
    {
        Id:70,
        productId:108,
        filterId:14,
        position:0
    },
    {
        Id:71,
        productId:109,
        filterId:14,
        position:0
    },
    {
        Id:72,
        productId:110,
        filterId:14,
        position:0
    },
    {
        Id:73,
        productId:111,
        filterId:15,
        position:0
    },
    {
        Id:74,
        productId:112,
        filterId:16,
        position:0
    },
    {
        Id:75,
        productId:113,
        filterId:16,
        position:0
    },
    {
        Id:76,
        productId:114,
        filterId:16,
        position:0
    },
    {
        Id:77,
        productId:115,
        filterId:15,
        position:0
    },
    {
        Id:78,
        productId:116,
        filterId:15,
        position:0
    },
    {
        Id:79,
        productId:117,
        filterId:15,
        position:0
    },
    {
        Id:80,
        productId:118,
        filterId:17,
        position:0
    },
    {
        Id:81,
        productId:119,
        filterId:19,
        position:0
    },
    {
        Id:82,
        productId:120,
        filterId:19,
        position:0
    },
    {
        Id:83,
        productId:121,
        filterId:20,
        position:0
    },
    {
        Id:84,
        productId:122,
        filterId:20,
        position:0
    },
    {
        Id:85,
        productId:123,
        filterId:20,
        position:0
    },
    {
        Id:86,
        productId:124,
        filterId:20,
        position:0
    },
    {
        Id:87,
        productId:125,
        filterId:21,
        position:0
    },
    {
        Id:88,
        productId:126,
        filterId:21,
        position:0
    },
    {
        Id:89,
        productId:127,
        filterId:21,
        position:0
    },
    {
        Id:90,
        productId:128,
        filterId:21,
        position:0
    },
    {
        Id:91,
        productId:129,
        filterId:19,
        position:0
    },
    {
        Id:92,
        productId:130,
        filterId:19,
        position:0
    },
    {
        Id:93,
        productId:132,
        filterId:23,
        position:0
    },
    {
        Id:94,
        productId:133,
        filterId:23,
        position:0
    },
    {
        Id:95,
        productId:135,
        filterId:25,
        position:0
    },
    {
        Id:96,
        productId:136,
        filterId:25,
        position:0
    },
    {
        Id:97,
        productId:137,
        filterId:25,
        position:0
    },
    {
        Id:98,
        productId:138,
        filterId:26,
        position:0
    },
    {
        Id:99,
        productId:139,
        filterId:26,
        position:0
    },
    {
        Id:100,
        productId:140,
        filterId:26,
        position:0
    },
    {
        Id:101,
        productId:141,
        filterId:26,
        position:0
    },
    {
        Id:102,
        productId:142,
        filterId:26,
        position:0
    },
    {
        Id:103,
        productId:143,
        filterId:26,
        position:0
    },
    {
        Id:104,
        productId:144,
        filterId:26,
        position:0
    },
    {
        Id:105,
        productId:145,
        filterId:26,
        position:0
    },
    {
        Id:106,
        productId:148,
        filterId:38,
        position:0
    },
    {
        Id:107,
        productId:149,
        filterId:38,
        position:0
    },
    {
        Id:108,
        productId:150,
        filterId:38,
        position:0
    },
    {
        Id:109,
        productId:151,
        filterId:38,
        position:0
    },
    {
        Id:110,
        productId:152,
        filterId:38,
        position:0
    },
    {
        Id:111,
        productId:153,
        filterId:38,
        position:0
    },
    {
        Id:112,
        productId:154,
        filterId:38,
        position:0
    },
    {
        Id:113,
        productId:155,
        filterId:38,
        position:0
    },
    {
        Id:114,
        productId:159,
        filterId:27,
        position:0
    },
    {
        Id:115,
        productId:160,
        filterId:28,
        position:0
    },
    {
        Id:116,
        productId:161,
        filterId:28,
        position:0
    },
    {
        Id:117,
        productId:162,
        filterId:28,
        position:0
    },
    {
        Id:118,
        productId:163,
        filterId:29,
        position:0
    },
    {
        Id:119,
        productId:164,
        filterId:29,
        position:0
    },
    {
        Id:120,
        productId:165,
        filterId:29,
        position:0
    },
    {
        Id:121,
        productId:166,
        filterId:30,
        position:0
    },
    {
        Id:122,
        productId:167,
        filterId:30,
        position:0
    },
    {
        Id:123,
        productId:168,
        filterId:30,
        position:0
    },
    {
        Id:124,
        productId:170,
        filterId:31,
        position:0
    },
    {
        Id:125,
        productId:171,
        filterId:31,
        position:0
    },
    {
        Id:126,
        productId:172,
        filterId:31,
        position:0
    },
    {
        Id:127,
        productId:177,
        filterId:3,
        position:0
    },
    {
        Id:128,
        productId:178,
        filterId:32,
        position:0
    },
    {
        Id:129,
        productId:179,
        filterId:32,
        position:0
    },
    {
        Id:130,
        productId:180,
        filterId:32,
        position:0
    },
    {
        Id:131,
        productId:181,
        filterId:36,
        position:0
    },
    {
        Id:132,
        productId:182,
        filterId:37,
        position:0
    },
    {
        Id:133,
        productId:183,
        filterId:35,
        position:0
    },
    {
        Id:134,
        productId:184,
        filterId:36,
        position:0
    },
    {
        Id:135,
        productId:185,
        filterId:37,
        position:0
    }
]