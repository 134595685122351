import React from 'react';
import './ProductCarousel.scoped.css'
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation, Thumbs} from "swiper";
import {DataContext} from "../../context/DataContext";
import {useNavigate, useParams} from "react-router-dom";

class ProductCarousel extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {
        super( props );
        this.state = {
            thumbsSwiper: null
        };
    }

    _setThumbsSwiper = (e) => {
        this.setState({
            thumbsSwiper: e
        })
    }
    _getItemId = () => {
        console.log(this.props)
        return this.props.params.id;
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getProductAssetsById(this._getItemId());
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <DataContext.Consumer>{( dataContext ) => {
                const { productAssetsById } = dataContext;


                if(this._isLoading){

                }
                if(!this._isLoading && productAssetsById){
                    console.log(productAssetsById);
                    if(productAssetsById.length > 0 ) {
                        return(
                            <>
                            <Swiper style={{ "--swiper-navigation-color": "#fff", "--swiper-pagination-color": "#fff" }}
                                    spaceBetween={10}
                                    navigation={true}
                                    thumbs={{ swiper: this.state.thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2">
                                {
                                    productAssetsById.map((asset, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <img style={{borderRadius: '10%'}}  src={'/assets/products/' + asset.path} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                                {
                                    productAssetsById.length > 1 &&
                                    <Swiper
                                                onSwiper={this._setThumbsSwiper}
                                                spaceBetween={10}
                                                slidesPerView={4}
                                                freeMode={true}
                                                watchSlidesProgress={true}
                                                modules={[FreeMode, Navigation, Thumbs]}
                                                className="mySwiper mt-4 mb-4">
                                        {
                                            productAssetsById.map((asset, index) => {
                                                 return(
                                                     <SwiperSlide>
                                                         <img style={{borderRadius: '10%'}}  src={'/assets/products/' + asset.path} />
                                                     </SwiperSlide>
                                                 )
                                            })
                                        }
                                    </Swiper>
                                }
                            </>
                        )

                    }
                }
            }}</DataContext.Consumer>
        )
    }
}

ProductCarousel.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();

    return <ProductCarousel {...props} navigation={navigation} params={params} />;
}

