import React, {Component, createContext} from 'react';

// service
import * as DataService from '../services/data'

export const DataContext = createContext();

class DataContextProvider extends Component {

    _isMounted = false;

    constructor( props ) {
        super(props);
        this.state = {
            rootMenu: null,
            rootMenuProductsWithAssets: null,
            sideBarClosed: true
        }
    }

    _toggleSideBar = () => {
        this.setState({
            sideBarClosed:!this.state.sideBarClosed
        })
    }

    importData = async () => {
        await DataService.importData();
    }

    getRootMenu = async () => {
        DataService.getRootMenu()
            .then((response) => {
                this.setState({
                    rootMenu: response
                });
                return;
            }).catch((error) => {
                return;
            });
    }

    getRootMenuProductsWithAssets = async () => {
        DataService.getRootMenuProductsWithAssets()
            .then(response => {
                this.setState({
                    rootMenuProductsWithAssets: response
                });
                return;
            }).catch((error) => {
                console.log('error', error);
                return;
            });
    }

    getChildSideBarMenuByMenuId = async menuId => {
        return DataService.getChildSideBarMenuByMenuId(menuId)
            .then(response => {
                this.setState({
                    childSideBarMenuByMenuId: response
                });
                return response;
            }).catch((error) => {
                return;
            });
    }

    getChildMenuByMenuId = async menuId => {
        return DataService.getChildMenuByMenuId(menuId)
            .then(response => {
                this.setState({
                    childMenuByMenuId: response
                });
                return response;
            }).catch((error) => {
            return;
        });
    }

    getProductsByMenuId = async menuId => {
        return DataService.getProductsByMenuId(menuId)
            .then((response) => {
                this.setState({
                    productsByMenuId: response
                });
                return response;
            }).catch((error) => {
            return;
        });
    }

    getProductById = async productId => {
        DataService.getProductById(productId)
            .then((response) => {
                this.setState({
                    productById: response
                });
                return;
            }).catch((error) => {
            return;
        });
    }

    getProductAttributesById = async productId => {
        DataService.getProductAttributesById(productId)
            .then((response) => {
                this.setState({
                    productAttributesById: response
                });
                return;
            }).catch((error) => {
            return;
        });
    }

    getProductColoursById = async productId => {
        DataService.getProductColoursById(productId)
            .then((response) => {
                this.setState({
                    productColoursById: response
                });
                return;
            }).catch((error) => {
            return;
        });
    }

    getProductAssetsById = async productId => {
        return DataService.getProductAssetsById(productId)
            .then((response) => {
                this.setState({
                    productAssetsById: response
                });
                return response;
            }).catch((error) => {
            return;
        });
    }

    getMenuAssetsById = async productId => {
        DataService.getMenuAssetsById(productId)
            .then((response) => {
                this.setState({
                    menuAssetsById: response
                });
                return;
            }).catch((error) => {
            return;
        });
    }

    checkMenuForChildren = async menuId => {
        DataService.checkMenuForChildren(menuId)
            .then((response) => {
                this.setState({
                    menuHasChildren: response
                });
                return;
            }).catch((error) => {
            return;
        });
    }

    getProductFilters = async menuId => {
        return DataService.getProductFilters(menuId)
            .then((response) => {
                this.setState({
                    productFilters: response
                });
                return response;
            }).catch((error) => {
                return;
            });
    }

    getProductFiltersByMenuId = async menuId => {
        return DataService.getProductFiltersByMenuId(menuId)
            .then((response) => {
                this.setState({
                    productFiltersByMenuId: response
                });
                return response;
            }).catch((error) => {
                return;
            });
    }

    getProductsByMenuId_Filtered = async (menuId, filters) => {
        return DataService.getProductsByMenuId_Filtered(menuId, filters)
            .then((response) => {
                console.log(response);
                this.setState({
                    productsByMenuId: response
                });
                return response;
            }).catch((error) => {
                return;
            });
    }

    getMenuParentByMenuId = async (menuId, returnOnly = false) => {
        return DataService.getMenuParentByMenuId(menuId)
            .then((response) => {
                if(!returnOnly) {
                    this.setState({
                        menuParentByMenuId: response
                    });
                }
                return response;
            }).catch((error) => {
                return;
            });
    }
    getMenuRootNameByMenuId = async (menuId, parentId) => {
        return DataService.getMenuRootNameByMenuId(menuId, parentId)
            .then((response) => {
                return response;
            }).catch((error) => {
                return;
            });
    }

    render () {
        return (
            <DataContext.Provider value={{
                ...this.state,
                importData: this.importData,
                getRootMenu: this.getRootMenu,
                getRootMenuProductsWithAssets: this.getRootMenuProductsWithAssets,
                getChildMenuByMenuId: this.getChildMenuByMenuId,
                getProductsByMenuId: this.getProductsByMenuId,
                checkMenuForChildren: this.checkMenuForChildren,
                getProductById: this.getProductById,
                getProductAttributesById: this.getProductAttributesById,
                getProductColoursById: this.getProductColoursById,
                getProductAssetsById: this.getProductAssetsById,
                getMenuAssetsById: this.getMenuAssetsById,
                getChildSideBarMenuByMenuId: this.getChildSideBarMenuByMenuId,
                getProductFilters: this.getProductFilters,
                getProductFiltersByMenuId: this.getProductFiltersByMenuId,
                getProductsByMenuId_Filtered: this.getProductsByMenuId_Filtered,
                getMenuParentByMenuId: this.getMenuParentByMenuId,
                toggleSidebar: this._toggleSideBar,
                getMenuRootNameByMenuId: this.getMenuRootNameByMenuId
            }}>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}

export default DataContextProvider;
