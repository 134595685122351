import React from 'react';
import './Breadcrumb.scoped.css'

import { Button, Card } from 'react-bootstrap';
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

class Breadcrumb extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { };
    }

    render() {
        return (
            <h6></h6>
        )
    }
}

export default function( props ) {
    return <Breadcrumb {...props} />;
}
