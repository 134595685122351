import React, { Component, createContext } from 'react';

export const RegistrationContext = createContext();

class RegistrationContextProvider extends Component {

    _isMounted = false;

    state = {

    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        return (
            <RegistrationContext.Provider
                value={{
                    ...this.state,
                }}
            >
                {this.props.children}
            </RegistrationContext.Provider>
        )
    }
}

export default RegistrationContextProvider;
