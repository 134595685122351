import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

import {BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";

// contexts
import AuthContextProvider, { AuthContext } from './context/AuthContext';
import DataContextProvider, { DataContext } from './context/DataContext';

import './App.css';

// routes
import * as AppRoutes from './AppRoutes';

const getAppStack = ( isAuthenticated, authenticationRequired) => {

    if( !isAuthenticated && authenticationRequired) {
        return <AppRoutes.Auth />
    }
    return (
        <DataContextProvider>
            <DataContext.Consumer>{( dataContext ) => {
                return(<AppRoutes.Main/>)
            }}</DataContext.Consumer>
        </DataContextProvider>
    )
}

function App() {
  return (
      <Router>
          <AuthContextProvider>
            <AuthContext.Consumer>{( authContext ) => {
               return getAppStack( authContext.isAuthenticated, authContext.authenticationRequired )
            }}</AuthContext.Consumer>
          </AuthContextProvider>
      </Router>
  );
}

export default App;
