export const colourSeed = [
    {
        Id:1,
        name:"Black \/ Grey",
        hex:"#000000",
        productId:1,
        position:0
    },
    {
        Id:2,
        name:"High White",
        hex:"#ffffff",
        productId:2,
        position:0
    },
    {
        Id:3,
        name:"High White",
        hex:"#ffffff",
        productId:3,
        position:0
    },
    {
        Id:4,
        name:"High White",
        hex:"#ffffff",
        productId:4,
        position:0
    },
    {
        Id:5,
        name:"White",
        hex:"#ffffff",
        productId:5,
        position:0
    },
    {
        Id:6,
        name:"High White",
        hex:"#ffffff",
        productId:6,
        position:0
    },
    {
        Id:7,
        name:"High White",
        hex:"#ffffff",
        productId:7,
        position:0
    },
    {
        Id:8,
        name:"White",
        hex:"#ffffff",
        productId:8,
        position:0
    },
    {
        Id:9,
        name:"High White",
        hex:"#ffffff",
        productId:9,
        position:0
    },
    {
        Id:10,
        name:"High White",
        hex:"#ffffff",
        productId:10,
        position:0
    },
    {
        Id:11,
        name:"White",
        hex:"#ffffff",
        productId:11,
        position:0
    },
    {
        Id:12,
        name:"High White",
        hex:"#ffffff",
        productId:12,
        position:0
    },
    {
        Id:13,
        name:"High White",
        hex:"#ffffff",
        productId:13,
        position:0
    },
    {
        Id:14,
        name:"High White",
        hex:"#ffffff",
        productId:14,
        position:0
    },
    {
        Id:15,
        name:"High White",
        hex:"#ffffff",
        productId:15,
        position:0
    },
    {
        Id:16,
        name:"White",
        hex:"#ffffff",
        productId:16,
        position:0
    },
    {
        Id:17,
        name:"High White",
        hex:"#ffffff",
        productId:17,
        position:0
    },
    {
        Id:18,
        name:"High White",
        hex:"#ffffff",
        productId:18,
        position:0
    },
    {
        Id:19,
        name:"High White \/ Yellow",
        hex:"#ffffff",
        productId:19,
        position:0
    },
    {
        Id:20,
        name:"High White \/ Red",
        hex:"#ffffff",
        productId:19,
        position:0
    },
    {
        Id:21,
        name:"High White \/ Green",
        hex:"#ffffff",
        productId:19,
        position:0
    },
    {
        Id:22,
        name:"High White \/ Royal Blue",
        hex:"#ffffff",
        productId:19,
        position:0
    },
    {
        Id:23,
        name:"High White \/ Yellow",
        hex:"#ffffff",
        productId:20,
        position:0
    },
    {
        Id:24,
        name:"High White \/ Red",
        hex:"#ffffff",
        productId:20,
        position:0
    },
    {
        Id:25,
        name:"High White \/ Green",
        hex:"#ffffff",
        productId:20,
        position:0
    },
    {
        Id:26,
        name:"High White \/ Royal Blue",
        hex:"#ffffff",
        productId:20,
        position:0
    },
    {
        Id:27,
        name:"Red",
        hex:"#c00000",
        productId:21,
        position:0
    },
    {
        Id:28,
        name:"Bottle",
        hex:"#006666",
        productId:21,
        position:0
    },
    {
        Id:29,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:21,
        position:0
    },
    {
        Id:30,
        name:"Navy",
        hex:"#000080",
        productId:21,
        position:0
    },
    {
        Id:31,
        name:"Yellow",
        hex:"#ffff00",
        productId:21,
        position:0
    },
    {
        Id:32,
        name:"Red",
        hex:"#c00000",
        productId:22,
        position:0
    },
    {
        Id:33,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:22,
        position:0
    },
    {
        Id:34,
        name:"Bottle",
        hex:"#006666",
        productId:22,
        position:0
    },
    {
        Id:35,
        name:"Navy",
        hex:"#000080",
        productId:22,
        position:0
    },
    {
        Id:36,
        name:"Yellow",
        hex:"#ffff00",
        productId:22,
        position:0
    },
    {
        Id:37,
        name:"Red",
        hex:"#c00000",
        productId:23,
        position:0
    },
    {
        Id:38,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:23,
        position:0
    },
    {
        Id:39,
        name:"Bottle",
        hex:"#006666",
        productId:23,
        position:0
    },
    {
        Id:40,
        name:"Navy",
        hex:"#000080",
        productId:23,
        position:0
    },
    {
        Id:41,
        name:"Red",
        hex:"#c00000",
        productId:24,
        position:0
    },
    {
        Id:42,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:24,
        position:0
    },
    {
        Id:43,
        name:"Bottle",
        hex:"#006666",
        productId:24,
        position:0
    },
    {
        Id:44,
        name:"Navy",
        hex:"#000080",
        productId:24,
        position:0
    },
    {
        Id:45,
        name:"High White",
        hex:"#ffffff",
        productId:25,
        position:0
    },
    {
        Id:46,
        name:"White",
        hex:"#ffffff",
        productId:26,
        position:0
    },
    {
        Id:47,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:26,
        position:0
    },
    {
        Id:48,
        name:"White",
        hex:"#ffffff",
        productId:27,
        position:0
    },
    {
        Id:49,
        name:"Navy",
        hex:"#000080",
        productId:27,
        position:0
    },
    {
        Id:50,
        name:"White",
        hex:"#ffffff",
        productId:28,
        position:0
    },
    {
        Id:51,
        name:"Navy",
        hex:"#000080",
        productId:28,
        position:0
    },
    {
        Id:52,
        name:"White",
        hex:"#ffffff",
        productId:29,
        position:0
    },
    {
        Id:53,
        name:"White",
        hex:"#ffffff",
        productId:30,
        position:0
    },
    {
        Id:54,
        name:"White",
        hex:"#ffffff",
        productId:31,
        position:0
    },
    {
        Id:55,
        name:"White",
        hex:"#ffffff",
        productId:32,
        position:0
    },
    {
        Id:56,
        name:"White",
        hex:"#ffffff",
        productId:33,
        position:0
    },
    {
        Id:57,
        name:"White",
        hex:"#ffffff",
        productId:34,
        position:0
    },
    {
        Id:58,
        name:"Black",
        hex:"#000000",
        productId:34,
        position:0
    },
    {
        Id:59,
        name:"White (Grey piping)",
        hex:"#ffffff",
        productId:35,
        position:0
    },
    {
        Id:60,
        name:"White (Black Piping)",
        hex:"#ffffff",
        productId:35,
        position:0
    },
    {
        Id:61,
        name:"Black (Grey piping)",
        hex:"#000000",
        productId:35,
        position:0
    },
    {
        Id:62,
        name:"White (Black Collar)",
        hex:"#ffffff",
        productId:37,
        position:0
    },
    {
        Id:63,
        name:"White",
        hex:"#ffffff",
        productId:38,
        position:0
    },
    {
        Id:64,
        name:"Black",
        hex:"#000000",
        productId:39,
        position:0
    },
    {
        Id:65,
        name:"Black",
        hex:"#000000",
        productId:40,
        position:0
    },
    {
        Id:66,
        name:"Grey",
        hex:"#cccccc",
        productId:41,
        position:0
    },
    {
        Id:67,
        name:"Blue Check",
        hex:"#4285f4",
        productId:42,
        position:0
    },
    {
        Id:68,
        name:"Black Check",
        hex:"#000000",
        productId:43,
        position:0
    },
    {
        Id:69,
        name:"White",
        hex:"#ffffff",
        productId:44,
        position:0
    },
    {
        Id:70,
        name:"Blue",
        hex:"#4285f4",
        productId:44,
        position:0
    },
    {
        Id:71,
        name:"Grey",
        hex:"#cccccc",
        productId:44,
        position:0
    },
    {
        Id:72,
        name:"Black",
        hex:"#000000",
        productId:44,
        position:0
    },
    {
        Id:73,
        name:"White",
        hex:"#ffffff",
        productId:45,
        position:0
    },
    {
        Id:74,
        name:"Grey",
        hex:"#cccccc",
        productId:45,
        position:0
    },
    {
        Id:75,
        name:"Navy",
        hex:"#000080",
        productId:45,
        position:0
    },
    {
        Id:76,
        name:"Black",
        hex:"#000000",
        productId:45,
        position:0
    },
    {
        Id:77,
        name:"Navy \/ White",
        hex:"#000080",
        productId:46,
        position:0
    },
    {
        Id:78,
        name:"Black \/ Silver",
        hex:"#000000",
        productId:46,
        position:0
    },
    {
        Id:79,
        name:"Black",
        hex:"#000000",
        productId:47,
        position:0
    },
    {
        Id:80,
        name:"White",
        hex:"#ffffff",
        productId:47,
        position:0
    },
    {
        Id:81,
        name:"White",
        hex:"#ffffff",
        productId:48,
        position:0
    },
    {
        Id:82,
        name:"Black",
        hex:"#000000",
        productId:48,
        position:0
    },
    {
        Id:83,
        name:"Charcoal Grey",
        hex:"#36454f",
        productId:48,
        position:0
    },
    {
        Id:84,
        name:"Navy",
        hex:"#000080",
        productId:48,
        position:0
    },
    {
        Id:85,
        name:"White",
        hex:"#ffffff",
        productId:49,
        position:0
    },
    {
        Id:86,
        name:"Black",
        hex:"#000000",
        productId:49,
        position:0
    },
    {
        Id:87,
        name:"Charcoal Grey",
        hex:"#36454f",
        productId:49,
        position:0
    },
    {
        Id:88,
        name:"Navy",
        hex:"#000080",
        productId:49,
        position:0
    },
    {
        Id:89,
        name:"White",
        hex:"#ffffff",
        productId:50,
        position:0
    },
    {
        Id:90,
        name:"Silver",
        hex:"#efefef",
        productId:50,
        position:0
    },
    {
        Id:91,
        name:"White",
        hex:"#ffffff",
        productId:51,
        position:0
    },
    {
        Id:92,
        name:"Silver",
        hex:"#efefef",
        productId:51,
        position:0
    },
    {
        Id:93,
        name:"Silver",
        hex:"#efefef",
        productId:52,
        position:0
    },
    {
        Id:94,
        name:"White",
        hex:"#ffffff",
        productId:52,
        position:0
    },
    {
        Id:95,
        name:"White",
        hex:"#ffffff",
        productId:53,
        position:0
    },
    {
        Id:96,
        name:"Silver",
        hex:"#efefef",
        productId:53,
        position:0
    },
    {
        Id:97,
        name:"White",
        hex:"#ffffff",
        productId:54,
        position:0
    },
    {
        Id:98,
        name:"Silver",
        hex:"#efefef",
        productId:54,
        position:0
    },
    {
        Id:99,
        name:"White",
        hex:"#ffffff",
        productId:55,
        position:0
    },
    {
        Id:100,
        name:"Silver",
        hex:"#efefef",
        productId:55,
        position:0
    },
    {
        Id:101,
        name:"White",
        hex:"#ffffff",
        productId:56,
        position:0
    },
    {
        Id:102,
        name:"White",
        hex:"#ffffff",
        productId:57,
        position:0
    },
    {
        Id:103,
        name:"White",
        hex:"#ffffff",
        productId:58,
        position:0
    },
    {
        Id:104,
        name:"Silver",
        hex:"#efefef",
        productId:58,
        position:0
    },
    {
        Id:105,
        name:"White",
        hex:"#ffffff",
        productId:59,
        position:0
    },
    {
        Id:106,
        name:"Silver",
        hex:"#efefef",
        productId:59,
        position:0
    },
    {
        Id:107,
        name:"White",
        hex:"#ffffff",
        productId:60,
        position:0
    },
    {
        Id:108,
        name:"Silver",
        hex:"#efefef",
        productId:60,
        position:0
    },
    {
        Id:109,
        name:"Silver",
        hex:"#efefef",
        productId:61,
        position:0
    },
    {
        Id:110,
        name:"White",
        hex:"#ffffff",
        productId:61,
        position:0
    },
    {
        Id:111,
        name:"Silver",
        hex:"#efefef",
        productId:62,
        position:0
    },
    {
        Id:112,
        name:"White",
        hex:"#ffffff",
        productId:62,
        position:0
    },
    {
        Id:113,
        name:"White",
        hex:"#ffffff",
        productId:63,
        position:0
    },
    {
        Id:114,
        name:"Silver",
        hex:"#efefef",
        productId:63,
        position:0
    },
    {
        Id:115,
        name:"White",
        hex:"#ffffff",
        productId:64,
        position:0
    },
    {
        Id:116,
        name:"Silver",
        hex:"#efefef",
        productId:64,
        position:0
    },
    {
        Id:117,
        name:"White",
        hex:"#ffffff",
        productId:65,
        position:0
    },
    {
        Id:118,
        name:"Silver",
        hex:"#efefef",
        productId:66,
        position:0
    },
    {
        Id:119,
        name:"Silver",
        hex:"#ffffff",
        productId:67,
        position:0
    },
    {
        Id:120,
        name:"Silver",
        hex:"#efefef",
        productId:68,
        position:0
    },
    {
        Id:121,
        name:"Silver",
        hex:"#efefef",
        productId:69,
        position:0
    },
    {
        Id:122,
        name:"Silver",
        hex:"#efefef",
        productId:70,
        position:0
    },
    {
        Id:123,
        name:"White",
        hex:"#ffffff",
        productId:71,
        position:0
    },
    {
        Id:124,
        name:"White",
        hex:"#ffffff",
        productId:72,
        position:0
    },
    {
        Id:125,
        name:"Silver",
        hex:"#efefef",
        productId:72,
        position:0
    },
    {
        Id:126,
        name:"Silver",
        hex:"#ffffff",
        productId:73,
        position:0
    },
    {
        Id:127,
        name:"Silver",
        hex:"#ffffff",
        productId:73,
        position:0
    },
    {
        Id:128,
        name:"White",
        hex:"#ffffff",
        productId:74,
        position:0
    },
    {
        Id:129,
        name:"Silver",
        hex:"#efefef",
        productId:74,
        position:0
    },
    {
        Id:130,
        name:"White",
        hex:"#ffffff",
        productId:75,
        position:0
    },
    {
        Id:131,
        name:"Silver",
        hex:"#efefef",
        productId:75,
        position:0
    },
    {
        Id:132,
        name:"White",
        hex:"#ffffff",
        productId:76,
        position:0
    },
    {
        Id:133,
        name:"Silver",
        hex:"#efefef",
        productId:76,
        position:0
    },
    {
        Id:134,
        name:"White",
        hex:"#ffffff",
        productId:77,
        position:0
    },
    {
        Id:135,
        name:"Yellow",
        hex:"#ffff00",
        productId:77,
        position:0
    },
    {
        Id:136,
        name:"White",
        hex:"#ffffff",
        productId:78,
        position:0
    },
    {
        Id:137,
        name:"Silver",
        hex:"#efefef",
        productId:78,
        position:0
    },
    {
        Id:138,
        name:"White",
        hex:"#ffffff",
        productId:79,
        position:0
    },
    {
        Id:139,
        name:"Silver",
        hex:"#efefef",
        productId:79,
        position:0
    },
    {
        Id:140,
        name:"Silver",
        hex:"#efefef",
        productId:80,
        position:0
    },
    {
        Id:141,
        name:"White",
        hex:"#ffffff",
        productId:80,
        position:0
    },
    {
        Id:142,
        name:"White",
        hex:"#ffffff",
        productId:81,
        position:0
    },
    {
        Id:143,
        name:"Silver",
        hex:"#efefef",
        productId:81,
        position:0
    },
    {
        Id:144,
        name:"White",
        hex:"#ffffff",
        productId:82,
        position:0
    },
    {
        Id:145,
        name:"Silver",
        hex:"#efefef",
        productId:82,
        position:0
    },
    {
        Id:146,
        name:"White",
        hex:"#ffffff",
        productId:83,
        position:0
    },
    {
        Id:147,
        name:"Black",
        hex:"#000000",
        productId:84,
        position:0
    },
    {
        Id:148,
        name:"Yellow",
        hex:"#ffff00",
        productId:85,
        position:0
    },
    {
        Id:149,
        name:"Light Blue",
        hex:"#6d9eeb",
        productId:85,
        position:0
    },
    {
        Id:150,
        name:"Grey",
        hex:"#cccccc",
        productId:85,
        position:0
    },
    {
        Id:151,
        name:"Dark Blue",
        hex:"#3c78d8",
        productId:85,
        position:0
    },
    {
        Id:152,
        name:"Yellow",
        hex:"#ffff00",
        productId:85,
        position:0
    },
    {
        Id:153,
        name:"Light Blue",
        hex:"#6d9eeb",
        productId:85,
        position:0
    },
    {
        Id:154,
        name:"Grey",
        hex:"#cccccc",
        productId:85,
        position:0
    },
    {
        Id:155,
        name:"Dark Blue",
        hex:"#3c78d8",
        productId:85,
        position:0
    },
    {
        Id:156,
        name:"White (Transparent)",
        hex:"#ffffff",
        productId:86,
        position:0
    },
    {
        Id:157,
        name:"White (Transparent)",
        hex:"#ffffff",
        productId:86,
        position:0
    },
    {
        Id:158,
        name:"Black ",
        hex:"#000000",
        productId:87,
        position:0
    },
    {
        Id:159,
        name:"Black ",
        hex:"#000000",
        productId:88,
        position:0
    },
    {
        Id:160,
        name:"Black ",
        hex:"#000000",
        productId:89,
        position:0
    },
    {
        Id:161,
        name:"Black ",
        hex:"#000000",
        productId:90,
        position:0
    },
    {
        Id:162,
        name:"Black ",
        hex:"#000000",
        productId:91,
        position:0
    },
    {
        Id:163,
        name:"wearwell",
        hex:"#ffffff",
        productId:92,
        position:0
    },
    {
        Id:164,
        name:"Red \/ White",
        hex:"#c00000",
        productId:93,
        position:0
    },
    {
        Id:165,
        name:"wearwell",
        hex:"#ffffff",
        productId:94,
        position:0
    },
    {
        Id:166,
        name:"wearwell",
        hex:"#ffffff",
        productId:95,
        position:0
    },
    {
        Id:167,
        name:"wearwell",
        hex:"#ffffff",
        productId:96,
        position:0
    },
    {
        Id:168,
        name:"wearwell",
        hex:"#ffffff",
        productId:97,
        position:0
    },
    {
        Id:169,
        name:"wearwell",
        hex:"#ffffff",
        productId:98,
        position:0
    },
    {
        Id:170,
        name:"wearwell",
        hex:"#ffffff",
        productId:99,
        position:0
    },
    {
        Id:171,
        name:"Black \/ Grey",
        hex:"#000000",
        productId:100,
        position:0
    },
    {
        Id:172,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:100,
        position:0
    },
    {
        Id:173,
        name:"Navy \/ Black",
        hex:"#000080",
        productId:100,
        position:0
    },
    {
        Id:174,
        name:"Navy  ",
        hex:"#000080",
        productId:100,
        position:0
    },
    {
        Id:175,
        name:"Blue",
        hex:"#4285f4",
        productId:102,
        position:0
    },
    {
        Id:176,
        name:"Green ",
        hex:"#00FF00",
        productId:103,
        position:0
    },
    {
        Id:177,
        name:"Red",
        hex:"#c00000",
        productId:104,
        position:0
    },
    {
        Id:178,
        name:"Navy",
        hex:"#000080",
        productId:105,
        position:0
    },
    {
        Id:179,
        name:"Navy",
        hex:"#000080",
        productId:106,
        position:0
    },
    {
        Id:180,
        name:"Navy",
        hex:"#000080",
        productId:107,
        position:0
    },
    {
        Id:181,
        name:"Orange",
        hex:"#ff9900",
        productId:108,
        position:0
    },
    {
        Id:182,
        name:"Red",
        hex:"#c00000",
        productId:108,
        position:0
    },
    {
        Id:183,
        name:"Navy",
        hex:"#000080",
        productId:108,
        position:0
    },
    {
        Id:184,
        name:"Orange ",
        hex:"#ff9900",
        productId:109,
        position:0
    },
    {
        Id:185,
        name:"Red",
        hex:"#c00000",
        productId:109,
        position:0
    },
    {
        Id:186,
        name:"Navy ",
        hex:"#000080",
        productId:109,
        position:0
    },
    {
        Id:187,
        name:"Orange",
        hex:"#ff9900",
        productId:110,
        position:0
    },
    {
        Id:188,
        name:"Red",
        hex:"#c00000",
        productId:110,
        position:0
    },
    {
        Id:189,
        name:"Navy",
        hex:"#000080",
        productId:110,
        position:0
    },
    {
        Id:190,
        name:"Navy\/Red ",
        hex:"#000080",
        productId:111,
        position:0
    },
    {
        Id:191,
        name:"Navy",
        hex:"#000080",
        productId:112,
        position:0
    },
    {
        Id:192,
        name:"Navy",
        hex:"#000080",
        productId:112,
        position:0
    },
    {
        Id:193,
        name:"Navy",
        hex:"#000080",
        productId:113,
        position:0
    },
    {
        Id:194,
        name:"Navy",
        hex:"#000080",
        productId:113,
        position:0
    },
    {
        Id:195,
        name:"Navy",
        hex:"#000080",
        productId:114,
        position:0
    },
    {
        Id:196,
        name:"Navy",
        hex:"#000080",
        productId:114,
        position:0
    },
    {
        Id:197,
        name:"Navy \/ Red ",
        hex:"#000080",
        productId:115,
        position:0
    },
    {
        Id:198,
        name:"Navy \/ Red ",
        hex:"#000080",
        productId:116,
        position:0
    },
    {
        Id:199,
        name:"Navy \/ Red ",
        hex:"#000080",
        productId:117,
        position:0
    },
    {
        Id:200,
        name:"wearwell",
        hex:"#ffffff",
        productId:118,
        position:0
    },
    {
        Id:201,
        name:"Navy",
        hex:"#000080",
        productId:119,
        position:0
    },
    {
        Id:202,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:120,
        position:0
    },
    {
        Id:203,
        name:"Yellow",
        hex:"#ffff00",
        productId:121,
        position:0
    },
    {
        Id:204,
        name:"Orange",
        hex:"#ff9900",
        productId:121,
        position:0
    },
    {
        Id:205,
        name:"Yellow",
        hex:"#ffff00",
        productId:122,
        position:0
    },
    {
        Id:206,
        name:"Orange",
        hex:"#ff9900",
        productId:122,
        position:0
    },
    {
        Id:207,
        name:"Yellow",
        hex:"#ffff00",
        productId:122,
        position:0
    },
    {
        Id:208,
        name:"Orange",
        hex:"#ff9900",
        productId:122,
        position:0
    },
    {
        Id:209,
        name:"Yellow",
        hex:"#ffff00",
        productId:124,
        position:0
    },
    {
        Id:210,
        name:"Hi-vis\/Navy ",
        hex:"#000080",
        productId:125,
        position:0
    },
    {
        Id:211,
        name:"Hi-vis\/Navy ",
        hex:"#000080",
        productId:126,
        position:0
    },
    {
        Id:212,
        name:"Hi-vis\/Navy ",
        hex:"#000080",
        productId:127,
        position:0
    },
    {
        Id:213,
        name:"Hi-vis\/Navy ",
        hex:"#000080",
        productId:128,
        position:0
    },
    {
        Id:214,
        name:"Hi-vis\/Navy ",
        hex:"#000080",
        productId:128,
        position:0
    },
    {
        Id:215,
        name:"Navy",
        hex:"#000080",
        productId:129,
        position:0
    },
    {
        Id:216,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:129,
        position:0
    },
    {
        Id:217,
        name:"Navy",
        hex:"#000080",
        productId:130,
        position:0
    },
    {
        Id:218,
        name:"Royal Blue",
        hex:"#4169e1",
        productId:130,
        position:0
    },
    {
        Id:219,
        name:"Navy",
        hex:"#000080",
        productId:131,
        position:0
    },
    {
        Id:220,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:132,
        position:0
    },
    {
        Id:221,
        name:"Orange \/ Navy",
        hex:"#ff9900",
        productId:132,
        position:0
    },
    {
        Id:222,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:133,
        position:0
    },
    {
        Id:223,
        name:"Orange \/ Navy",
        hex:"#ff9900",
        productId:133,
        position:0
    },
    {
        Id:224,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:134,
        position:0
    },
    {
        Id:225,
        name:"Navy \/ Black",
        hex:"#000080",
        productId:134,
        position:0
    },
    {
        Id:226,
        name:"Black \/ Red ",
        hex:"#000000",
        productId:134,
        position:0
    },
    {
        Id:227,
        name:"wearwell",
        hex:"#ffffff",
        productId:135,
        position:0
    },
    {
        Id:228,
        name:"wearwell",
        hex:"#ffffff",
        productId:136,
        position:0
    },
    {
        Id:229,
        name:"wearwell",
        hex:"#ffffff",
        productId:137,
        position:0
    },
    {
        Id:230,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:138,
        position:0
    },
    {
        Id:231,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:138,
        position:0
    },
    {
        Id:232,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:140,
        position:0
    },
    {
        Id:233,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:140,
        position:0
    },
    {
        Id:234,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:142,
        position:0
    },
    {
        Id:235,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:142,
        position:0
    },
    {
        Id:236,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:144,
        position:0
    },
    {
        Id:237,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:144,
        position:0
    },
    {
        Id:238,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:146,
        position:0
    },
    {
        Id:239,
        name:"White",
        hex:"#ffffff",
        productId:147,
        position:0
    },
    {
        Id:240,
        name:"Black",
        hex:"#000000",
        productId:148,
        position:0
    },
    {
        Id:241,
        name:"Black",
        hex:"#000000",
        productId:149,
        position:0
    },
    {
        Id:242,
        name:"Black",
        hex:"#000000",
        productId:150,
        position:0
    },
    {
        Id:243,
        name:"Black",
        hex:"#000000",
        productId:151,
        position:0
    },
    {
        Id:244,
        name:"Black",
        hex:"#000000",
        productId:152,
        position:0
    },
    {
        Id:245,
        name:"Black",
        hex:"#000000",
        productId:153,
        position:0
    },
    {
        Id:246,
        name:"Black",
        hex:"#000000",
        productId:154,
        position:0
    },
    {
        Id:247,
        name:"Black",
        hex:"#000000",
        productId:155,
        position:0
    },
    {
        Id:248,
        name:"Black \/ Grey",
        hex:"#000000",
        productId:156,
        position:0
    },
    {
        Id:249,
        name:" Grey \/ Black ",
        hex:"#cccccc",
        productId:156,
        position:0
    },
    {
        Id:250,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:156,
        position:0
    },
    {
        Id:251,
        name:"Black \/ Grey",
        hex:"#000000",
        productId:157,
        position:0
    },
    {
        Id:252,
        name:"Navy",
        hex:"#000080",
        productId:158,
        position:0
    },
    {
        Id:253,
        name:"wearwell",
        hex:"#ffffff",
        productId:159,
        position:0
    },
    {
        Id:254,
        name:"wearwell",
        hex:"#ffffff",
        productId:160,
        position:0
    },
    {
        Id:255,
        name:"wearwell",
        hex:"#ffffff",
        productId:161,
        position:0
    },
    {
        Id:256,
        name:"wearwell",
        hex:"#ffffff",
        productId:162,
        position:0
    },
    {
        Id:257,
        name:"wearwell",
        hex:"#ffffff",
        productId:163,
        position:0
    },
    {
        Id:258,
        name:"wearwell",
        hex:"#ffffff",
        productId:164,
        position:0
    },
    {
        Id:259,
        name:"wearwell",
        hex:"#ffffff",
        productId:165,
        position:0
    },
    {
        Id:260,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:166,
        position:0
    },
    {
        Id:261,
        name:"Orange \/ Navy",
        hex:"#ff9900",
        productId:166,
        position:0
    },
    {
        Id:262,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:167,
        position:0
    },
    {
        Id:263,
        name:"Orange \/ Navy",
        hex:"#ff9900",
        productId:167,
        position:0
    },
    {
        Id:264,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:167,
        position:0
    },
    {
        Id:265,
        name:"Orange \/ Navy",
        hex:"#ff9900",
        productId:167,
        position:0
    },
    {
        Id:266,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:168,
        position:0
    },
    {
        Id:267,
        name:"Orange \/ Navy",
        hex:"#ff9900",
        productId:168,
        position:0
    },
    {
        Id:268,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:168,
        position:0
    },
    {
        Id:269,
        name:"Yellow \/ Navy",
        hex:"#ffff00",
        productId:168,
        position:0
    },
    {
        Id:270,
        name:"Orange \/ Navy",
        hex:"#ff9900",
        productId:168,
        position:0
    },
    {
        Id:271,
        name:"Black \/ Grey",
        hex:"#000000",
        productId:169,
        position:0
    },
    {
        Id:272,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:169,
        position:0
    },
    {
        Id:273,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:170,
        position:0
    },
    {
        Id:274,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:170,
        position:0
    },
    {
        Id:275,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:171,
        position:0
    },
    {
        Id:276,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:171,
        position:0
    },
    {
        Id:277,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:171,
        position:0
    },
    {
        Id:278,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:172,
        position:0
    },
    {
        Id:279,
        name:"Hi-vis\/ Navy",
        hex:"#000080",
        productId:172,
        position:0
    },
    {
        Id:280,
        name:" Grey \/ Black ",
        hex:"#cccccc",
        productId:173,
        position:0
    },
    {
        Id:281,
        name:"Black \/ Grey",
        hex:"#000000",
        productId:173,
        position:0
    },
    {
        Id:282,
        name:"Grey \/ Red",
        hex:"#cccccc",
        productId:173,
        position:0
    },
    {
        Id:283,
        name:"Navy \/ Red",
        hex:"#000080",
        productId:173,
        position:0
    },
    {
        Id:284,
        name:"Black",
        hex:"#000000",
        productId:174,
        position:0
    },
    {
        Id:285,
        name:"Navy  ",
        hex:"#000080",
        productId:175,
        position:0
    },
    {
        Id:286,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:176,
        position:0
    },
    {
        Id:287,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:176,
        position:0
    },
    {
        Id:288,
        name:"wearwell",
        hex:"#ffffff",
        productId:177,
        position:0
    },
    {
        Id:289,
        name:"wearwell",
        hex:"#ffffff",
        productId:178,
        position:0
    },
    {
        Id:290,
        name:"wearwell",
        hex:"#ffffff",
        productId:179,
        position:0
    },
    {
        Id:291,
        name:"wearwell",
        hex:"#ffffff",
        productId:180,
        position:0
    },
    {
        Id:292,
        name:"Green ",
        hex:"#00FF00",
        productId:181,
        position:0
    },
    {
        Id:293,
        name:"Red",
        hex:"#c00000",
        productId:182,
        position:0
    },
    {
        Id:294,
        name:"Blue",
        hex:"#4285f4",
        productId:183,
        position:0
    },
    {
        Id:295,
        name:"Green ",
        hex:"#00FF00",
        productId:184,
        position:0
    },
    {
        Id:296,
        name:"Red",
        hex:"#c00000",
        productId:185,
        position:0
    },
    {
        Id:297,
        name:"Black \/ Navy",
        hex:"#000000",
        productId:186,
        position:0
    },
    {
        Id:298,
        name:"Brown",
        hex:"#964B00",
        productId:187,
        position:0
    },
    {
        Id:299,
        name:"Blue",
        hex:"#4285f4",
        productId:188,
        position:0
    },
    {
        Id:300,
        name:"Black",
        hex:"#000000",
        productId:189,
        position:0
    },
    {
        Id:301,
        name:"Light Grey ",
        hex:"#d9d9d9",
        productId:190,
        position:0
    },
    {
        Id:302,
        name:"Red",
        hex:"#c00000",
        productId:191,
        position:0
    },
    {
        Id:303,
        name:"Brown",
        hex:"#964B00",
        productId:192,
        position:0
    },
    {
        Id:304,
        name:"Blue",
        hex:"#4285f4",
        productId:193,
        position:0
    },
    {
        Id:305,
        name:"Black",
        hex:"#000000",
        productId:194,
        position:0
    },
    {
        Id:306,
        name:"Light Grey ",
        hex:"#d9d9d9",
        productId:195,
        position:0
    },
    {
        Id:307,
        name:"Red",
        hex:"#c00000",
        productId:196,
        position:0
    },
    {
        Id:308,
        name:"Brown",
        hex:"#964B00",
        productId:197,
        position:0
    },
    {
        Id:309,
        name:"Blue",
        hex:"#4285f4",
        productId:198,
        position:0
    },
    {
        Id:310,
        name:"Black",
        hex:"#000000",
        productId:199,
        position:0
    },
    {
        Id:311,
        name:"Light Grey ",
        hex:"#d9d9d9",
        productId:200,
        position:0
    },
    {
        Id:312,
        name:"Red",
        hex:"#c00000",
        productId:201,
        position:0
    },
    {
        Id:313,
        name:"Brown",
        hex:"#964B00",
        productId:202,
        position:0
    },
    {
        Id:314,
        name:"Blue ",
        hex:"#4285f4",
        productId:203,
        position:0
    },
    {
        Id:315,
        name:"Black ",
        hex:"#000000",
        productId:204,
        position:0
    },
    {
        Id:316,
        name:"Light Grey ",
        hex:"#d9d9d9",
        productId:205,
        position:0
    },
    {
        Id:317,
        name:"Red",
        hex:"#c00000",
        productId:206,
        position:0
    },
    {
        Id:318,
        name:"Grey",
        hex:"#cccccc",
        productId:207,
        position:0
    },
    {
        Id:319,
        name:"Grey",
        hex:"#cccccc",
        productId:208,
        position:0
    },
    {
        Id:320,
        name:"Grey",
        hex:"#cccccc",
        productId:209,
        position:0
    },
    {
        Id:321,
        name:"Grey",
        hex:"#cccccc",
        productId:210,
        position:0
    },
    {
        Id:322,
        name:"Black",
        hex:"#000000",
        productId:211,
        position:0
    },
    {
        Id:323,
        name:"Black",
        hex:"#000000",
        productId:212,
        position:0
    },
    {
        Id:324,
        name:"Black",
        hex:"#000000",
        productId:213,
        position:0
    },
    {
        Id:325,
        name:"Black",
        hex:"#000000",
        productId:214,
        position:0
    },
    {
        Id:326,
        name:"Grey",
        hex:"#cccccc",
        productId:215,
        position:0
    },
    {
        Id:327,
        name:"Grey",
        hex:"#cccccc",
        productId:216,
        position:0
    },
    {
        Id:328,
        name:"Grey",
        hex:"#cccccc",
        productId:217,
        position:0
    },
    {
        Id:329,
        name:"Multi",
        hex:"#ffffff",
        productId:218,
        position:0
    },
    {
        Id:330,
        name:"Mink",
        hex:"#ffffff",
        productId:219,
        position:0
    },
    {
        Id:331,
        name:"Mink",
        hex:"#ffffff",
        productId:220,
        position:0
    },
    {
        Id:332,
        name:"Multi",
        hex:"#ffffff",
        productId:221,
        position:0
    },
    {
        Id:333,
        name:"Multi",
        hex:"#ffffff",
        productId:222,
        position:0
    },
    {
        Id:334,
        name:"Black",
        hex:"#000000",
        productId:223,
        position:0
    },
    {
        Id:335,
        name:"Black",
        hex:"#000000",
        productId:224,
        position:0
    },
    {
        Id:336,
        name:"Black",
        hex:"#000000",
        productId:225,
        position:0
    },
    {
        Id:337,
        name:"Black",
        hex:"#000000",
        productId:226,
        position:0
    },
    {
        Id:338,
        name:"Black",
        hex:"#000000",
        productId:227,
        position:0
    },
    {
        Id:339,
        name:"Multi",
        hex:"#ffffff",
        productId:228,
        position:0
    },
    {
        Id:340,
        name:"Multi",
        hex:"#ffffff",
        productId:229,
        position:0
    },
    {
        Id:341,
        name:"Multi",
        hex:"#ffffff",
        productId:230,
        position:0
    },
    {
        Id:342,
        name:"Multi",
        hex:"#ffffff",
        productId:231,
        position:0
    },
    {
        Id:343,
        name:"Multi",
        hex:"#ffffff",
        productId:232,
        position:0
    },
    {
        Id:344,
        name:"Multi",
        hex:"#ffffff",
        productId:233,
        position:0
    },
    {
        Id:345,
        name:"Multi",
        hex:"#ffffff",
        productId:234,
        position:0
    },
    {
        Id:346,
        name:"Multi",
        hex:"#ffffff",
        productId:235,
        position:0
    },
    {
        Id:347,
        name:"Multi",
        hex:"#ffffff",
        productId:236,
        position:0
    },
    {
        Id:348,
        name:"Multi",
        hex:"#ffffff",
        productId:237,
        position:0
    },
    {
        Id:349,
        name:"Multi",
        hex:"#ffffff",
        productId:238,
        position:0
    },
    {
        Id:350,
        name:"Multi",
        hex:"#ffffff",
        productId:239,
        position:0
    },
    {
        Id:351,
        name:"Multi",
        hex:"#ffffff",
        productId:240,
        position:0
    },
    {
        Id:352,
        name:"Multi",
        hex:"#ffffff",
        productId:241,
        position:0
    },
    {
        Id:353,
        name:"Multi",
        hex:"#ffffff",
        productId:242,
        position:0
    },
    {
        Id:354,
        name:"Multi",
        hex:"#ffffff",
        productId:243,
        position:0
    },
    {
        Id:355,
        name:"Multi",
        hex:"#ffffff",
        productId:244,
        position:0
    },
    {
        Id:356,
        name:"Multi",
        hex:"#ffffff",
        productId:245,
        position:0
    },
    {
        Id:357,
        name:"Multi",
        hex:"#ffffff",
        productId:246,
        position:0
    },
    {
        Id:358,
        name:"Multi",
        hex:"#ffffff",
        productId:247,
        position:0
    },
    {
        Id:359,
        name:"Multi",
        hex:"#ffffff",
        productId:248,
        position:0
    },
    {
        Id:360,
        name:"Multi",
        hex:"#ffffff",
        productId:249,
        position:0
    },
    {
        Id:361,
        name:"Multi",
        hex:"#ffffff",
        productId:250,
        position:0
    },
    {
        Id:362,
        name:"Multi",
        hex:"#ffffff",
        productId:251,
        position:0
    },
    {
        Id:363,
        name:"Multi",
        hex:"#ffffff",
        productId:252,
        position:0
    }
]