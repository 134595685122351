import React from 'react';
import './GridLayout.scoped.css'
import GridItem from "./GridItem";

import {Link} from "react-router-dom";

// context
import { DataContext } from "../../context/DataContext";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

class GridLayout extends React.Component {

    _isMounted = false;

    constructor( props ) {
        super( props );
        this.state = {
            isLoading: true
        };
    }
    _init = async () => {
        if( this._isMounted ) {
            let self = this;
            // if(this.props.parentId !== 0) {
            await this.context.getChildMenuByMenuId(this.props.parentId);
            this.setState({isLoading: false});
            // } else {
            //     await this.context.getRootMenuProductsWithAssets().then(function (result) {
            //         self.setState({isLoading: false});
            //     });
            // }
            // this.setState({
            //     isLoading: false
            // })
            // await this.context.getRootMenuProductsWithAssets().then(function (result) {
            //     self.setState({isLoading: false});
            // });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        console.log('parentId', this.props.parentId);
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <DataContext.Consumer>{( dataContext ) => {
                const { childMenuByMenuId } = dataContext;
                if(this.state.isLoading && childMenuByMenuId == null) {
                    return(
                        <>
                            <LoadingSpinner />
                        </>
                    )
                }
                if(!this.state.isLoading  && childMenuByMenuId != null){
                    return (
                        <div className="gridlayout-container container scrollable-div">
                            <div className="row">
                                {
                                    childMenuByMenuId.map((gridItem) => {
                                        return (
                                            <div className="col col-md-3 mb-4" key={gridItem.Id}>
                                                <Link to={(gridItem.children === true ? `/category-group/${gridItem.Id}` : `/products/${gridItem.Id}/${this.props.parentId}`)}>
                                                    <GridItem navPath={(gridItem.children === true ? `/category-group/${gridItem.Id}/` : `/products/${gridItem.Id}/${this.props.parentId}`)} fromWelcomePage={this.props.parentId === 0} gridItem={gridItem} showButtons={this.props.parentId === 0} showDescription={this.props.parentId === 0} />
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            }}</DataContext.Consumer>
        );
    }
};

GridLayout.contextType = DataContext;

export default function( props ) {
    return <GridLayout {...props} />;
}
