import React from 'react';
import './FilterItem.scoped.css'

import Icon from "../Icon/Icon";
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

// context
import { DataContext } from "../../context/DataContext";

class FilterItem extends React.Component {

    _isMounted = false;

    constructor( props ) {
        super( props );
        this.state = {};
    }
    _init = async () => {
        if(this._isMounted){

        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    render() {
        return (
            <>
                {
                    (this.props.selected === true) &&
                    <Button onClick={() => this.props.filterSelected(this.props.filter)} className={'filterButton button--green position-relative'}>
                        {this.props.filter.name}
                        <Icon color="#FFFFFF" size={18} icon={'ico-yes'} className={'icon-position position-absolute end-0'} />
                    </Button>
                }
                {
                    (this.props.selected === false) &&
                    <Button onClick={() => this.props.filterSelected(this.props.filter)}  className={'filterButton button--green--outline position-relative'}>
                        {this.props.filter.name}
                    </Button>
                }
            </>
        )
    }
}

FilterItem.contextType = DataContext;

export default function( props ) {
    return <FilterItem {...props} />;
}


