import React from 'react';
import './Category.scoped.css'

import { Button, Card } from 'react-bootstrap';
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

class Category extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { };
    }

    render() {
        return (
            <Card className={this.props.className}>
                <Card.Img variant="top" src="https://via.placeholder.com/150x150" />
                <Card.Body>
                    <Card.Title>{this.props.group.name}</Card.Title>
                    <Button as={Link} to={'/group/' + this.props.group.groupId + '/' + this.props.group.name } variant={'primary'} className={'button--green'}>Discover</Button>
                </Card.Body>
            </Card>
        )
    }
}

export default function( props ) {
    return <Category {...props} />;
}
