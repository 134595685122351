export const colourAttributeSeed = [
    {
        Id:1,
        colourId:1,
        name:"FRY",
        productcode:"C50066569",
        position:0
    },
    {
        Id:2,
        colourId:1,
        name:"SSG",
        productcode:"C50066569",
        position:0
    },
    {
        Id:3,
        colourId:1,
        name:"SOL",
        productcode:"103546",
        position:0
    },
    {
        Id:4,
        colourId:2,
        name:"FRY",
        productcode:"575261301",
        position:0
    },
    {
        Id:5,
        colourId:2,
        name:"SSG",
        productcode:"575261301",
        position:0
    },
    {
        Id:6,
        colourId:2,
        name:"SOL",
        productcode:"102284",
        position:0
    },
    {
        Id:7,
        colourId:3,
        name:"FRY",
        productcode:"475261301",
        position:0
    },
    {
        Id:8,
        colourId:3,
        name:"SSG",
        productcode:"475261301",
        position:0
    },
    {
        Id:9,
        colourId:3,
        name:"SOL",
        productcode:"102220",
        position:0
    },
    {
        Id:10,
        colourId:4,
        name:"FRY",
        productcode:"297781301",
        position:0
    },
    {
        Id:11,
        colourId:4,
        name:"SSG",
        productcode:"297781301",
        position:0
    },
    {
        Id:12,
        colourId:4,
        name:"SOL",
        productcode:"111174",
        position:0
    },
    {
        Id:13,
        colourId:5,
        name:"FRY",
        productcode:"FC2",
        position:0
    },
    {
        Id:14,
        colourId:5,
        name:"SSG",
        productcode:"0EKLG 001",
        position:0
    },
    {
        Id:15,
        colourId:5,
        name:"SOL",
        productcode:"100250",
        position:0
    },
    {
        Id:16,
        colourId:6,
        name:"FRY",
        productcode:"575361301",
        position:0
    },
    {
        Id:17,
        colourId:6,
        name:"SSG",
        productcode:"575361301",
        position:0
    },
    {
        Id:18,
        colourId:6,
        name:"SOL",
        productcode:"102285",
        position:0
    },
    {
        Id:19,
        colourId:7,
        name:"FRY",
        productcode:"475461301",
        position:0
    },
    {
        Id:20,
        colourId:7,
        name:"SSG",
        productcode:"475461301",
        position:0
    },
    {
        Id:21,
        colourId:7,
        name:"SOL",
        productcode:"102222",
        position:0
    },
    {
        Id:22,
        colourId:8,
        name:"FRY",
        productcode:"F100",
        position:0
    },
    {
        Id:23,
        colourId:8,
        name:"SSG",
        productcode:"F100",
        position:0
    },
    {
        Id:24,
        colourId:8,
        name:"SOL",
        productcode:"104880",
        position:0
    },
    {
        Id:25,
        colourId:9,
        name:"FRY",
        productcode:"298261301",
        position:0
    },
    {
        Id:26,
        colourId:9,
        name:"SSG",
        productcode:"298261301",
        position:0
    },
    {
        Id:27,
        colourId:9,
        name:"SOL",
        productcode:"102147",
        position:0
    },
    {
        Id:28,
        colourId:10,
        name:"FRY",
        productcode:"577061301",
        position:0
    },
    {
        Id:29,
        colourId:10,
        name:"SSG",
        productcode:"577061301",
        position:0
    },
    {
        Id:30,
        colourId:10,
        name:"SOL",
        productcode:"102323",
        position:0
    },
    {
        Id:31,
        colourId:11,
        name:"FRY",
        productcode:"FC2-FTF",
        position:0
    },
    {
        Id:32,
        colourId:11,
        name:"SSG",
        productcode:"0ELSJ",
        position:0
    },
    {
        Id:33,
        colourId:11,
        name:"SOL",
        productcode:"100282",
        position:0
    },
    {
        Id:34,
        colourId:12,
        name:"FRY",
        productcode:"575061301",
        position:0
    },
    {
        Id:35,
        colourId:12,
        name:"SSG",
        productcode:"575061301",
        position:0
    },
    {
        Id:36,
        colourId:12,
        name:"SOL",
        productcode:"102277",
        position:0
    },
    {
        Id:37,
        colourId:13,
        name:"FRY",
        productcode:"475061301",
        position:0
    },
    {
        Id:38,
        colourId:13,
        name:"SSG",
        productcode:"475061301",
        position:0
    },
    {
        Id:39,
        colourId:13,
        name:"SOL",
        productcode:"102207",
        position:0
    },
    {
        Id:40,
        colourId:14,
        name:"FRY",
        productcode:"475161401",
        position:0
    },
    {
        Id:41,
        colourId:14,
        name:"SSG",
        productcode:"475161401",
        position:0
    },
    {
        Id:42,
        colourId:14,
        name:"SOL",
        productcode:"102219",
        position:0
    },
    {
        Id:43,
        colourId:15,
        name:"FRY",
        productcode:"575161301",
        position:0
    },
    {
        Id:44,
        colourId:15,
        name:"SSG",
        productcode:"575161301",
        position:0
    },
    {
        Id:45,
        colourId:15,
        name:"SOL",
        productcode:"102283",
        position:0
    },
    {
        Id:46,
        colourId:16,
        name:"FRY",
        productcode:"OM94",
        position:0
    },
    {
        Id:47,
        colourId:16,
        name:"SSG",
        productcode:"0M94 001",
        position:0
    },
    {
        Id:48,
        colourId:16,
        name:"SOL",
        productcode:"100933",
        position:0
    },
    {
        Id:49,
        colourId:17,
        name:"FRY",
        productcode:"298161301",
        position:0
    },
    {
        Id:50,
        colourId:17,
        name:"SSG",
        productcode:"298161301",
        position:0
    },
    {
        Id:51,
        colourId:17,
        name:"SOL",
        productcode:"102142",
        position:0
    },
    {
        Id:52,
        colourId:18,
        name:"FRY",
        productcode:"298061301",
        position:0
    },
    {
        Id:53,
        colourId:18,
        name:"SSG",
        productcode:"298061301",
        position:0
    },
    {
        Id:54,
        colourId:18,
        name:"SOL",
        productcode:"102141",
        position:0
    },
    {
        Id:55,
        colourId:19,
        name:"FRY",
        productcode:"576161101",
        position:0
    },
    {
        Id:56,
        colourId:19,
        name:"SSG",
        productcode:"576161101",
        position:0
    },
    {
        Id:57,
        colourId:19,
        name:"SOL",
        productcode:"102314",
        position:0
    },
    {
        Id:58,
        colourId:20,
        name:"FRY",
        productcode:"576162501",
        position:0
    },
    {
        Id:59,
        colourId:20,
        name:"SSG",
        productcode:"576162501",
        position:0
    },
    {
        Id:60,
        colourId:20,
        name:"SOL",
        productcode:"102315",
        position:0
    },
    {
        Id:61,
        colourId:21,
        name:"FRY",
        productcode:"576165701",
        position:0
    },
    {
        Id:62,
        colourId:21,
        name:"SSG",
        productcode:"576165701",
        position:0
    },
    {
        Id:63,
        colourId:21,
        name:"SOL",
        productcode:"102316",
        position:0
    },
    {
        Id:64,
        colourId:22,
        name:"FRY",
        productcode:"576166501",
        position:0
    },
    {
        Id:65,
        colourId:22,
        name:"SSG",
        productcode:"576166501",
        position:0
    },
    {
        Id:66,
        colourId:22,
        name:"SOL",
        productcode:"102317",
        position:0
    },
    {
        Id:67,
        colourId:23,
        name:"FRY",
        productcode:"576061101",
        position:0
    },
    {
        Id:68,
        colourId:23,
        name:"SSG",
        productcode:"576061101",
        position:0
    },
    {
        Id:69,
        colourId:23,
        name:"SOL",
        productcode:"102310",
        position:0
    },
    {
        Id:70,
        colourId:24,
        name:"FRY",
        productcode:"576062501",
        position:0
    },
    {
        Id:71,
        colourId:24,
        name:"SSG",
        productcode:"576062501",
        position:0
    },
    {
        Id:72,
        colourId:24,
        name:"SOL",
        productcode:"102311",
        position:0
    },
    {
        Id:73,
        colourId:25,
        name:"FRY",
        productcode:"576065701",
        position:0
    },
    {
        Id:74,
        colourId:25,
        name:"SSG",
        productcode:"576065701",
        position:0
    },
    {
        Id:75,
        colourId:25,
        name:"SOL",
        productcode:"102312",
        position:0
    },
    {
        Id:76,
        colourId:26,
        name:"FRY",
        productcode:"576066501",
        position:0
    },
    {
        Id:77,
        colourId:26,
        name:"SSG",
        productcode:"576066501",
        position:0
    },
    {
        Id:78,
        colourId:26,
        name:"SOL",
        productcode:"102313",
        position:0
    },
    {
        Id:79,
        colourId:27,
        name:"FRY",
        productcode:"FC2",
        position:0
    },
    {
        Id:80,
        colourId:27,
        name:"SSG",
        productcode:"0eklg",
        position:0
    },
    {
        Id:81,
        colourId:27,
        name:"SOL",
        productcode:"100257",
        position:0
    },
    {
        Id:82,
        colourId:28,
        name:"FRY",
        productcode:"FC2",
        position:0
    },
    {
        Id:83,
        colourId:28,
        name:"SSG",
        productcode:"0eklg",
        position:0
    },
    {
        Id:84,
        colourId:28,
        name:"SOL",
        productcode:"109802",
        position:0
    },
    {
        Id:85,
        colourId:29,
        name:"FRY",
        productcode:"FC2",
        position:0
    },
    {
        Id:86,
        colourId:29,
        name:"SSG",
        productcode:"0EKLG 008",
        position:0
    },
    {
        Id:87,
        colourId:29,
        name:"SOL",
        productcode:"100252",
        position:0
    },
    {
        Id:88,
        colourId:30,
        name:"FRY",
        productcode:"FC2",
        position:0
    },
    {
        Id:89,
        colourId:30,
        name:"SSG",
        productcode:"0eklg",
        position:0
    },
    {
        Id:90,
        colourId:30,
        name:"SOL",
        productcode:"100255",
        position:0
    },
    {
        Id:91,
        colourId:31,
        name:"FRY",
        productcode:"FC2",
        position:0
    },
    {
        Id:92,
        colourId:31,
        name:"SSG",
        productcode:"0eklg",
        position:0
    },
    {
        Id:93,
        colourId:31,
        name:"SOL",
        productcode:"109803",
        position:0
    },
    {
        Id:94,
        colourId:32,
        name:"FRY",
        productcode:"FC2-FTF",
        position:0
    },
    {
        Id:95,
        colourId:32,
        name:"SSG",
        productcode:"0ELSJ",
        position:0
    },
    {
        Id:96,
        colourId:32,
        name:"SOL",
        productcode:"110761",
        position:0
    },
    {
        Id:97,
        colourId:33,
        name:"FRY",
        productcode:"FC2-FTF",
        position:0
    },
    {
        Id:98,
        colourId:33,
        name:"SSG",
        productcode:"0ELSJ",
        position:0
    },
    {
        Id:99,
        colourId:33,
        name:"SOL",
        productcode:"100289",
        position:0
    },
    {
        Id:100,
        colourId:34,
        name:"FRY",
        productcode:"FC2-FTF",
        position:0
    },
    {
        Id:101,
        colourId:34,
        name:"SSG",
        productcode:"0ELSJ",
        position:0
    },
    {
        Id:102,
        colourId:34,
        name:"SOL",
        productcode:"100282",
        position:0
    },
    {
        Id:103,
        colourId:35,
        name:"FRY",
        productcode:"FC2-FTF",
        position:0
    },
    {
        Id:104,
        colourId:35,
        name:"SSG",
        productcode:"0ELSJ",
        position:0
    },
    {
        Id:105,
        colourId:35,
        name:"SOL",
        productcode:"100285",
        position:0
    },
    {
        Id:106,
        colourId:36,
        name:"FRY",
        productcode:"FC2-FTF",
        position:0
    },
    {
        Id:107,
        colourId:36,
        name:"SSG",
        productcode:"FC2-FTF YEL",
        position:0
    },
    {
        Id:108,
        colourId:36,
        name:"SOL",
        productcode:"111573",
        position:0
    },
    {
        Id:109,
        colourId:37,
        name:"FRY",
        productcode:"Z0323001TB",
        position:0
    },
    {
        Id:110,
        colourId:37,
        name:"SSG",
        productcode:"F100",
        position:0
    },
    {
        Id:111,
        colourId:37,
        name:"SOL",
        productcode:"104877",
        position:0
    },
    {
        Id:112,
        colourId:38,
        name:"FRY",
        productcode:"F100",
        position:0
    },
    {
        Id:113,
        colourId:38,
        name:"SSG",
        productcode:"F100",
        position:0
    },
    {
        Id:114,
        colourId:38,
        name:"SOL",
        productcode:"104878",
        position:0
    },
    {
        Id:115,
        colourId:39,
        name:"FRY",
        productcode:"Z0323001TB",
        position:0
    },
    {
        Id:116,
        colourId:39,
        name:"SSG",
        productcode:"F100",
        position:0
    },
    {
        Id:117,
        colourId:39,
        name:"SOL",
        productcode:"104863",
        position:0
    },
    {
        Id:118,
        colourId:40,
        name:"FRY",
        productcode:"F100",
        position:0
    },
    {
        Id:119,
        colourId:40,
        name:"SSG",
        productcode:"F100",
        position:0
    },
    {
        Id:120,
        colourId:40,
        name:"SOL",
        productcode:"104873",
        position:0
    },
    {
        Id:121,
        colourId:41,
        name:"FRY",
        productcode:"Z0322800TB",
        position:0
    },
    {
        Id:122,
        colourId:41,
        name:"SSG",
        productcode:"0M94 029",
        position:0
    },
    {
        Id:123,
        colourId:41,
        name:"SOL",
        productcode:"100937",
        position:0
    },
    {
        Id:124,
        colourId:42,
        name:"FRY",
        productcode:"OM94",
        position:0
    },
    {
        Id:125,
        colourId:42,
        name:"SSG",
        productcode:"0M94 008",
        position:0
    },
    {
        Id:126,
        colourId:42,
        name:"SOL",
        productcode:"100934",
        position:0
    },
    {
        Id:127,
        colourId:43,
        name:"FRY",
        productcode:"Z0322800TB",
        position:0
    },
    {
        Id:128,
        colourId:43,
        name:"SSG",
        productcode:"0M94 010",
        position:0
    },
    {
        Id:129,
        colourId:43,
        name:"SOL",
        productcode:"110431",
        position:0
    },
    {
        Id:130,
        colourId:44,
        name:"FRY",
        productcode:"Z0322800TB",
        position:0
    },
    {
        Id:131,
        colourId:44,
        name:"SSG",
        productcode:"0M94 012",
        position:0
    },
    {
        Id:132,
        colourId:44,
        name:"SOL",
        productcode:"100935",
        position:0
    },
    {
        Id:133,
        colourId:45,
        name:"FRY",
        productcode:"875081301",
        position:0
    },
    {
        Id:134,
        colourId:45,
        name:"SSG",
        productcode:"875081301",
        position:0
    },
    {
        Id:135,
        colourId:45,
        name:"SOL",
        productcode:"109833",
        position:0
    },
    {
        Id:136,
        colourId:46,
        name:"FRY",
        productcode:"B200",
        position:0
    },
    {
        Id:137,
        colourId:46,
        name:"SSG",
        productcode:"B200",
        position:0
    },
    {
        Id:138,
        colourId:46,
        name:"SOL",
        productcode:"103295",
        position:0
    },
    {
        Id:139,
        colourId:47,
        name:"FRY",
        productcode:"B200",
        position:0
    },
    {
        Id:140,
        colourId:47,
        name:"SSG",
        productcode:"B200",
        position:0
    },
    {
        Id:141,
        colourId:47,
        name:"SOL",
        productcode:"103292",
        position:0
    },
    {
        Id:142,
        colourId:48,
        name:"FRY",
        productcode:"0300-001",
        position:0
    },
    {
        Id:143,
        colourId:48,
        name:"SSG",
        productcode:"ETYN",
        position:0
    },
    {
        Id:144,
        colourId:48,
        name:"SOL",
        productcode:"104822",
        position:0
    },
    {
        Id:145,
        colourId:49,
        name:"FRY",
        productcode:"0300-790",
        position:0
    },
    {
        Id:146,
        colourId:49,
        name:"SSG",
        productcode:"ETYN",
        position:0
    },
    {
        Id:147,
        colourId:49,
        name:"SOL",
        productcode:"104819",
        position:0
    },
    {
        Id:148,
        colourId:50,
        name:"FRY",
        productcode:"0330-001",
        position:0
    },
    {
        Id:149,
        colourId:50,
        name:"SSG",
        productcode:"0TYR",
        position:0
    },
    {
        Id:150,
        colourId:50,
        name:"SOL",
        productcode:"101564",
        position:0
    },
    {
        Id:151,
        colourId:51,
        name:"FRY",
        productcode:"0330-790",
        position:0
    },
    {
        Id:152,
        colourId:51,
        name:"SSG",
        productcode:"0TYR",
        position:0
    },
    {
        Id:153,
        colourId:51,
        name:"SOL",
        productcode:"101561",
        position:0
    },
    {
        Id:154,
        colourId:52,
        name:"FRY",
        productcode:"4077-102-101",
        position:0
    },
    {
        Id:155,
        colourId:52,
        name:"SSG",
        productcode:"HYRU",
        position:0
    },
    {
        Id:156,
        colourId:52,
        name:"SOL",
        productcode:"105536",
        position:0
    },
    {
        Id:157,
        colourId:53,
        name:"FRY",
        productcode:"CC22",
        position:0
    },
    {
        Id:158,
        colourId:53,
        name:"SSG",
        productcode:"CC22 001",
        position:0
    },
    {
        Id:159,
        colourId:53,
        name:"SOL",
        productcode:"103694",
        position:0
    },
    {
        Id:160,
        colourId:54,
        name:"FRY",
        productcode:"CC24",
        position:0
    },
    {
        Id:161,
        colourId:54,
        name:"SSG",
        productcode:"CC24 001",
        position:0
    },
    {
        Id:162,
        colourId:54,
        name:"SOL",
        productcode:"103697",
        position:0
    },
    {
        Id:163,
        colourId:55,
        name:"FRY",
        productcode:"23591-4100-1-11-101",
        position:0
    },
    {
        Id:164,
        colourId:55,
        name:"SSG",
        productcode:"0MNB",
        position:0
    },
    {
        Id:165,
        colourId:55,
        name:"SOL",
        productcode:"101053",
        position:0
    },
    {
        Id:166,
        colourId:56,
        name:"FRY",
        productcode:"DD08AFDE",
        position:0
    },
    {
        Id:167,
        colourId:56,
        name:"SSG",
        productcode:"0JSAE",
        position:0
    },
    {
        Id:168,
        colourId:56,
        name:"SOL",
        productcode:"100848",
        position:0
    },
    {
        Id:169,
        colourId:57,
        name:"FRY",
        productcode:"430361401",
        position:0
    },
    {
        Id:170,
        colourId:57,
        name:"SSG",
        productcode:"430361401",
        position:0
    },
    {
        Id:171,
        colourId:57,
        name:"SOL",
        productcode:"102200",
        position:0
    },
    {
        Id:172,
        colourId:58,
        name:"FRY",
        productcode:"430361499",
        position:0
    },
    {
        Id:173,
        colourId:58,
        name:"SSG",
        productcode:"430361499",
        position:0
    },
    {
        Id:174,
        colourId:58,
        name:"SOL",
        productcode:"Not Set Up",
        position:0
    },
    {
        Id:175,
        colourId:59,
        name:"FRY",
        productcode:"430163401",
        position:0
    },
    {
        Id:176,
        colourId:59,
        name:"SSG",
        productcode:"430163401",
        position:0
    },
    {
        Id:177,
        colourId:59,
        name:"SOL",
        productcode:"111371",
        position:0
    },
    {
        Id:178,
        colourId:60,
        name:"FRY",
        productcode:"430169901",
        position:0
    },
    {
        Id:179,
        colourId:60,
        name:"SSG",
        productcode:"430169901",
        position:0
    },
    {
        Id:180,
        colourId:60,
        name:"SOL",
        productcode:"102199",
        position:0
    },
    {
        Id:181,
        colourId:61,
        name:"FRY",
        productcode:"430163499",
        position:0
    },
    {
        Id:182,
        colourId:61,
        name:"SSG",
        productcode:"430163499",
        position:0
    },
    {
        Id:183,
        colourId:61,
        name:"SOL",
        productcode:"102198",
        position:0
    },
    {
        Id:184,
        colourId:62,
        name:"FRY",
        productcode:"4111R1499",
        position:0
    },
    {
        Id:185,
        colourId:62,
        name:"SSG",
        productcode:"4111R1499",
        position:0
    },
    {
        Id:186,
        colourId:62,
        name:"SOL",
        productcode:"102189",
        position:0
    },
    {
        Id:187,
        colourId:63,
        name:"FRY",
        productcode:"25209-105-101",
        position:0
    },
    {
        Id:188,
        colourId:63,
        name:"SSG",
        productcode:"25209-105-101",
        position:0
    },
    {
        Id:189,
        colourId:63,
        name:"SOL",
        productcode:"111698",
        position:0
    },
    {
        Id:190,
        colourId:64,
        name:"FRY",
        productcode:"CC05",
        position:0
    },
    {
        Id:191,
        colourId:64,
        name:"SSG",
        productcode:"CC05 024",
        position:0
    },
    {
        Id:192,
        colourId:64,
        name:"SOL",
        productcode:"103666",
        position:0
    },
    {
        Id:193,
        colourId:65,
        name:"FRY",
        productcode:"232061499",
        position:0
    },
    {
        Id:194,
        colourId:65,
        name:"SSG",
        productcode:"232061499",
        position:0
    },
    {
        Id:195,
        colourId:65,
        name:"SOL",
        productcode:"102027",
        position:0
    },
    {
        Id:196,
        colourId:66,
        name:"FRY",
        productcode:"297181332",
        position:0
    },
    {
        Id:197,
        colourId:66,
        name:"SSG",
        productcode:"297181332",
        position:0
    },
    {
        Id:198,
        colourId:66,
        name:"SOL",
        productcode:"111515",
        position:0
    },
    {
        Id:199,
        colourId:67,
        name:"FRY",
        productcode:"CC07",
        position:0
    },
    {
        Id:200,
        colourId:67,
        name:"SSG",
        productcode:"CC07 471",
        position:0
    },
    {
        Id:201,
        colourId:67,
        name:"SOL",
        productcode:"103669",
        position:0
    },
    {
        Id:202,
        colourId:68,
        name:"FRY",
        productcode:"CC06",
        position:0
    },
    {
        Id:203,
        colourId:68,
        name:"SSG",
        productcode:"CC06 476",
        position:0
    },
    {
        Id:204,
        colourId:68,
        name:"SOL",
        productcode:"103668",
        position:0
    },
    {
        Id:205,
        colourId:69,
        name:"FRY",
        productcode:"60061401",
        position:0
    },
    {
        Id:206,
        colourId:69,
        name:"SSG",
        productcode:"60061401",
        position:0
    },
    {
        Id:207,
        colourId:69,
        name:"SOL",
        productcode:"100055",
        position:0
    },
    {
        Id:208,
        colourId:70,
        name:"FRY",
        productcode:"60061469",
        position:0
    },
    {
        Id:209,
        colourId:70,
        name:"SSG",
        productcode:"60061469",
        position:0
    },
    {
        Id:210,
        colourId:70,
        name:"SOL",
        productcode:"100061",
        position:0
    },
    {
        Id:211,
        colourId:71,
        name:"FRY",
        productcode:"60061434",
        position:0
    },
    {
        Id:212,
        colourId:71,
        name:"SSG",
        productcode:"60061434",
        position:0
    },
    {
        Id:213,
        colourId:71,
        name:"SOL",
        productcode:"100058",
        position:0
    },
    {
        Id:214,
        colourId:72,
        name:"FRY",
        productcode:"60061499",
        position:0
    },
    {
        Id:215,
        colourId:72,
        name:"SSG",
        productcode:"60061499",
        position:0
    },
    {
        Id:216,
        colourId:72,
        name:"SOL",
        productcode:"100062",
        position:0
    },
    {
        Id:217,
        colourId:73,
        name:"FRY",
        productcode:"62061401",
        position:0
    },
    {
        Id:218,
        colourId:73,
        name:"SSG",
        productcode:"62061401",
        position:0
    },
    {
        Id:219,
        colourId:73,
        name:"SOL",
        productcode:"100066",
        position:0
    },
    {
        Id:220,
        colourId:74,
        name:"FRY",
        productcode:"62061434",
        position:0
    },
    {
        Id:221,
        colourId:74,
        name:"SSG",
        productcode:"62061434",
        position:0
    },
    {
        Id:222,
        colourId:74,
        name:"SOL",
        productcode:"100069",
        position:0
    },
    {
        Id:223,
        colourId:75,
        name:"FRY",
        productcode:"62061469",
        position:0
    },
    {
        Id:224,
        colourId:75,
        name:"SSG",
        productcode:"62061469",
        position:0
    },
    {
        Id:225,
        colourId:75,
        name:"SOL",
        productcode:"100071",
        position:0
    },
    {
        Id:226,
        colourId:76,
        name:"FRY",
        productcode:"62061499",
        position:0
    },
    {
        Id:227,
        colourId:76,
        name:"SSG",
        productcode:"62061499",
        position:0
    },
    {
        Id:228,
        colourId:76,
        name:"SOL",
        productcode:"100072",
        position:0
    },
    {
        Id:229,
        colourId:77,
        name:"FRY",
        productcode:"CC09",
        position:0
    },
    {
        Id:230,
        colourId:77,
        name:"SSG",
        productcode:"CC09",
        position:0
    },
    {
        Id:231,
        colourId:77,
        name:"SOL",
        productcode:"103674",
        position:0
    },
    {
        Id:232,
        colourId:78,
        name:"FRY",
        productcode:"DP50B",
        position:0
    },
    {
        Id:233,
        colourId:78,
        name:"SSG",
        productcode:"HYUO",
        position:0
    },
    {
        Id:234,
        colourId:78,
        name:"SOL",
        productcode:"105539",
        position:0
    },
    {
        Id:235,
        colourId:79,
        name:"FRY",
        productcode:"CTW-2BLK",
        position:0
    },
    {
        Id:236,
        colourId:79,
        name:"SSG",
        productcode:"CC16 024",
        position:0
    },
    {
        Id:237,
        colourId:79,
        name:"SOL",
        productcode:"103685",
        position:0
    },
    {
        Id:238,
        colourId:80,
        name:"FRY",
        productcode:"CTW-2WHT",
        position:0
    },
    {
        Id:239,
        colourId:80,
        name:"SSG",
        productcode:"CC16 001",
        position:0
    },
    {
        Id:240,
        colourId:80,
        name:"SOL",
        productcode:"103684",
        position:0
    },
    {
        Id:241,
        colourId:81,
        name:"FRY",
        productcode:"0300-001",
        position:0
    },
    {
        Id:242,
        colourId:81,
        name:"SSG",
        productcode:"ETYN",
        position:0
    },
    {
        Id:243,
        colourId:81,
        name:"SOL",
        productcode:"104822",
        position:0
    },
    {
        Id:244,
        colourId:82,
        name:"FRY",
        productcode:"0300-900",
        position:0
    },
    {
        Id:245,
        colourId:82,
        name:"SSG",
        productcode:"ETYN",
        position:0
    },
    {
        Id:246,
        colourId:82,
        name:"SOL",
        productcode:"104816",
        position:0
    },
    {
        Id:247,
        colourId:83,
        name:"FRY",
        productcode:"0300-295",
        position:0
    },
    {
        Id:248,
        colourId:83,
        name:"SSG",
        productcode:"ETYN",
        position:0
    },
    {
        Id:249,
        colourId:83,
        name:"SOL",
        productcode:"104817",
        position:0
    },
    {
        Id:250,
        colourId:84,
        name:"FRY",
        productcode:"0300-790",
        position:0
    },
    {
        Id:251,
        colourId:84,
        name:"SSG",
        productcode:"ETYN",
        position:0
    },
    {
        Id:252,
        colourId:84,
        name:"SOL",
        productcode:"104819",
        position:0
    },
    {
        Id:253,
        colourId:85,
        name:"FRY",
        productcode:"0320-001",
        position:0
    },
    {
        Id:254,
        colourId:85,
        name:"SSG",
        productcode:"0YSC",
        position:0
    },
    {
        Id:255,
        colourId:85,
        name:"SOL",
        productcode:"101736",
        position:0
    },
    {
        Id:256,
        colourId:86,
        name:"FRY",
        productcode:"0320-900",
        position:0
    },
    {
        Id:257,
        colourId:86,
        name:"SSG",
        productcode:"0YSC",
        position:0
    },
    {
        Id:258,
        colourId:86,
        name:"SOL",
        productcode:"101731",
        position:0
    },
    {
        Id:259,
        colourId:87,
        name:"FRY",
        productcode:"0320-295",
        position:0
    },
    {
        Id:260,
        colourId:87,
        name:"SSG",
        productcode:"0YSC",
        position:0
    },
    {
        Id:261,
        colourId:87,
        name:"SOL",
        productcode:"101732",
        position:0
    },
    {
        Id:262,
        colourId:88,
        name:"FRY",
        productcode:"0320-790",
        position:0
    },
    {
        Id:263,
        colourId:88,
        name:"SSG",
        productcode:"0YSC",
        position:0
    },
    {
        Id:264,
        colourId:88,
        name:"SOL",
        productcode:"101733",
        position:0
    },
    {
        Id:265,
        colourId:89,
        name:"FRY",
        productcode:"EL62426",
        position:0
    },
    {
        Id:266,
        colourId:89,
        name:"SSG",
        productcode:"EL62426",
        position:0
    },
    {
        Id:267,
        colourId:89,
        name:"SOL",
        productcode:"104650",
        position:0
    },
    {
        Id:268,
        colourId:90,
        name:"FRY",
        productcode:"EL67734",
        position:0
    },
    {
        Id:269,
        colourId:90,
        name:"SSG",
        productcode:"EL67734",
        position:0
    },
    {
        Id:270,
        colourId:90,
        name:"SOL",
        productcode:"109736",
        position:0
    },
    {
        Id:271,
        colourId:91,
        name:"FRY",
        productcode:"EL62428",
        position:0
    },
    {
        Id:272,
        colourId:91,
        name:"SSG",
        productcode:"EL62428",
        position:0
    },
    {
        Id:273,
        colourId:91,
        name:"SOL",
        productcode:"104658",
        position:0
    },
    {
        Id:274,
        colourId:92,
        name:"FRY",
        productcode:"EL67733",
        position:0
    },
    {
        Id:275,
        colourId:92,
        name:"SSG",
        productcode:"EL67733",
        position:0
    },
    {
        Id:276,
        colourId:92,
        name:"SOL",
        productcode:"109735",
        position:0
    },
    {
        Id:277,
        colourId:93,
        name:"FRY",
        productcode:"EL72401",
        position:0
    },
    {
        Id:278,
        colourId:93,
        name:"SSG",
        productcode:"EL72401",
        position:0
    },
    {
        Id:279,
        colourId:93,
        name:"SOL",
        productcode:"109742",
        position:0
    },
    {
        Id:280,
        colourId:94,
        name:"FRY",
        productcode:"EL62429",
        position:0
    },
    {
        Id:281,
        colourId:94,
        name:"SSG",
        productcode:"EL62429",
        position:0
    },
    {
        Id:282,
        colourId:94,
        name:"SOL",
        productcode:"104661",
        position:0
    },
    {
        Id:283,
        colourId:95,
        name:"FRY",
        productcode:"EL72413",
        position:0
    },
    {
        Id:284,
        colourId:95,
        name:"SSG",
        productcode:"EL72413",
        position:0
    },
    {
        Id:285,
        colourId:95,
        name:"SOL",
        productcode:"104698",
        position:0
    },
    {
        Id:286,
        colourId:96,
        name:"FRY",
        productcode:"EL72422",
        position:0
    },
    {
        Id:287,
        colourId:96,
        name:"SSG",
        productcode:"EL72422",
        position:0
    },
    {
        Id:288,
        colourId:96,
        name:"SOL",
        productcode:"109744",
        position:0
    },
    {
        Id:289,
        colourId:97,
        name:"FRY",
        productcode:"EL62427",
        position:0
    },
    {
        Id:290,
        colourId:97,
        name:"SSG",
        productcode:"EL62427",
        position:0
    },
    {
        Id:291,
        colourId:97,
        name:"SOL",
        productcode:"104654",
        position:0
    },
    {
        Id:292,
        colourId:98,
        name:"FRY",
        productcode:"EL67735",
        position:0
    },
    {
        Id:293,
        colourId:98,
        name:"SSG",
        productcode:"EL67735",
        position:0
    },
    {
        Id:294,
        colourId:98,
        name:"SOL",
        productcode:"109737",
        position:0
    },
    {
        Id:295,
        colourId:99,
        name:"FRY",
        productcode:"EL62430",
        position:0
    },
    {
        Id:296,
        colourId:99,
        name:"SSG",
        productcode:"EL62430",
        position:0
    },
    {
        Id:297,
        colourId:99,
        name:"SOL",
        productcode:"104665",
        position:0
    },
    {
        Id:298,
        colourId:100,
        name:"FRY",
        productcode:"EL72424",
        position:0
    },
    {
        Id:299,
        colourId:100,
        name:"SSG",
        productcode:"EL72424",
        position:0
    },
    {
        Id:300,
        colourId:100,
        name:"SOL",
        productcode:"109745",
        position:0
    },
    {
        Id:301,
        colourId:101,
        name:"FRY",
        productcode:"EL32023",
        position:0
    },
    {
        Id:302,
        colourId:101,
        name:"SSG",
        productcode:"EL32023",
        position:0
    },
    {
        Id:303,
        colourId:101,
        name:"SOL",
        productcode:"104614",
        position:0
    },
    {
        Id:304,
        colourId:102,
        name:"FRY",
        productcode:"EL6725",
        position:0
    },
    {
        Id:305,
        colourId:102,
        name:"SSG",
        productcode:"EL6725",
        position:0
    },
    {
        Id:306,
        colourId:102,
        name:"SOL",
        productcode:"104680",
        position:0
    },
    {
        Id:307,
        colourId:103,
        name:"FRY",
        productcode:"EL62426",
        position:0
    },
    {
        Id:308,
        colourId:103,
        name:"SSG",
        productcode:"EL62426",
        position:0
    },
    {
        Id:309,
        colourId:103,
        name:"SOL",
        productcode:"104650",
        position:0
    },
    {
        Id:310,
        colourId:104,
        name:"FRY",
        productcode:"EL67734",
        position:0
    },
    {
        Id:311,
        colourId:104,
        name:"SSG",
        productcode:"EL67734",
        position:0
    },
    {
        Id:312,
        colourId:104,
        name:"SOL",
        productcode:"109736",
        position:0
    },
    {
        Id:313,
        colourId:105,
        name:"FRY",
        productcode:"EL62428",
        position:0
    },
    {
        Id:314,
        colourId:105,
        name:"SSG",
        productcode:"EL62428",
        position:0
    },
    {
        Id:315,
        colourId:105,
        name:"SOL",
        productcode:"104658",
        position:0
    },
    {
        Id:316,
        colourId:106,
        name:"FRY",
        productcode:"EL67733",
        position:0
    },
    {
        Id:317,
        colourId:106,
        name:"SSG",
        productcode:"EL67733",
        position:0
    },
    {
        Id:318,
        colourId:106,
        name:"SOL",
        productcode:"109735",
        position:0
    },
    {
        Id:319,
        colourId:107,
        name:"FRY",
        productcode:"EL72413",
        position:0
    },
    {
        Id:320,
        colourId:107,
        name:"SSG",
        productcode:"EL72413",
        position:0
    },
    {
        Id:321,
        colourId:107,
        name:"SOL",
        productcode:"104698",
        position:0
    },
    {
        Id:322,
        colourId:108,
        name:"FRY",
        productcode:"EL72422",
        position:0
    },
    {
        Id:323,
        colourId:108,
        name:"SSG",
        productcode:"EL72422",
        position:0
    },
    {
        Id:324,
        colourId:108,
        name:"SOL",
        productcode:"109744",
        position:0
    },
    {
        Id:325,
        colourId:109,
        name:"FRY",
        productcode:"EL76941",
        position:0
    },
    {
        Id:326,
        colourId:109,
        name:"SSG",
        productcode:"EL76941",
        position:0
    },
    {
        Id:327,
        colourId:109,
        name:"SOL",
        productcode:"109748",
        position:0
    },
    {
        Id:328,
        colourId:110,
        name:"FRY",
        productcode:"EL76932",
        position:0
    },
    {
        Id:329,
        colourId:110,
        name:"SSG",
        productcode:"EL76932",
        position:0
    },
    {
        Id:330,
        colourId:110,
        name:"SOL",
        productcode:"104723",
        position:0
    },
    {
        Id:331,
        colourId:111,
        name:"FRY",
        productcode:"EL616966",
        position:0
    },
    {
        Id:332,
        colourId:111,
        name:"SSG",
        productcode:"EL616966",
        position:0
    },
    {
        Id:333,
        colourId:111,
        name:"SOL",
        productcode:"110478",
        position:0
    },
    {
        Id:334,
        colourId:112,
        name:"FRY",
        productcode:"EL67727",
        position:0
    },
    {
        Id:335,
        colourId:112,
        name:"SSG",
        productcode:"EL67727",
        position:0
    },
    {
        Id:336,
        colourId:112,
        name:"SOL",
        productcode:"104684",
        position:0
    },
    {
        Id:337,
        colourId:113,
        name:"FRY",
        productcode:"EL62425",
        position:0
    },
    {
        Id:338,
        colourId:113,
        name:"SSG",
        productcode:"EL62425",
        position:0
    },
    {
        Id:339,
        colourId:113,
        name:"SOL",
        productcode:"104649",
        position:0
    },
    {
        Id:340,
        colourId:114,
        name:"FRY",
        productcode:"EL67730",
        position:0
    },
    {
        Id:341,
        colourId:114,
        name:"SSG",
        productcode:"EL67730",
        position:0
    },
    {
        Id:342,
        colourId:114,
        name:"SOL",
        productcode:"109732",
        position:0
    },
    {
        Id:343,
        colourId:115,
        name:"FRY",
        productcode:"EL62424",
        position:0
    },
    {
        Id:344,
        colourId:115,
        name:"SSG",
        productcode:"EL62424",
        position:0
    },
    {
        Id:345,
        colourId:115,
        name:"SOL",
        productcode:"104648",
        position:0
    },
    {
        Id:346,
        colourId:116,
        name:"FRY",
        productcode:"EL67731",
        position:0
    },
    {
        Id:347,
        colourId:116,
        name:"SSG",
        productcode:"EL67731",
        position:0
    },
    {
        Id:348,
        colourId:116,
        name:"SOL",
        productcode:"109733",
        position:0
    },
    {
        Id:349,
        colourId:117,
        name:"FRY",
        productcode:"EL82343",
        position:0
    },
    {
        Id:350,
        colourId:117,
        name:"SSG",
        productcode:"EL82343",
        position:0
    },
    {
        Id:351,
        colourId:117,
        name:"SOL",
        productcode:"104730",
        position:0
    },
    {
        Id:352,
        colourId:118,
        name:"FRY",
        productcode:"EL74306",
        position:0
    },
    {
        Id:353,
        colourId:118,
        name:"SSG",
        productcode:"EL74306",
        position:0
    },
    {
        Id:354,
        colourId:118,
        name:"SOL",
        productcode:"104707",
        position:0
    },
    {
        Id:355,
        colourId:119,
        name:"FRY",
        productcode:"EL74310",
        position:0
    },
    {
        Id:356,
        colourId:119,
        name:"SSG",
        productcode:"EL74310",
        position:0
    },
    {
        Id:357,
        colourId:119,
        name:"SOL",
        productcode:"104711",
        position:0
    },
    {
        Id:358,
        colourId:120,
        name:"FRY",
        productcode:"EL74309",
        position:0
    },
    {
        Id:359,
        colourId:120,
        name:"SSG",
        productcode:"EL74309",
        position:0
    },
    {
        Id:360,
        colourId:120,
        name:"SOL",
        productcode:"104710",
        position:0
    },
    {
        Id:361,
        colourId:121,
        name:"FRY",
        productcode:"EL74307",
        position:0
    },
    {
        Id:362,
        colourId:121,
        name:"SSG",
        productcode:"EL74307",
        position:0
    },
    {
        Id:363,
        colourId:121,
        name:"SOL",
        productcode:"104708",
        position:0
    },
    {
        Id:364,
        colourId:122,
        name:"FRY",
        productcode:"EL74308",
        position:0
    },
    {
        Id:365,
        colourId:122,
        name:"SSG",
        productcode:"EL74308",
        position:0
    },
    {
        Id:366,
        colourId:122,
        name:"SOL",
        productcode:"104709",
        position:0
    },
    {
        Id:367,
        colourId:123,
        name:"FRY",
        productcode:"EL74300",
        position:0
    },
    {
        Id:368,
        colourId:123,
        name:"SSG",
        productcode:"EL74300",
        position:0
    },
    {
        Id:369,
        colourId:123,
        name:"SOL",
        productcode:"104703",
        position:0
    },
    {
        Id:370,
        colourId:124,
        name:"FRY",
        productcode:"EL62433",
        position:0
    },
    {
        Id:371,
        colourId:124,
        name:"SSG",
        productcode:"EL62433",
        position:0
    },
    {
        Id:372,
        colourId:124,
        name:"SOL",
        productcode:"104673",
        position:0
    },
    {
        Id:373,
        colourId:125,
        name:"FRY",
        productcode:"EL67732",
        position:0
    },
    {
        Id:374,
        colourId:125,
        name:"SSG",
        productcode:"EL67732",
        position:0
    },
    {
        Id:375,
        colourId:125,
        name:"SOL",
        productcode:"109734",
        position:0
    },
    {
        Id:376,
        colourId:126,
        name:"FRY",
        productcode:"EL4400",
        position:0
    },
    {
        Id:377,
        colourId:126,
        name:"SSG",
        productcode:"N\/A",
        position:0
    },
    {
        Id:378,
        colourId:126,
        name:"SOL",
        productcode:"N\/A",
        position:0
    },
    {
        Id:379,
        colourId:127,
        name:"FRY",
        productcode:"EL4401",
        position:0
    },
    {
        Id:380,
        colourId:127,
        name:"SSG",
        productcode:"109888",
        position:0
    },
    {
        Id:381,
        colourId:128,
        name:"FRY",
        productcode:"EL615174",
        position:0
    },
    {
        Id:382,
        colourId:128,
        name:"SSG",
        productcode:"EL615174",
        position:0
    },
    {
        Id:383,
        colourId:128,
        name:"SOL",
        productcode:"109783",
        position:0
    },
    {
        Id:384,
        colourId:129,
        name:"FRY",
        productcode:"EL615173",
        position:0
    },
    {
        Id:385,
        colourId:129,
        name:"SSG",
        productcode:"EL615173",
        position:0
    },
    {
        Id:386,
        colourId:129,
        name:"SOL",
        productcode:"109784",
        position:0
    },
    {
        Id:387,
        colourId:130,
        name:"FRY",
        productcode:"EL615176",
        position:0
    },
    {
        Id:388,
        colourId:130,
        name:"SSG",
        productcode:"EL615176",
        position:0
    },
    {
        Id:389,
        colourId:130,
        name:"SOL",
        productcode:"109780",
        position:0
    },
    {
        Id:390,
        colourId:131,
        name:"FRY",
        productcode:"EL615175",
        position:0
    },
    {
        Id:391,
        colourId:131,
        name:"SSG",
        productcode:"EL615175",
        position:0
    },
    {
        Id:392,
        colourId:131,
        name:"SOL",
        productcode:"109781",
        position:0
    },
    {
        Id:393,
        colourId:132,
        name:"FRY",
        productcode:"EL3225",
        position:0
    },
    {
        Id:394,
        colourId:132,
        name:"SSG",
        productcode:"EL3225",
        position:0
    },
    {
        Id:395,
        colourId:132,
        name:"SOL",
        productcode:"104617",
        position:0
    },
    {
        Id:396,
        colourId:133,
        name:"FRY",
        productcode:"EL16664",
        position:0
    },
    {
        Id:397,
        colourId:133,
        name:"SSG",
        productcode:"EL16664",
        position:0
    },
    {
        Id:398,
        colourId:133,
        name:"SOL",
        productcode:"109730",
        position:0
    },
    {
        Id:399,
        colourId:134,
        name:"FRY",
        productcode:"EL44667",
        position:0
    },
    {
        Id:400,
        colourId:134,
        name:"SSG",
        productcode:"EL44667",
        position:0
    },
    {
        Id:401,
        colourId:134,
        name:"SOL",
        productcode:"110400",
        position:0
    },
    {
        Id:402,
        colourId:135,
        name:"FRY",
        productcode:"EL44666",
        position:0
    },
    {
        Id:403,
        colourId:135,
        name:"SSG",
        productcode:"EL44666",
        position:0
    },
    {
        Id:404,
        colourId:135,
        name:"SOL",
        productcode:"109759",
        position:0
    },
    {
        Id:405,
        colourId:136,
        name:"FRY",
        productcode:"EL62434",
        position:0
    },
    {
        Id:406,
        colourId:136,
        name:"SSG",
        productcode:"EL62434",
        position:0
    },
    {
        Id:407,
        colourId:136,
        name:"SOL",
        productcode:"104678",
        position:0
    },
    {
        Id:408,
        colourId:137,
        name:"FRY",
        productcode:"EL67737",
        position:0
    },
    {
        Id:409,
        colourId:137,
        name:"SSG",
        productcode:"EL67737",
        position:0
    },
    {
        Id:410,
        colourId:137,
        name:"SOL",
        productcode:"109739",
        position:0
    },
    {
        Id:411,
        colourId:138,
        name:"FRY",
        productcode:"EL62435",
        position:0
    },
    {
        Id:412,
        colourId:138,
        name:"SSG",
        productcode:"EL62435",
        position:0
    },
    {
        Id:413,
        colourId:138,
        name:"SOL",
        productcode:"110006",
        position:0
    },
    {
        Id:414,
        colourId:139,
        name:"FRY",
        productcode:"EL67738",
        position:0
    },
    {
        Id:415,
        colourId:139,
        name:"SSG",
        productcode:"EL67738",
        position:0
    },
    {
        Id:416,
        colourId:139,
        name:"SOL",
        productcode:"109740",
        position:0
    },
    {
        Id:417,
        colourId:140,
        name:"FRY",
        productcode:"EL76765",
        position:0
    },
    {
        Id:418,
        colourId:140,
        name:"SSG",
        productcode:"EL76765",
        position:0
    },
    {
        Id:419,
        colourId:140,
        name:"SOL",
        productcode:"109747",
        position:0
    },
    {
        Id:420,
        colourId:141,
        name:"FRY",
        productcode:"EL75388",
        position:0
    },
    {
        Id:421,
        colourId:141,
        name:"SSG",
        productcode:"EL75388",
        position:0
    },
    {
        Id:422,
        colourId:141,
        name:"SOL",
        productcode:"104720",
        position:0
    },
    {
        Id:423,
        colourId:142,
        name:"FRY",
        productcode:"EL62432",
        position:0
    },
    {
        Id:424,
        colourId:142,
        name:"SSG",
        productcode:"EL62432",
        position:0
    },
    {
        Id:425,
        colourId:142,
        name:"SOL",
        productcode:"104669",
        position:0
    },
    {
        Id:426,
        colourId:143,
        name:"FRY",
        productcode:"EL67736",
        position:0
    },
    {
        Id:427,
        colourId:143,
        name:"SSG",
        productcode:"EL67736",
        position:0
    },
    {
        Id:428,
        colourId:143,
        name:"SOL",
        productcode:"109738",
        position:0
    },
    {
        Id:429,
        colourId:144,
        name:"FRY",
        productcode:"EL72426",
        position:0
    },
    {
        Id:430,
        colourId:144,
        name:"SSG",
        productcode:"EL72426",
        position:0
    },
    {
        Id:431,
        colourId:144,
        name:"SOL",
        productcode:"104702",
        position:0
    },
    {
        Id:432,
        colourId:145,
        name:"FRY",
        productcode:"EL72435",
        position:0
    },
    {
        Id:433,
        colourId:145,
        name:"SSG",
        productcode:"EL72435",
        position:0
    },
    {
        Id:434,
        colourId:145,
        name:"SOL",
        productcode:"109746",
        position:0
    },
    {
        Id:435,
        colourId:146,
        name:"FRY",
        productcode:"EL611063",
        position:0
    },
    {
        Id:436,
        colourId:146,
        name:"SSG",
        productcode:"EL611063",
        position:0
    },
    {
        Id:437,
        colourId:146,
        name:"SOL",
        productcode:"110906",
        position:0
    },
    {
        Id:438,
        colourId:147,
        name:"FRY",
        productcode:"EL77162",
        position:0
    },
    {
        Id:439,
        colourId:147,
        name:"SSG",
        productcode:"EL77162",
        position:0
    },
    {
        Id:440,
        colourId:147,
        name:"SOL",
        productcode:"110024",
        position:0
    },
    {
        Id:441,
        colourId:148,
        name:"FRY",
        productcode:"EL74315123",
        position:0
    },
    {
        Id:442,
        colourId:148,
        name:"SSG",
        productcode:"EL74315123",
        position:0
    },
    {
        Id:443,
        colourId:148,
        name:"SOL",
        productcode:"110459",
        position:0
    },
    {
        Id:444,
        colourId:149,
        name:"FRY",
        productcode:"EL74315124",
        position:0
    },
    {
        Id:445,
        colourId:149,
        name:"SSG",
        productcode:"EL74315124",
        position:0
    },
    {
        Id:446,
        colourId:149,
        name:"SOL",
        productcode:"110460",
        position:0
    },
    {
        Id:447,
        colourId:150,
        name:"FRY",
        productcode:"EL74315125",
        position:0
    },
    {
        Id:448,
        colourId:150,
        name:"SSG",
        productcode:"EL74315125",
        position:0
    },
    {
        Id:449,
        colourId:150,
        name:"SOL",
        productcode:"110461",
        position:0
    },
    {
        Id:450,
        colourId:151,
        name:"FRY",
        productcode:"EL74315126",
        position:0
    },
    {
        Id:451,
        colourId:151,
        name:"SSG",
        productcode:"EL74315126",
        position:0
    },
    {
        Id:452,
        colourId:151,
        name:"SOL",
        productcode:"110462",
        position:0
    },
    {
        Id:453,
        colourId:152,
        name:"FRY",
        productcode:"EL74315123",
        position:0
    },
    {
        Id:454,
        colourId:152,
        name:"SSG",
        productcode:"EL74315123",
        position:0
    },
    {
        Id:455,
        colourId:152,
        name:"SOL",
        productcode:"110459",
        position:0
    },
    {
        Id:456,
        colourId:153,
        name:"FRY",
        productcode:"EL74315124",
        position:0
    },
    {
        Id:457,
        colourId:153,
        name:"SSG",
        productcode:"EL74315124",
        position:0
    },
    {
        Id:458,
        colourId:153,
        name:"SOL",
        productcode:"110460",
        position:0
    },
    {
        Id:459,
        colourId:154,
        name:"FRY",
        productcode:"EL74315125",
        position:0
    },
    {
        Id:460,
        colourId:154,
        name:"SSG",
        productcode:"EL74315125",
        position:0
    },
    {
        Id:461,
        colourId:154,
        name:"SOL",
        productcode:"110461",
        position:0
    },
    {
        Id:462,
        colourId:155,
        name:"FRY",
        productcode:"EL74315126",
        position:0
    },
    {
        Id:463,
        colourId:155,
        name:"SSG",
        productcode:"EL74315126",
        position:0
    },
    {
        Id:464,
        colourId:155,
        name:"SOL",
        productcode:"110462",
        position:0
    },
    {
        Id:465,
        colourId:156,
        name:"FRY",
        productcode:"EL71163",
        position:0
    },
    {
        Id:466,
        colourId:156,
        name:"SSG",
        productcode:"EL71163",
        position:0
    },
    {
        Id:467,
        colourId:156,
        name:"SOL",
        productcode:"110104",
        position:0
    },
    {
        Id:468,
        colourId:157,
        name:"FRY",
        productcode:"EL71163",
        position:0
    },
    {
        Id:469,
        colourId:157,
        name:"SSG",
        productcode:"EL71163",
        position:0
    },
    {
        Id:470,
        colourId:157,
        name:"SOL",
        productcode:"110104",
        position:0
    },
    {
        Id:471,
        colourId:158,
        name:"FRY",
        productcode:"916085538",
        position:0
    },
    {
        Id:472,
        colourId:158,
        name:"SSG",
        productcode:"916085538",
        position:0
    },
    {
        Id:473,
        colourId:158,
        name:"SOL",
        productcode:"102660",
        position:0
    },
    {
        Id:474,
        colourId:159,
        name:"FRY",
        productcode:"F20062569",
        position:0
    },
    {
        Id:475,
        colourId:159,
        name:"SSG",
        productcode:"F20062569",
        position:0
    },
    {
        Id:476,
        colourId:159,
        name:"SOL",
        productcode:"104906",
        position:0
    },
    {
        Id:477,
        colourId:160,
        name:"FRY",
        productcode:"F56062569",
        position:0
    },
    {
        Id:478,
        colourId:160,
        name:"SSG",
        productcode:"F56062569",
        position:0
    },
    {
        Id:479,
        colourId:160,
        name:"SOL",
        productcode:"104936",
        position:0
    },
    {
        Id:480,
        colourId:161,
        name:"FRY",
        productcode:"F51062569",
        position:0
    },
    {
        Id:481,
        colourId:161,
        name:"SSG",
        productcode:"F51062569",
        position:0
    },
    {
        Id:482,
        colourId:161,
        name:"SOL",
        productcode:"104934",
        position:0
    },
    {
        Id:483,
        colourId:162,
        name:"FRY",
        productcode:"F70062569",
        position:0
    },
    {
        Id:484,
        colourId:162,
        name:"SSG",
        productcode:"F70062569",
        position:0
    },
    {
        Id:485,
        colourId:162,
        name:"SOL",
        productcode:"104959",
        position:0
    },
    {
        Id:486,
        colourId:164,
        name:"FRY",
        productcode:"BWUN",
        position:0
    },
    {
        Id:487,
        colourId:164,
        name:"SSG",
        productcode:"BWUN 687",
        position:0
    },
    {
        Id:488,
        colourId:164,
        name:"SOL",
        productcode:"103399",
        position:0
    },
    {
        Id:489,
        colourId:168,
        name:"FRY",
        productcode:"1059",
        position:0
    },
    {
        Id:490,
        colourId:168,
        name:"SSG",
        productcode:"ZHEBF 012",
        position:0
    },
    {
        Id:491,
        colourId:168,
        name:"SOL",
        productcode:"109372",
        position:0
    },
    {
        Id:492,
        colourId:169,
        name:"FRY",
        productcode:"ZJLEU 012",
        position:0
    },
    {
        Id:493,
        colourId:169,
        name:"SSG",
        productcode:"ZJLEU 012",
        position:0
    },
    {
        Id:494,
        colourId:169,
        name:"SOL",
        productcode:"109479",
        position:0
    },
    {
        Id:495,
        colourId:170,
        name:"FRY",
        productcode:"ZNAJO 012",
        position:0
    },
    {
        Id:496,
        colourId:170,
        name:"SSG",
        productcode:"ZNAJO 012",
        position:0
    },
    {
        Id:497,
        colourId:170,
        name:"SOL",
        productcode:"109524",
        position:0
    },
    {
        Id:498,
        colourId:171,
        name:"FRY",
        productcode:"809063699",
        position:0
    },
    {
        Id:499,
        colourId:171,
        name:"SSG",
        productcode:"809063699",
        position:0
    },
    {
        Id:500,
        colourId:171,
        name:"SOL",
        productcode:"102560",
        position:0
    },
    {
        Id:501,
        colourId:172,
        name:"FRY",
        productcode:"809066569",
        position:0
    },
    {
        Id:502,
        colourId:172,
        name:"SSG",
        productcode:"809066569",
        position:0
    },
    {
        Id:503,
        colourId:172,
        name:"SOL",
        productcode:"102561",
        position:0
    },
    {
        Id:504,
        colourId:173,
        name:"FRY",
        productcode:"809066965",
        position:0
    },
    {
        Id:505,
        colourId:173,
        name:"SSG",
        productcode:"809066965",
        position:0
    },
    {
        Id:506,
        colourId:173,
        name:"SOL",
        productcode:"102562",
        position:0
    },
    {
        Id:507,
        colourId:174,
        name:"FRY",
        productcode:"832061169",
        position:0
    },
    {
        Id:508,
        colourId:174,
        name:"SSG",
        productcode:"832061169",
        position:0
    },
    {
        Id:509,
        colourId:174,
        name:"SOL",
        productcode:"102622",
        position:0
    },
    {
        Id:510,
        colourId:175,
        name:"FRY",
        productcode:"EL74885 and EL1529\n",
        position:0
    },
    {
        Id:511,
        colourId:175,
        name:"SSG",
        productcode:"EL74885 and EL1529\n",
        position:0
    },
    {
        Id:512,
        colourId:175,
        name:"SOL",
        productcode:"104717 and 104582",
        position:0
    },
    {
        Id:513,
        colourId:176,
        name:"FRY",
        productcode:"EL74885 and EL1503\n",
        position:0
    },
    {
        Id:514,
        colourId:176,
        name:"SSG",
        productcode:"EL74885 and EL1503\n",
        position:0
    },
    {
        Id:515,
        colourId:176,
        name:"SOL",
        productcode:"104717 and 104580",
        position:0
    },
    {
        Id:516,
        colourId:177,
        name:"FRY",
        productcode:"EL74885 and EL1528\n",
        position:0
    },
    {
        Id:517,
        colourId:177,
        name:"SSG",
        productcode:"EL74885 and EL1528\n",
        position:0
    },
    {
        Id:518,
        colourId:177,
        name:"SOL",
        productcode:"104717 and 104581",
        position:0
    },
    {
        Id:519,
        colourId:178,
        name:"FRY",
        productcode:"850536469",
        position:0
    },
    {
        Id:520,
        colourId:178,
        name:"SSG",
        productcode:"850536469",
        position:0
    },
    {
        Id:521,
        colourId:178,
        name:"SOL",
        productcode:"102623",
        position:0
    },
    {
        Id:522,
        colourId:179,
        name:"FRY",
        productcode:"776636469",
        position:0
    },
    {
        Id:523,
        colourId:179,
        name:"SSG",
        productcode:"776636469",
        position:0
    },
    {
        Id:524,
        colourId:179,
        name:"SOL",
        productcode:"102511",
        position:0
    },
    {
        Id:525,
        colourId:180,
        name:"FRY",
        productcode:"291236469",
        position:0
    },
    {
        Id:526,
        colourId:180,
        name:"SSG",
        productcode:"291236469",
        position:0
    },
    {
        Id:527,
        colourId:180,
        name:"SOL",
        productcode:"102111",
        position:0
    },
    {
        Id:528,
        colourId:181,
        name:"FRY",
        productcode:"819388519",
        position:0
    },
    {
        Id:529,
        colourId:181,
        name:"SSG",
        productcode:"819388519",
        position:0
    },
    {
        Id:530,
        colourId:181,
        name:"SOL",
        productcode:"102597",
        position:0
    },
    {
        Id:531,
        colourId:182,
        name:"FRY",
        productcode:"819388525",
        position:0
    },
    {
        Id:532,
        colourId:182,
        name:"SSG",
        productcode:"819388525",
        position:0
    },
    {
        Id:533,
        colourId:182,
        name:"SOL",
        productcode:"102598",
        position:0
    },
    {
        Id:534,
        colourId:183,
        name:"FRY",
        productcode:"819388569",
        position:0
    },
    {
        Id:535,
        colourId:183,
        name:"SSG",
        productcode:"819388569",
        position:0
    },
    {
        Id:536,
        colourId:183,
        name:"SOL",
        productcode:"102599",
        position:0
    },
    {
        Id:537,
        colourId:184,
        name:"FRY",
        productcode:"723388519",
        position:0
    },
    {
        Id:538,
        colourId:184,
        name:"SSG",
        productcode:"723388519",
        position:0
    },
    {
        Id:539,
        colourId:184,
        name:"SOL",
        productcode:"102460",
        position:0
    },
    {
        Id:540,
        colourId:185,
        name:"FRY",
        productcode:"723388525",
        position:0
    },
    {
        Id:541,
        colourId:185,
        name:"SSG",
        productcode:"723388525",
        position:0
    },
    {
        Id:542,
        colourId:185,
        name:"SOL",
        productcode:"102461",
        position:0
    },
    {
        Id:543,
        colourId:186,
        name:"FRY",
        productcode:"723388569",
        position:0
    },
    {
        Id:544,
        colourId:186,
        name:"SSG",
        productcode:"723388569",
        position:0
    },
    {
        Id:545,
        colourId:186,
        name:"SOL",
        productcode:"102462",
        position:0
    },
    {
        Id:546,
        colourId:187,
        name:"FRY",
        productcode:"223388519",
        position:0
    },
    {
        Id:547,
        colourId:187,
        name:"SSG",
        productcode:"223388519",
        position:0
    },
    {
        Id:548,
        colourId:187,
        name:"SOL",
        productcode:"102004",
        position:0
    },
    {
        Id:549,
        colourId:188,
        name:"FRY",
        productcode:"223388525",
        position:0
    },
    {
        Id:550,
        colourId:188,
        name:"SSG",
        productcode:"223388525",
        position:0
    },
    {
        Id:551,
        colourId:188,
        name:"SOL",
        productcode:"102005",
        position:0
    },
    {
        Id:552,
        colourId:189,
        name:"FRY",
        productcode:"223388569",
        position:0
    },
    {
        Id:553,
        colourId:189,
        name:"SSG",
        productcode:"223388569",
        position:0
    },
    {
        Id:554,
        colourId:189,
        name:"SOL",
        productcode:"102006",
        position:0
    },
    {
        Id:555,
        colourId:190,
        name:"FRY",
        productcode:"521182569",
        position:0
    },
    {
        Id:556,
        colourId:190,
        name:"SSG",
        productcode:"521182569",
        position:0
    },
    {
        Id:557,
        colourId:190,
        name:"SOL",
        productcode:"109715",
        position:0
    },
    {
        Id:558,
        colourId:191,
        name:"FRY",
        productcode:"818764969",
        position:0
    },
    {
        Id:559,
        colourId:191,
        name:"SSG",
        productcode:"818764969",
        position:0
    },
    {
        Id:560,
        colourId:191,
        name:"SOL",
        productcode:"102593",
        position:0
    },
    {
        Id:561,
        colourId:192,
        name:"FRY",
        productcode:"818764969",
        position:0
    },
    {
        Id:562,
        colourId:192,
        name:"SSG",
        productcode:"818764969",
        position:0
    },
    {
        Id:563,
        colourId:192,
        name:"SOL",
        productcode:"102593",
        position:0
    },
    {
        Id:564,
        colourId:193,
        name:"FRY",
        productcode:"721A64969",
        position:0
    },
    {
        Id:565,
        colourId:193,
        name:"SSG",
        productcode:"721A64969",
        position:0
    },
    {
        Id:566,
        colourId:193,
        name:"SOL",
        productcode:"102453",
        position:0
    },
    {
        Id:567,
        colourId:194,
        name:"FRY",
        productcode:"721A64969",
        position:0
    },
    {
        Id:568,
        colourId:194,
        name:"SSG",
        productcode:"721A64969",
        position:0
    },
    {
        Id:569,
        colourId:194,
        name:"SOL",
        productcode:"102453",
        position:0
    },
    {
        Id:570,
        colourId:195,
        name:"FRY",
        productcode:"222964969",
        position:0
    },
    {
        Id:571,
        colourId:195,
        name:"SSG",
        productcode:"222964969",
        position:0
    },
    {
        Id:572,
        colourId:195,
        name:"SOL",
        productcode:"101993",
        position:0
    },
    {
        Id:573,
        colourId:196,
        name:"FRY",
        productcode:"222964969",
        position:0
    },
    {
        Id:574,
        colourId:196,
        name:"SSG",
        productcode:"222964969",
        position:0
    },
    {
        Id:575,
        colourId:196,
        name:"SOL",
        productcode:"101993",
        position:0
    },
    {
        Id:576,
        colourId:197,
        name:"FRY",
        productcode:"819082569",
        position:0
    },
    {
        Id:577,
        colourId:197,
        name:"SSG",
        productcode:"819082569",
        position:0
    },
    {
        Id:578,
        colourId:197,
        name:"SOL",
        productcode:"102595",
        position:0
    },
    {
        Id:579,
        colourId:198,
        name:"FRY",
        productcode:"723082569",
        position:0
    },
    {
        Id:580,
        colourId:198,
        name:"SSG",
        productcode:"723082569",
        position:0
    },
    {
        Id:581,
        colourId:198,
        name:"SOL",
        productcode:"102456",
        position:0
    },
    {
        Id:582,
        colourId:199,
        name:"FRY",
        productcode:"223082569",
        position:0
    },
    {
        Id:583,
        colourId:199,
        name:"SSG",
        productcode:"223082569",
        position:0
    },
    {
        Id:584,
        colourId:199,
        name:"SOL",
        productcode:"101998",
        position:0
    },
    {
        Id:585,
        colourId:200,
        name:"FRY",
        productcode:"401WEA",
        position:0
    },
    {
        Id:586,
        colourId:200,
        name:"SSG",
        productcode:"B450",
        position:0
    },
    {
        Id:587,
        colourId:200,
        name:"SOL",
        productcode:"103314",
        position:0
    },
    {
        Id:588,
        colourId:201,
        name:"FRY",
        productcode:"F100",
        position:0
    },
    {
        Id:589,
        colourId:201,
        name:"SSG",
        productcode:"F100",
        position:0
    },
    {
        Id:590,
        colourId:201,
        name:"SOL",
        productcode:"104873",
        position:0
    },
    {
        Id:591,
        colourId:202,
        name:"FRY",
        productcode:"F100",
        position:0
    },
    {
        Id:592,
        colourId:202,
        name:"SSG",
        productcode:"F100",
        position:0
    },
    {
        Id:593,
        colourId:202,
        name:"SOL",
        productcode:"104878",
        position:0
    },
    {
        Id:594,
        colourId:203,
        name:"FRY",
        productcode:"95216FL11",
        position:0
    },
    {
        Id:595,
        colourId:203,
        name:"SSG",
        productcode:"95216FL11",
        position:0
    },
    {
        Id:596,
        colourId:203,
        name:"SOL",
        productcode:"102672",
        position:0
    },
    {
        Id:597,
        colourId:204,
        name:"FRY",
        productcode:"95216FL18",
        position:0
    },
    {
        Id:598,
        colourId:204,
        name:"SSG",
        productcode:"95216FL18",
        position:0
    },
    {
        Id:599,
        colourId:204,
        name:"SOL",
        productcode:"102673",
        position:0
    },
    {
        Id:600,
        colourId:205,
        name:"FRY",
        productcode:"D70062111",
        position:0
    },
    {
        Id:601,
        colourId:205,
        name:"SSG",
        productcode:"D70062111",
        position:0
    },
    {
        Id:602,
        colourId:205,
        name:"SOL",
        productcode:"104094",
        position:0
    },
    {
        Id:603,
        colourId:206,
        name:"FRY",
        productcode:"D70062118",
        position:0
    },
    {
        Id:604,
        colourId:206,
        name:"SSG",
        productcode:"D70062118",
        position:0
    },
    {
        Id:605,
        colourId:206,
        name:"SOL",
        productcode:"104095",
        position:0
    },
    {
        Id:606,
        colourId:207,
        name:"FRY",
        productcode:"D78062111",
        position:0
    },
    {
        Id:607,
        colourId:207,
        name:"SSG",
        productcode:"D78062111",
        position:0
    },
    {
        Id:608,
        colourId:207,
        name:"SOL",
        productcode:"104101",
        position:0
    },
    {
        Id:609,
        colourId:208,
        name:"FRY",
        productcode:"D78062118",
        position:0
    },
    {
        Id:610,
        colourId:208,
        name:"SSG",
        productcode:"D78062118",
        position:0
    },
    {
        Id:611,
        colourId:208,
        name:"SOL",
        productcode:"104102",
        position:0
    },
    {
        Id:612,
        colourId:209,
        name:"FRY",
        productcode:"C60062411",
        position:0
    },
    {
        Id:613,
        colourId:209,
        name:"SSG",
        productcode:"C60062411",
        position:0
    },
    {
        Id:614,
        colourId:209,
        name:"SOL",
        productcode:"103594",
        position:0
    },
    {
        Id:615,
        colourId:210,
        name:"FRY",
        productcode:"744066911\n052066511*(Hood)",
        position:0
    },
    {
        Id:616,
        colourId:211,
        name:"FRY",
        productcode:"884066911",
        position:0
    },
    {
        Id:617,
        colourId:211,
        name:"SSG",
        productcode:"884066911",
        position:0
    },
    {
        Id:618,
        colourId:211,
        name:"SOL",
        productcode:"102648",
        position:0
    },
    {
        Id:619,
        colourId:212,
        name:"FRY",
        productcode:"744166911\n052066511*(Hood)",
        position:0
    },
    {
        Id:620,
        colourId:212,
        name:"SSG",
        productcode:"744166911\n052066511*(Hood)",
        position:0
    },
    {
        Id:621,
        colourId:212,
        name:"SOL",
        productcode:"102484",
        position:0
    },
    {
        Id:622,
        colourId:213,
        name:"FRY",
        productcode:"284066911",
        position:0
    },
    {
        Id:623,
        colourId:213,
        name:"SSG",
        productcode:"284066911",
        position:0
    },
    {
        Id:624,
        colourId:214,
        name:"FRY",
        productcode:"284261169",
        position:0
    },
    {
        Id:625,
        colourId:214,
        name:"SSG",
        productcode:"284261169",
        position:0
    },
    {
        Id:626,
        colourId:214,
        name:"SOL",
        productcode:"No SOL code",
        position:0
    },
    {
        Id:627,
        colourId:215,
        name:"FRY",
        productcode:"C100",
        position:0
    },
    {
        Id:628,
        colourId:215,
        name:"SSG",
        productcode:"C100",
        position:0
    },
    {
        Id:629,
        colourId:216,
        name:"FRY",
        productcode:"C100",
        position:0
    },
    {
        Id:630,
        colourId:216,
        name:"SSG",
        productcode:"C100",
        position:0
    },
    {
        Id:631,
        colourId:217,
        name:"FRY",
        productcode:"B100",
        position:0
    },
    {
        Id:632,
        colourId:218,
        name:"FRY",
        productcode:"B100",
        position:0
    },
    {
        Id:633,
        colourId:219,
        name:"FRY",
        productcode:"753161169",
        position:0
    },
    {
        Id:634,
        colourId:219,
        name:"SSG",
        productcode:"753161169",
        position:0
    },
    {
        Id:635,
        colourId:219,
        name:"SOL",
        productcode:"102505",
        position:0
    },
    {
        Id:636,
        colourId:220,
        name:"FRY",
        productcode:"785061169",
        position:0
    },
    {
        Id:637,
        colourId:220,
        name:"SSG",
        productcode:"785061169",
        position:0
    },
    {
        Id:638,
        colourId:220,
        name:"SOL",
        productcode:"102514",
        position:0
    },
    {
        Id:639,
        colourId:221,
        name:"FRY",
        productcode:"785061869",
        position:0
    },
    {
        Id:640,
        colourId:221,
        name:"SSG",
        productcode:"785061869",
        position:0
    },
    {
        Id:641,
        colourId:221,
        name:"SOL",
        productcode:"102515",
        position:0
    },
    {
        Id:642,
        colourId:222,
        name:"FRY",
        productcode:"255061169",
        position:0
    },
    {
        Id:643,
        colourId:222,
        name:"SSG",
        productcode:"255061169",
        position:0
    },
    {
        Id:644,
        colourId:222,
        name:"SOL",
        productcode:"102082",
        position:0
    },
    {
        Id:645,
        colourId:223,
        name:"FRY",
        productcode:"255061869",
        position:0
    },
    {
        Id:646,
        colourId:223,
        name:"SSG",
        productcode:"255061869",
        position:0
    },
    {
        Id:647,
        colourId:223,
        name:"SOL",
        productcode:"102083",
        position:0
    },
    {
        Id:648,
        colourId:224,
        name:"FRY",
        productcode:"D6006ZT69",
        position:0
    },
    {
        Id:649,
        colourId:224,
        name:"SSG",
        productcode:"D6006ZT69",
        position:0
    },
    {
        Id:650,
        colourId:224,
        name:"SOL",
        productcode:"104084",
        position:0
    },
    {
        Id:651,
        colourId:225,
        name:"FRY",
        productcode:"D6006ZX65",
        position:0
    },
    {
        Id:652,
        colourId:225,
        name:"SSG",
        productcode:"D6006ZX65",
        position:0
    },
    {
        Id:653,
        colourId:225,
        name:"SOL",
        productcode:"104085",
        position:0
    },
    {
        Id:654,
        colourId:226,
        name:"FRY",
        productcode:"D6006ZH69",
        position:0
    },
    {
        Id:655,
        colourId:226,
        name:"SSG",
        productcode:"D6006ZH69",
        position:0
    },
    {
        Id:656,
        colourId:226,
        name:"SOL",
        productcode:"104063",
        position:0
    },
    {
        Id:657,
        colourId:227,
        name:"FRY",
        productcode:"*0626",
        position:0
    },
    {
        Id:658,
        colourId:227,
        name:"SSG",
        productcode:"ZJHEN",
        position:0
    },
    {
        Id:659,
        colourId:227,
        name:"SOL",
        productcode:"109421",
        position:0
    },
    {
        Id:660,
        colourId:228,
        name:"FRY",
        productcode:"*0624",
        position:0
    },
    {
        Id:661,
        colourId:228,
        name:"SSG",
        productcode:"ZJHEO",
        position:0
    },
    {
        Id:662,
        colourId:228,
        name:"SOL",
        productcode:"109422",
        position:0
    },
    {
        Id:663,
        colourId:229,
        name:"FRY",
        productcode:"*0625",
        position:0
    },
    {
        Id:664,
        colourId:229,
        name:"SSG",
        productcode:"ZJHEP",
        position:0
    },
    {
        Id:665,
        colourId:229,
        name:"SOL",
        productcode:"109423",
        position:0
    },
    {
        Id:666,
        colourId:230,
        name:"FRY",
        productcode:"831061169",
        position:0
    },
    {
        Id:667,
        colourId:230,
        name:"SSG",
        productcode:"831061169",
        position:0
    },
    {
        Id:668,
        colourId:230,
        name:"SOL",
        productcode:"102619",
        position:0
    },
    {
        Id:669,
        colourId:231,
        name:"FRY",
        productcode:"831061169",
        position:0
    },
    {
        Id:670,
        colourId:231,
        name:"SSG",
        productcode:"831061169",
        position:0
    },
    {
        Id:671,
        colourId:231,
        name:"SOL",
        productcode:"102619",
        position:0
    },
    {
        Id:672,
        colourId:232,
        name:"FRY",
        productcode:"736061169",
        position:0
    },
    {
        Id:673,
        colourId:232,
        name:"SSG",
        productcode:"736061169",
        position:0
    },
    {
        Id:674,
        colourId:232,
        name:"SOL",
        productcode:"102476",
        position:0
    },
    {
        Id:675,
        colourId:233,
        name:"FRY",
        productcode:"736061169",
        position:0
    },
    {
        Id:676,
        colourId:233,
        name:"SSG",
        productcode:"736061169",
        position:0
    },
    {
        Id:677,
        colourId:233,
        name:"SOL",
        productcode:"102476",
        position:0
    },
    {
        Id:678,
        colourId:234,
        name:"FRY",
        productcode:"791061169 \/ (Hood 052268011)",
        position:0
    },
    {
        Id:679,
        colourId:234,
        name:"SSG",
        productcode:"736061169",
        position:0
    },
    {
        Id:680,
        colourId:234,
        name:"SOL",
        productcode:"102476",
        position:0
    },
    {
        Id:681,
        colourId:235,
        name:"FRY",
        productcode:"791061169 \/ (Hood 052268011)",
        position:0
    },
    {
        Id:682,
        colourId:235,
        name:"SSG",
        productcode:"736061169",
        position:0
    },
    {
        Id:683,
        colourId:235,
        name:"SOL",
        productcode:"102476",
        position:0
    },
    {
        Id:684,
        colourId:236,
        name:"FRY",
        productcode:"236261169",
        position:0
    },
    {
        Id:685,
        colourId:236,
        name:"SSG",
        productcode:"236261169",
        position:0
    },
    {
        Id:686,
        colourId:236,
        name:"SOL",
        productcode:"102045",
        position:0
    },
    {
        Id:687,
        colourId:237,
        name:"FRY",
        productcode:"236261169",
        position:0
    },
    {
        Id:688,
        colourId:237,
        name:"SSG",
        productcode:"236261169",
        position:0
    },
    {
        Id:689,
        colourId:237,
        name:"SOL",
        productcode:"102045",
        position:0
    },
    {
        Id:690,
        colourId:238,
        name:"FRY",
        productcode:"70406ZX65",
        position:0
    },
    {
        Id:691,
        colourId:238,
        name:"SSG",
        productcode:"70406ZX65",
        position:0
    },
    {
        Id:692,
        colourId:238,
        name:"SOL",
        productcode:"102411",
        position:0
    },
    {
        Id:693,
        colourId:240,
        name:"FRY",
        productcode:"EL618889",
        position:0
    },
    {
        Id:694,
        colourId:241,
        name:"FRY",
        productcode:"EL618890",
        position:0
    },
    {
        Id:695,
        colourId:242,
        name:"FRY",
        productcode:"EL618891",
        position:0
    },
    {
        Id:696,
        colourId:243,
        name:"FRY",
        productcode:"EL618895",
        position:0
    },
    {
        Id:697,
        colourId:244,
        name:"FRY",
        productcode:"EL618893",
        position:0
    },
    {
        Id:698,
        colourId:245,
        name:"FRY",
        productcode:"EL618892",
        position:0
    },
    {
        Id:699,
        colourId:246,
        name:"FRY",
        productcode:"EL618894",
        position:0
    },
    {
        Id:700,
        colourId:247,
        name:"FRY",
        productcode:"EL618896",
        position:0
    },
    {
        Id:701,
        colourId:248,
        name:"FRY",
        productcode:"D55063699",
        position:0
    },
    {
        Id:702,
        colourId:248,
        name:"SSG",
        productcode:"D55063699",
        position:0
    },
    {
        Id:703,
        colourId:248,
        name:"SOL",
        productcode:"104071",
        position:0
    },
    {
        Id:704,
        colourId:249,
        name:"FRY",
        productcode:"D55069936",
        position:0
    },
    {
        Id:705,
        colourId:249,
        name:"SSG",
        productcode:"D55069936",
        position:0
    },
    {
        Id:706,
        colourId:249,
        name:"SOL",
        productcode:"104073",
        position:0
    },
    {
        Id:707,
        colourId:250,
        name:"FRY",
        productcode:"D55066569",
        position:0
    },
    {
        Id:708,
        colourId:250,
        name:"SSG",
        productcode:"D55066569",
        position:0
    },
    {
        Id:709,
        colourId:250,
        name:"SOL",
        productcode:"104072",
        position:0
    },
    {
        Id:710,
        colourId:251,
        name:"FRY",
        productcode:"D6006ZN99",
        position:0
    },
    {
        Id:711,
        colourId:251,
        name:"SSG",
        productcode:"D6006ZN99",
        position:0
    },
    {
        Id:712,
        colourId:251,
        name:"SOL",
        productcode:"104083",
        position:0
    },
    {
        Id:713,
        colourId:252,
        name:"FRY",
        productcode:"D55G61269",
        position:0
    },
    {
        Id:714,
        colourId:252,
        name:"SSG",
        productcode:"D55G61269",
        position:0
    },
    {
        Id:715,
        colourId:252,
        name:"SOL",
        productcode:"104076",
        position:0
    },
    {
        Id:716,
        colourId:257,
        name:"FRY",
        productcode:"4460",
        position:0
    },
    {
        Id:717,
        colourId:257,
        name:"SSG",
        productcode:"ZHVW",
        position:0
    },
    {
        Id:718,
        colourId:257,
        name:"SOL",
        productcode:"No SOL code",
        position:0
    },
    {
        Id:719,
        colourId:258,
        name:"FRY",
        productcode:"4400",
        position:0
    },
    {
        Id:720,
        colourId:258,
        name:"SSG",
        productcode:"ZSKAF",
        position:0
    },
    {
        Id:721,
        colourId:258,
        name:"SOL",
        productcode:"109562",
        position:0
    },
    {
        Id:722,
        colourId:259,
        name:"FRY",
        productcode:"4410",
        position:0
    },
    {
        Id:723,
        colourId:259,
        name:"SSG",
        productcode:"ZJHER",
        position:0
    },
    {
        Id:724,
        colourId:259,
        name:"SOL",
        productcode:"109425",
        position:0
    },
    {
        Id:725,
        colourId:260,
        name:"FRY",
        productcode:"880066911",
        position:0
    },
    {
        Id:726,
        colourId:260,
        name:"SSG",
        productcode:"880066911",
        position:0
    },
    {
        Id:727,
        colourId:260,
        name:"SOL",
        productcode:"102641",
        position:0
    },
    {
        Id:728,
        colourId:261,
        name:"FRY",
        productcode:"880066918",
        position:0
    },
    {
        Id:729,
        colourId:261,
        name:"SSG",
        productcode:"880066918",
        position:0
    },
    {
        Id:730,
        colourId:261,
        name:"SOL",
        productcode:"102642",
        position:0
    },
    {
        Id:731,
        colourId:262,
        name:"FRY",
        productcode:"751066911",
        position:0
    },
    {
        Id:732,
        colourId:262,
        name:"SSG",
        productcode:"751066911",
        position:0
    },
    {
        Id:733,
        colourId:262,
        name:"SOL",
        productcode:"109372",
        position:0
    },
    {
        Id:734,
        colourId:263,
        name:"FRY",
        productcode:"751066918",
        position:0
    },
    {
        Id:735,
        colourId:263,
        name:"SSG",
        productcode:"751066918",
        position:0
    },
    {
        Id:736,
        colourId:263,
        name:"SOL",
        productcode:"102492",
        position:0
    },
    {
        Id:737,
        colourId:264,
        name:"FRY",
        productcode:"752061169",
        position:0
    },
    {
        Id:738,
        colourId:264,
        name:"SSG",
        productcode:"752061169",
        position:0
    },
    {
        Id:739,
        colourId:264,
        name:"SOL",
        productcode:"102499",
        position:0
    },
    {
        Id:740,
        colourId:265,
        name:"FRY",
        productcode:"752061869",
        position:0
    },
    {
        Id:741,
        colourId:265,
        name:"SSG",
        productcode:"752061869",
        position:0
    },
    {
        Id:742,
        colourId:265,
        name:"SOL",
        productcode:"102501",
        position:0
    },
    {
        Id:743,
        colourId:266,
        name:"FRY",
        productcode:"241061169",
        position:0
    },
    {
        Id:744,
        colourId:266,
        name:"SSG",
        productcode:"241061169",
        position:0
    },
    {
        Id:745,
        colourId:266,
        name:"SOL",
        productcode:"102062",
        position:0
    },
    {
        Id:746,
        colourId:267,
        name:"FRY",
        productcode:"241061169",
        position:0
    },
    {
        Id:747,
        colourId:267,
        name:"SSG",
        productcode:"241061169",
        position:0
    },
    {
        Id:748,
        colourId:267,
        name:"SOL",
        productcode:"102062",
        position:0
    },
    {
        Id:749,
        colourId:268,
        name:"FRY",
        productcode:"242066911",
        position:0
    },
    {
        Id:750,
        colourId:268,
        name:"SSG",
        productcode:"242066911",
        position:0
    },
    {
        Id:751,
        colourId:268,
        name:"SOL",
        productcode:"102070",
        position:0
    },
    {
        Id:752,
        colourId:269,
        name:"FRY",
        productcode:"244061169",
        position:0
    },
    {
        Id:753,
        colourId:269,
        name:"SSG",
        productcode:"244061169",
        position:0
    },
    {
        Id:754,
        colourId:269,
        name:"SOL",
        productcode:"110404",
        position:0
    },
    {
        Id:755,
        colourId:270,
        name:"FRY",
        productcode:"242066918",
        position:0
    },
    {
        Id:756,
        colourId:270,
        name:"SSG",
        productcode:"242066918",
        position:0
    },
    {
        Id:757,
        colourId:270,
        name:"SOL",
        productcode:"102071",
        position:0
    },
    {
        Id:758,
        colourId:271,
        name:"FRY",
        productcode:"20826ZN99",
        position:0
    },
    {
        Id:759,
        colourId:271,
        name:"SSG",
        productcode:"20826ZN99",
        position:0
    },
    {
        Id:760,
        colourId:271,
        name:"SOL",
        productcode:"101923",
        position:0
    },
    {
        Id:761,
        colourId:272,
        name:"FRY",
        productcode:"20826ZT69",
        position:0
    },
    {
        Id:762,
        colourId:272,
        name:"SSG",
        productcode:"20826ZT69",
        position:0
    },
    {
        Id:763,
        colourId:272,
        name:"SOL",
        productcode:"101933",
        position:0
    },
    {
        Id:764,
        colourId:273,
        name:"FRY",
        productcode:"830081169",
        position:0
    },
    {
        Id:765,
        colourId:273,
        name:"SSG",
        productcode:"830081169",
        position:0
    },
    {
        Id:766,
        colourId:273,
        name:"SOL",
        productcode:"102616",
        position:0
    },
    {
        Id:767,
        colourId:274,
        name:"FRY",
        productcode:"830281169*",
        position:0
    },
    {
        Id:768,
        colourId:274,
        name:"SSG",
        productcode:"830281169*",
        position:0
    },
    {
        Id:769,
        colourId:274,
        name:"SOL",
        productcode:"102618",
        position:0
    },
    {
        Id:770,
        colourId:275,
        name:"FRY",
        productcode:"735081169",
        position:0
    },
    {
        Id:771,
        colourId:275,
        name:"SSG",
        productcode:"735081169",
        position:0
    },
    {
        Id:772,
        colourId:275,
        name:"SOL",
        productcode:"102471",
        position:0
    },
    {
        Id:773,
        colourId:276,
        name:"FRY",
        productcode:"735381169*",
        position:0
    },
    {
        Id:774,
        colourId:276,
        name:"SSG",
        productcode:"735381169*",
        position:0
    },
    {
        Id:775,
        colourId:276,
        name:"SOL",
        productcode:"102473",
        position:0
    },
    {
        Id:776,
        colourId:277,
        name:"FRY",
        productcode:"736086911",
        position:0
    },
    {
        Id:777,
        colourId:277,
        name:"SSG",
        productcode:"736086911",
        position:0
    },
    {
        Id:778,
        colourId:277,
        name:"SOL",
        productcode:"102477",
        position:0
    },
    {
        Id:779,
        colourId:278,
        name:"FRY",
        productcode:"231081169",
        position:0
    },
    {
        Id:780,
        colourId:278,
        name:"SSG",
        productcode:"231081169",
        position:0
    },
    {
        Id:781,
        colourId:278,
        name:"SOL",
        productcode:"102023",
        position:0
    },
    {
        Id:782,
        colourId:279,
        name:"FRY",
        productcode:"230086911",
        position:0
    },
    {
        Id:783,
        colourId:279,
        name:"SSG",
        productcode:"230086911",
        position:0
    },
    {
        Id:784,
        colourId:279,
        name:"SOL",
        productcode:"102017",
        position:0
    },
    {
        Id:785,
        colourId:280,
        name:"FRY",
        productcode:"D6106ZT69",
        position:0
    },
    {
        Id:786,
        colourId:280,
        name:"SSG",
        productcode:"D6106ZT69",
        position:0
    },
    {
        Id:787,
        colourId:280,
        name:"SOL",
        productcode:"104091",
        position:0
    },
    {
        Id:788,
        colourId:281,
        name:"FRY",
        productcode:"C54063699",
        position:0
    },
    {
        Id:789,
        colourId:281,
        name:"SSG",
        productcode:"C54063699",
        position:0
    },
    {
        Id:790,
        colourId:281,
        name:"SOL",
        productcode:"103567",
        position:0
    },
    {
        Id:791,
        colourId:282,
        name:"FRY",
        productcode:"C54069936",
        position:0
    },
    {
        Id:792,
        colourId:282,
        name:"SSG",
        productcode:"C54069936",
        position:0
    },
    {
        Id:793,
        colourId:282,
        name:"SOL",
        productcode:"103570",
        position:0
    },
    {
        Id:794,
        colourId:283,
        name:"FRY",
        productcode:"C54066569",
        position:0
    },
    {
        Id:795,
        colourId:283,
        name:"SSG",
        productcode:"C54066569",
        position:0
    },
    {
        Id:796,
        colourId:283,
        name:"SOL",
        productcode:"103568",
        position:0
    },
    {
        Id:797,
        colourId:284,
        name:"FRY",
        productcode:"21106Z099",
        position:0
    },
    {
        Id:798,
        colourId:284,
        name:"SSG",
        productcode:"21106Z099",
        position:0
    },
    {
        Id:799,
        colourId:284,
        name:"SOL",
        productcode:"101960",
        position:0
    },
    {
        Id:800,
        colourId:285,
        name:"FRY",
        productcode:"253161169",
        position:0
    },
    {
        Id:801,
        colourId:285,
        name:"SSG",
        productcode:"253161169",
        position:0
    },
    {
        Id:802,
        colourId:285,
        name:"SOL",
        productcode:"102080",
        position:0
    },
    {
        Id:803,
        colourId:286,
        name:"FRY",
        productcode:"20816ZN99",
        position:0
    },
    {
        Id:804,
        colourId:286,
        name:"SSG",
        productcode:"20816ZN99",
        position:0
    },
    {
        Id:805,
        colourId:286,
        name:"SOL",
        productcode:"101921",
        position:0
    },
    {
        Id:806,
        colourId:287,
        name:"FRY",
        productcode:"20816ZT69",
        position:0
    },
    {
        Id:807,
        colourId:287,
        name:"SSG",
        productcode:"20816ZT69",
        position:0
    },
    {
        Id:808,
        colourId:287,
        name:"SOL",
        productcode:"101923",
        position:0
    },
    {
        Id:809,
        colourId:289,
        name:"FRY",
        productcode:"5186",
        position:0
    },
    {
        Id:810,
        colourId:289,
        name:"SSG",
        productcode:"ZWHO",
        position:0
    },
    {
        Id:811,
        colourId:290,
        name:"FRY",
        productcode:"7434",
        position:0
    },
    {
        Id:812,
        colourId:290,
        name:"SSG",
        productcode:"ZJHFF",
        position:0
    },
    {
        Id:813,
        colourId:290,
        name:"SOL",
        productcode:"109433",
        position:0
    },
    {
        Id:814,
        colourId:291,
        name:"FRY",
        productcode:"7435",
        position:0
    },
    {
        Id:815,
        colourId:291,
        name:"SSG",
        productcode:"ZJHFG",
        position:0
    },
    {
        Id:816,
        colourId:291,
        name:"SOL",
        productcode:"109434",
        position:0
    },
    {
        Id:817,
        colourId:292,
        name:"FRY",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:818,
        colourId:292,
        name:"SSG",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:819,
        colourId:293,
        name:"FRY",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:820,
        colourId:293,
        name:"SSG",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:821,
        colourId:294,
        name:"FRY",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:822,
        colourId:294,
        name:"SSG",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:823,
        colourId:295,
        name:"FRY",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:824,
        colourId:295,
        name:"SSG",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:825,
        colourId:295,
        name:"SOL",
        productcode:"104715 and 104716",
        position:0
    },
    {
        Id:826,
        colourId:296,
        name:"FRY",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:827,
        colourId:296,
        name:"SSG",
        productcode:"EL74810_74825",
        position:0
    },
    {
        Id:828,
        colourId:297,
        name:"FRY",
        productcode:"70406ZT69",
        position:0
    },
    {
        Id:829,
        colourId:297,
        name:"SSG",
        productcode:"70406ZT69",
        position:0
    },
    {
        Id:830,
        colourId:297,
        name:"SOL",
        productcode:"102410",
        position:0
    },
    {
        Id:831,
        colourId:298,
        name:"FRY",
        productcode:"Brown - 2319",
        position:0
    },
    {
        Id:832,
        colourId:298,
        name:"SSG",
        productcode:"N2038",
        position:0
    },
    {
        Id:833,
        colourId:298,
        name:"SOL",
        productcode:"106103",
        position:0
    },
    {
        Id:834,
        colourId:299,
        name:"FRY",
        productcode:"Blue 2318",
        position:0
    },
    {
        Id:835,
        colourId:299,
        name:"SSG",
        productcode:"N2042",
        position:0
    },
    {
        Id:836,
        colourId:299,
        name:"SOL",
        productcode:"106107",
        position:0
    },
    {
        Id:837,
        colourId:300,
        name:"FRY",
        productcode:"Black Mink 2150",
        position:0
    },
    {
        Id:838,
        colourId:300,
        name:"SSG",
        productcode:"N2050",
        position:0
    },
    {
        Id:839,
        colourId:300,
        name:"SOL",
        productcode:"106114",
        position:0
    },
    {
        Id:840,
        colourId:301,
        name:"FRY",
        productcode:"N2170",
        position:0
    },
    {
        Id:841,
        colourId:301,
        name:"SSG",
        productcode:"N2170",
        position:0
    },
    {
        Id:842,
        colourId:301,
        name:"SOL",
        productcode:"106186",
        position:0
    },
    {
        Id:843,
        colourId:302,
        name:"FRY",
        productcode:"N2174",
        position:0
    },
    {
        Id:844,
        colourId:302,
        name:"SSG",
        productcode:"N2174",
        position:0
    },
    {
        Id:845,
        colourId:302,
        name:"SOL",
        productcode:"106190",
        position:0
    },
    {
        Id:846,
        colourId:303,
        name:"FRY",
        productcode:"Brown 2319",
        position:0
    },
    {
        Id:847,
        colourId:303,
        name:"SSG",
        productcode:"N2039",
        position:0
    },
    {
        Id:848,
        colourId:303,
        name:"SOL",
        productcode:"106104",
        position:0
    },
    {
        Id:849,
        colourId:304,
        name:"FRY",
        productcode:"Blue 2318",
        position:0
    },
    {
        Id:850,
        colourId:304,
        name:"SSG",
        productcode:"N2043",
        position:0
    },
    {
        Id:851,
        colourId:304,
        name:"SOL",
        productcode:"106108",
        position:0
    },
    {
        Id:852,
        colourId:305,
        name:"FRY",
        productcode:"Black Mink 2150",
        position:0
    },
    {
        Id:853,
        colourId:305,
        name:"SSG",
        productcode:"N2051",
        position:0
    },
    {
        Id:854,
        colourId:305,
        name:"SOL",
        productcode:"106115",
        position:0
    },
    {
        Id:855,
        colourId:306,
        name:"SSG",
        productcode:"N2171",
        position:0
    },
    {
        Id:856,
        colourId:306,
        name:"SOL",
        productcode:"106187",
        position:0
    },
    {
        Id:857,
        colourId:307,
        name:"FRY",
        productcode:"N2175",
        position:0
    },
    {
        Id:858,
        colourId:307,
        name:"SSG",
        productcode:"N2175",
        position:0
    },
    {
        Id:859,
        colourId:307,
        name:"SOL",
        productcode:"106191",
        position:0
    },
    {
        Id:860,
        colourId:308,
        name:"FRY",
        productcode:"Brown 2319",
        position:0
    },
    {
        Id:861,
        colourId:308,
        name:"SSG",
        productcode:"N2040",
        position:0
    },
    {
        Id:862,
        colourId:308,
        name:"SOL",
        productcode:"106105",
        position:0
    },
    {
        Id:863,
        colourId:309,
        name:"FRY",
        productcode:"Blue 2318",
        position:0
    },
    {
        Id:864,
        colourId:309,
        name:"SSG",
        productcode:"N2044",
        position:0
    },
    {
        Id:865,
        colourId:309,
        name:"SOL",
        productcode:"106109",
        position:0
    },
    {
        Id:866,
        colourId:310,
        name:"FRY",
        productcode:"Black Mink 2150",
        position:0
    },
    {
        Id:867,
        colourId:310,
        name:"SSG",
        productcode:"N2052",
        position:0
    },
    {
        Id:868,
        colourId:310,
        name:"SOL",
        productcode:"106116",
        position:0
    },
    {
        Id:869,
        colourId:311,
        name:"SSG",
        productcode:"N2172",
        position:0
    },
    {
        Id:870,
        colourId:311,
        name:"SOL",
        productcode:"106188",
        position:0
    },
    {
        Id:871,
        colourId:312,
        name:"FRY",
        productcode:"N2176",
        position:0
    },
    {
        Id:872,
        colourId:312,
        name:"SSG",
        productcode:"N2176",
        position:0
    },
    {
        Id:873,
        colourId:312,
        name:"SOL",
        productcode:"106192",
        position:0
    },
    {
        Id:874,
        colourId:313,
        name:"FRY",
        productcode:"Brown 2319",
        position:0
    },
    {
        Id:875,
        colourId:313,
        name:"SSG",
        productcode:"N2041",
        position:0
    },
    {
        Id:876,
        colourId:313,
        name:"SOL",
        productcode:"106106",
        position:0
    },
    {
        Id:877,
        colourId:314,
        name:"FRY",
        productcode:"Blue 2318",
        position:0
    },
    {
        Id:878,
        colourId:314,
        name:"SSG",
        productcode:"N2045",
        position:0
    },
    {
        Id:879,
        colourId:314,
        name:"SOL",
        productcode:"106110",
        position:0
    },
    {
        Id:880,
        colourId:315,
        name:"FRY",
        productcode:"Black Mink 2150",
        position:0
    },
    {
        Id:881,
        colourId:315,
        name:"SSG",
        productcode:"N2053",
        position:0
    },
    {
        Id:882,
        colourId:315,
        name:"SOL",
        productcode:"106117",
        position:0
    },
    {
        Id:883,
        colourId:316,
        name:"SSG",
        productcode:"N2173",
        position:0
    },
    {
        Id:884,
        colourId:316,
        name:"SOL",
        productcode:"106189",
        position:0
    },
    {
        Id:885,
        colourId:317,
        name:"FRY",
        productcode:"N2177",
        position:0
    },
    {
        Id:886,
        colourId:317,
        name:"SSG",
        productcode:"N2177",
        position:0
    },
    {
        Id:887,
        colourId:317,
        name:"SOL",
        productcode:"No SOL code",
        position:0
    },
    {
        Id:888,
        colourId:318,
        name:"SSG",
        productcode:"N2178",
        position:0
    },
    {
        Id:889,
        colourId:318,
        name:"SOL",
        productcode:"106193",
        position:0
    },
    {
        Id:890,
        colourId:319,
        name:"SSG",
        productcode:"N2179",
        position:0
    },
    {
        Id:891,
        colourId:319,
        name:"SOL",
        productcode:"106194",
        position:0
    },
    {
        Id:892,
        colourId:320,
        name:"SSG",
        productcode:"N2180",
        position:0
    },
    {
        Id:893,
        colourId:320,
        name:"SOL",
        productcode:"106195",
        position:0
    },
    {
        Id:894,
        colourId:321,
        name:"SSG",
        productcode:"N2181",
        position:0
    },
    {
        Id:895,
        colourId:321,
        name:"SOL",
        productcode:"106196",
        position:0
    },
    {
        Id:896,
        colourId:322,
        name:"SSG",
        productcode:"N2187",
        position:0
    },
    {
        Id:897,
        colourId:322,
        name:"SOL",
        productcode:"106199",
        position:0
    },
    {
        Id:898,
        colourId:323,
        name:"FRY",
        productcode:"N1280024",
        position:0
    },
    {
        Id:899,
        colourId:324,
        name:"SSG",
        productcode:"N1723",
        position:0
    },
    {
        Id:900,
        colourId:324,
        name:"SOL",
        productcode:"106031",
        position:0
    },
    {
        Id:901,
        colourId:325,
        name:"SSG",
        productcode:"N2188",
        position:0
    },
    {
        Id:902,
        colourId:325,
        name:"SOL",
        productcode:"106200",
        position:0
    },
    {
        Id:903,
        colourId:326,
        name:"FRY",
        productcode:"N1294128",
        position:0
    },
    {
        Id:904,
        colourId:326,
        name:"SSG",
        productcode:"N1294128",
        position:0
    },
    {
        Id:905,
        colourId:327,
        name:"SSG",
        productcode:"N545000",
        position:0
    },
    {
        Id:906,
        colourId:328,
        name:"SSG",
        productcode:"N546000",
        position:0
    },
    {
        Id:907,
        colourId:329,
        name:"FRY",
        productcode:"1123375",
        position:0
    },
    {
        Id:908,
        colourId:329,
        name:"SSG",
        productcode:"N2285",
        position:0
    },
    {
        Id:909,
        colourId:329,
        name:"SOL",
        productcode:"106250",
        position:0
    },
    {
        Id:910,
        colourId:330,
        name:"SSG",
        productcode:"N2182",
        position:0
    },
    {
        Id:911,
        colourId:330,
        name:"SOL",
        productcode:"106197",
        position:0
    },
    {
        Id:912,
        colourId:331,
        name:"SSG",
        productcode:"N2183",
        position:0
    },
    {
        Id:913,
        colourId:331,
        name:"SOL",
        productcode:"106198",
        position:0
    },
    {
        Id:914,
        colourId:332,
        name:"SSG",
        productcode:"N2185",
        position:0
    },
    {
        Id:915,
        colourId:333,
        name:"SSG",
        productcode:"N2186",
        position:0
    },
    {
        Id:916,
        colourId:334,
        name:"SSG",
        productcode:"N2060",
        position:0
    },
    {
        Id:917,
        colourId:334,
        name:"SOL",
        productcode:"106125",
        position:0
    },
    {
        Id:918,
        colourId:335,
        name:"SSG",
        productcode:"N2061",
        position:0
    },
    {
        Id:919,
        colourId:335,
        name:"SOL",
        productcode:"106126",
        position:0
    },
    {
        Id:920,
        colourId:336,
        name:"SSG",
        productcode:"N2066",
        position:0
    },
    {
        Id:921,
        colourId:337,
        name:"SSG",
        productcode:"N2067",
        position:0
    },
    {
        Id:922,
        colourId:337,
        name:"SOL",
        productcode:"106133",
        position:0
    },
    {
        Id:923,
        colourId:338,
        name:"SSG",
        productcode:"N2068",
        position:0
    },
    {
        Id:924,
        colourId:339,
        name:"SSG",
        productcode:"N1460",
        position:0
    },
    {
        Id:925,
        colourId:339,
        name:"SOL",
        productcode:"105968",
        position:0
    },
    {
        Id:926,
        colourId:340,
        name:"FRY",
        productcode:"1123002",
        position:0
    },
    {
        Id:927,
        colourId:340,
        name:"SSG",
        productcode:"N2074",
        position:0
    },
    {
        Id:928,
        colourId:340,
        name:"SOL",
        productcode:"106136",
        position:0
    },
    {
        Id:929,
        colourId:341,
        name:"FRY",
        productcode:"1123109",
        position:0
    },
    {
        Id:930,
        colourId:341,
        name:"SSG",
        productcode:"N2276",
        position:0
    },
    {
        Id:931,
        colourId:341,
        name:"SOL",
        productcode:"106241",
        position:0
    },
    {
        Id:932,
        colourId:342,
        name:"FRY",
        productcode:"1123002",
        position:0
    },
    {
        Id:933,
        colourId:342,
        name:"SSG",
        productcode:"N2277",
        position:0
    },
    {
        Id:934,
        colourId:342,
        name:"SOL",
        productcode:"106242",
        position:0
    },
    {
        Id:935,
        colourId:343,
        name:"FRY",
        productcode:"1123051",
        position:0
    },
    {
        Id:936,
        colourId:343,
        name:"SSG",
        productcode:"N2279",
        position:0
    },
    {
        Id:937,
        colourId:343,
        name:"SOL",
        productcode:"106244",
        position:0
    },
    {
        Id:938,
        colourId:344,
        name:"FRY",
        productcode:"1123120",
        position:0
    },
    {
        Id:939,
        colourId:344,
        name:"SSG",
        productcode:"N2281",
        position:0
    },
    {
        Id:940,
        colourId:344,
        name:"SOL",
        productcode:"106246",
        position:0
    },
    {
        Id:941,
        colourId:345,
        name:"SSG",
        productcode:"N1462",
        position:0
    },
    {
        Id:942,
        colourId:345,
        name:"SOL",
        productcode:"105970",
        position:0
    },
    {
        Id:943,
        colourId:346,
        name:"SSG",
        productcode:"N2075",
        position:0
    },
    {
        Id:944,
        colourId:346,
        name:"SOL",
        productcode:"106137",
        position:0
    },
    {
        Id:945,
        colourId:347,
        name:"SSG",
        productcode:"N1461",
        position:0
    },
    {
        Id:946,
        colourId:347,
        name:"SOL",
        productcode:"105969",
        position:0
    },
    {
        Id:947,
        colourId:348,
        name:"SSG",
        productcode:"N2076",
        position:0
    },
    {
        Id:948,
        colourId:348,
        name:"SOL",
        productcode:"106138",
        position:0
    },
    {
        Id:949,
        colourId:349,
        name:"FRY",
        productcode:"1127376",
        position:0
    },
    {
        Id:950,
        colourId:349,
        name:"SSG",
        productcode:"N2275",
        position:0
    },
    {
        Id:951,
        colourId:349,
        name:"SOL",
        productcode:"106240",
        position:0
    },
    {
        Id:952,
        colourId:350,
        name:"FRY",
        productcode:"1123123",
        position:0
    },
    {
        Id:953,
        colourId:350,
        name:"SSG",
        productcode:"N2282",
        position:0
    },
    {
        Id:954,
        colourId:350,
        name:"SOL",
        productcode:"106247",
        position:0
    },
    {
        Id:955,
        colourId:351,
        name:"FRY",
        productcode:"1123155",
        position:0
    },
    {
        Id:956,
        colourId:351,
        name:"SSG",
        productcode:"N2284",
        position:0
    },
    {
        Id:957,
        colourId:351,
        name:"SOL",
        productcode:"106249",
        position:0
    },
    {
        Id:958,
        colourId:352,
        name:"FRY",
        productcode:"N1044000",
        position:0
    },
    {
        Id:959,
        colourId:352,
        name:"SSG",
        productcode:"N1044000",
        position:0
    },
    {
        Id:960,
        colourId:353,
        name:"SSG",
        productcode:"N2077",
        position:0
    },
    {
        Id:961,
        colourId:353,
        name:"SOL",
        productcode:"106139",
        position:0
    },
    {
        Id:962,
        colourId:354,
        name:"FRY",
        productcode:"644229",
        position:0
    },
    {
        Id:963,
        colourId:354,
        name:"SSG",
        productcode:"N2217",
        position:0
    },
    {
        Id:964,
        colourId:354,
        name:"SOL",
        productcode:"110439",
        position:0
    },
    {
        Id:965,
        colourId:355,
        name:"FRY",
        productcode:"668958",
        position:0
    },
    {
        Id:966,
        colourId:355,
        name:"SSG",
        productcode:"N2219",
        position:0
    },
    {
        Id:967,
        colourId:355,
        name:"SOL",
        productcode:"106208",
        position:0
    },
    {
        Id:968,
        colourId:356,
        name:"FRY",
        productcode:"650115",
        position:0
    },
    {
        Id:969,
        colourId:356,
        name:"SSG",
        productcode:"N2220",
        position:0
    },
    {
        Id:970,
        colourId:356,
        name:"SOL",
        productcode:"110440",
        position:0
    },
    {
        Id:971,
        colourId:357,
        name:"FRY",
        productcode:"649076",
        position:0
    },
    {
        Id:972,
        colourId:357,
        name:"SSG",
        productcode:"N2221",
        position:0
    },
    {
        Id:973,
        colourId:357,
        name:"SOL",
        productcode:"110438",
        position:0
    },
    {
        Id:974,
        colourId:358,
        name:"FRY",
        productcode:"645984",
        position:0
    },
    {
        Id:975,
        colourId:358,
        name:"SSG",
        productcode:"N2222",
        position:0
    },
    {
        Id:976,
        colourId:359,
        name:"FRY",
        productcode:"644240",
        position:0
    },
    {
        Id:977,
        colourId:359,
        name:"SSG",
        productcode:"N2223",
        position:0
    },
    {
        Id:978,
        colourId:359,
        name:"SOL",
        productcode:"106209",
        position:0
    },
    {
        Id:979,
        colourId:360,
        name:"FRY",
        productcode:"649069",
        position:0
    },
    {
        Id:980,
        colourId:360,
        name:"SSG",
        productcode:"N2224",
        position:0
    },
    {
        Id:981,
        colourId:361,
        name:"FRY",
        productcode:"669034",
        position:0
    },
    {
        Id:982,
        colourId:361,
        name:"SSG",
        productcode:"N2225",
        position:0
    },
    {
        Id:983,
        colourId:362,
        name:"FRY",
        productcode:"668761",
        position:0
    },
    {
        Id:984,
        colourId:362,
        name:"SSG",
        productcode:"N2226",
        position:0
    },
    {
        Id:985,
        colourId:362,
        name:"SOL",
        productcode:"106210",
        position:0
    },
    {
        Id:986,
        colourId:363,
        name:"FRY",
        productcode:"649518",
        position:0
    },
    {
        Id:987,
        colourId:363,
        name:"SSG",
        productcode:"N2227",
        position:0
    },
    {
        Id:988,
        colourId:363,
        name:"SOL",
        productcode:"106211",
        position:0
    }
]