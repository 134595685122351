/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
import * as Api from './api';

// lodash - https://lodash.com/
import _ from 'lodash';

/*******************************************************************************
 * Responses
 ******************************************************************************/

const _onSuccess = ( data ) => {
    if( _.get( data, 'data.data' ) )
        return _.get( data, 'data.data' );

    if( _.get( data, 'data' ) )
        return _.get( data, 'data' );

    if(_.get(data, ''))
        return _.get( data, '' );

    return data;
};

const _onFailed = ( error ) => new Promise(( resolve, reject ) => {
    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error;
    reject( _.get( error_obj, 'data', null ) || error_obj );
});

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

export const importData = () =>
    Api.importData()
        .then( _onSuccess )
        .catch( _onFailed );

export const getRootMenu = () =>
    Api.getRootMenu()
        .then( _onSuccess )
        .catch( _onFailed );

export const getRootMenuProductsWithAssets = () =>
    Api.getRootMenuProductsWithAssets()
        .then( _onSuccess )
        .catch( _onFailed );

export const getChildMenuByMenuId = menuId =>
    Api.getChildMenuByMenuId(menuId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductById = productId =>
    Api.getProductById(productId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductAttributesById = productId =>
    Api.getProductAttributesById(productId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductColoursById = productId =>
    Api.getProductColoursById(productId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductsByMenuId = menuId =>
    Api.getProductsByMenuId(menuId)
        .then( _onSuccess )
        .catch( _onFailed );

export const checkMenuForChildren = menuId =>
    Api.checkMenuForChildren(menuId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductAssetsById = productId =>
    Api.getProductAssetsById(productId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getMenuAssetsById = productId =>
    Api.getMenuAssetsById(productId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getChildSideBarMenuByMenuId = menuId =>
    Api.getChildMenuByMenuId(menuId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductFilters = (productIds:Array) =>
    Api.getProductFilters(productIds)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductFiltersByMenuId = (menuId) =>
    Api.getProductFiltersByMenuId(menuId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getProductsByMenuId_Filtered = (menuId, filterId) =>
    Api.getProductsByMenuId_Filtered(menuId, filterId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getMenuParentByMenuId = (menuId) =>
    Api.getMenuParentByMenuId(menuId)
        .then( _onSuccess )
        .catch( _onFailed );

export const getMenuRootNameByMenuId = (menuId, parentId) =>
    Api.getMenuRootNameByMenuId(menuId, parentId)
        .then( _onSuccess )
        .catch( _onFailed );
