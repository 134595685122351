import React from 'react';
import './ProductCopy.scoped.css'
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation, Thumbs} from "swiper";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ColorSwatch from "../ColorSwatch/ColorSwatch";
import {DataContext} from "../../context/DataContext";
import {useNavigate, useParams} from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

class ProductCopy extends React.Component {

    _isMounted = false;

    constructor( props ) {
        super( props );
        this.state = {
            thumbsSwiper: null
        };
    }

    _getItemId = () => {
        return this.props.params.id;
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getProductById(this._getItemId());
            await this.context.getProductAttributesById(this._getItemId());
            await this.context.getProductColoursById(this._getItemId());
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    render() {
        return (
            <DataContext.Consumer>{( dataContext ) => {
                const { productById, productAttributesById, productColoursById } = dataContext;

                if(this.state.isLoading){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading && productById && productAttributesById) {
                    let descriptionArray = productById.description.replace('<p>','').replace('</p>','').split('<br>');
                    return (
                        <>
                            <Breadcrumb/>
                            <h3 className={'product-heading-title font-weight-bold'}>{productById.name}</h3>
                            {
                                descriptionArray.length > 0 &&
                                <ul className={'product-listing'}>
                                    {
                                        descriptionArray.map((description) => {
                                            return(
                                                <li key={Math.random().toString(16).slice(-4)}><span dangerouslySetInnerHTML={{ __html: description}} /></li>
                                            )
                                        })
                                    }
                                </ul>
                            }
                            {/*<div dangerouslySetInnerHTML={{__html: productById.description}}></div>*/}
                            {/*<ul>*/}
                            {/*    <li>Front opening</li>*/}
                            {/*    <li>Concealed fasteners</li>*/}
                            {/*    <li>Mandarin</li>*/}
                            {/*    <li>Raglan sleeves</li>*/}
                            {/*    <li>Adjustable sleeve width</li>*/}
                            {/*    <li>Two external fastened flap pockets</li>*/}
                            {/*</ul>*/}
                            {
                                productAttributesById.length > 0 &&
                                <table className="table">
                                    <tbody>
                                        {
                                            productAttributesById.map((attribute) => {
                                                return(
                                                    <tr key={attribute.Id} className={'product-attributes'}>
                                                        <th scope="row">{attribute.name}</th>
                                                        <td>{attribute.data}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            }
                            <h5 className={'product-color-title'}>Colours Available:</h5>
                            <ColorSwatch />
                        </>
                    );
                };
            }}</DataContext.Consumer>
        );
    }
};

ProductCopy.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();
    return <ProductCopy {...props} navigation={navigation} params={params} />;
}
