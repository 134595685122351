import React from 'react';
import './BackButton.scoped.css'

// context
import { DataContext } from "../../context/DataContext";
import {Link, useNavigate, useParams} from "react-router-dom";
// components
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {Button} from "react-bootstrap";
import Icon from "../Icon/Icon";


class BackButton extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { categoryList } = dataContext;
                if(this.state.isLoading){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading){
                    return (
                        <>
                            <div className={`backButton-container mb-4 ${this.props.classname}`}>
                                <Button onClick={() => this.props.navigation(-1)} className={'button--green--square'}><Icon color="#FFFFFF" size={20} icon={'ico-arrow-left'} /></Button>
                            </div>
                        </>
                    )
                };
            }}</DataContext.Consumer>
        );
    };
};

BackButton.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();

    return <BackButton {...props} navigation={navigation} params={params} />;
}

