import React from 'react';

// context
import { DataContext } from "../context/DataContext";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import AppContent from "../components/AppContent/AppContent";

class WelcomeScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            let self = this;
            await this.context.getRootMenu();
            this.setState({
                loadFailed: false,
                isLoading: false
            })
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {

                if(this.state.isLoading){
                    return(
                        <>
                            <h1>Welcome</h1>
                            <LoadingSpinner />
                        </>

                    )
                }
                if(!this.state.isLoading){
                    return (
                        <>
                            <AppContent />
                        </>

                    )
                }
            }}</DataContext.Consumer>
        );
    };
};

WelcomeScreen.contextType = DataContext;

export default function( props ) {
    return <WelcomeScreen {...props} />;
}
