import React from 'react';
import './MenuWithChildren.scoped.css'

import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';


import db from '../../db/application-db';

//https://sag1v.github.io/react-elastic-carousel
//https://www.npmjs.com/package/@itseasy21/react-elastic-carousel fork v18
import Carousel from '@itseasy21/react-elastic-carousel'

import Product from '../Product/Product';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Icon from "../Icon/Icon";
import AppContent from "../AppContent/AppContent";
import {onDOMContentLoaded} from "bootstrap/js/src/util";
import {DataContext} from "../../context/DataContext";
import {Link, useNavigate, useParams} from "react-router-dom";

class MenuWithChildren extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
            isCollapsed: true,
            pageData : null
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            let self = this;
            await this.context.getChildSideBarMenuByMenuId(this.props.menu_id).then(function(data)  {
                self.setState({ loadFailed: false, isLoading: false, pageData : data });
            });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }


    render() {
        if(this.state.isLoading) {
            return (
                <LoadingSpinner />
            )
        }
        if(!this.state.isLoading && this.state.pageData) {
            if(this.props.root){
                return (
                    <SubMenu onClick={this.props.toggleSidebar} title={this.props.menu_name} icon={<><Icon color="#00B9B0" size={this.context.sideBarClosed ? 65: 45} icon={`ico-${this.props.menu_name.toString().toLowerCase()}`}/> {this.context.sideBarClosed ? <span>{this.props.menu_name}</span> : ''}</> }>
                        {
                            this.state.pageData.map((item) => {
                                if(item.children) {
                                     return(
                                        // <MenuWithChildren key={item.Id} onClick={this.toggleSidebar}  root={false} menu_id={item.Id} menu_name={item.title} />
                                         <MenuItem key={item.Id}>
                                             <Link to={'/category-group/'+ item.Id}>
                                                 {item.title}
                                             </Link>
                                         </MenuItem>
                                     )
                                } else {
                                    return(
                                        <MenuItem key={item.Id}>
                                            <Link to={'/products/'+ item.Id + '/' + item.parentId}>
                                                {item.title}
                                            </Link>
                                        </MenuItem>
                                    )
                                }
                            })
                        }
                    </SubMenu>
                )
            } else {
                return (
                    <SubMenu onClick={this._setOpen} title={this.props.menu_name}>
                        {
                            this.state.pageData.map((item) => {
                                if(item.children) {
                                    return(
                                        <MenuWithChildren key={item.Id} parentId={item.parentId} root={false} menu_id={item.Id} menu_name={item.title} />
                                    )
                                } else {
                                    return(
                                        <MenuItem key={item.Id}>
                                            <Link to={'/products/'+ item.Id + '/' + item.parentId}>
                                                {item.title}
                                            </Link>
                                        </MenuItem>
                                    )
                                }

                            })
                        }
                    </SubMenu>
                )
            }
        }
    }
}
MenuWithChildren.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();
    return <MenuWithChildren {...props} navigation={navigation} params={params} />;
}




