import React from 'react';

// context
import { DataContext } from "../context/DataContext";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import AppContent from "../components/AppContent/AppContent";
import {useNavigate, useParams} from "react-router-dom";
import GridLayout from "../components/GridLayout/GridLayout";
import BackButton from "../components/BackButton/BackButton";

class CategoryGroupItemScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
            pageTitle: '',
            menuId: null
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getChildMenuByMenuId(this._getMenuId());
            await this.context.getMenuParentByMenuId(this._getMenuId());
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    _getMenuId = () => {
        this.setState({
            menuId: this.props.params.id
        })
        return this.props.params.id;
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.state.menuId != nextProps.params.id) {
            this._init();
        }
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { childMenuByMenuId, menuParentByMenuId } = dataContext;

                if(this.state.isLoading){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading && childMenuByMenuId != null){
                    return (
                        <>
                            <BackButton />
                            <h1 className={'page-title text-center text-uppercase font-weight-bold mt-4'}>{menuParentByMenuId.title}</h1>
                                <blockquote className="text-center font-weight-normal mt-4 mb-4">
                                    <p className={'page-description'}>
                                        {menuParentByMenuId?.description}
                                    </p>
                                </blockquote>
                            <GridLayout parentId={this.state.menuId} showingProducts={false} gridSource={childMenuByMenuId}  />
                        </>
                    )
                };
            }}</DataContext.Consumer>
        );
    };
};

CategoryGroupItemScreen.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();
    return <CategoryGroupItemScreen {...props} navigation={navigation} params={params} />;
}
