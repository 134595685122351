import React from 'react';
import './SidebarNav.scoped.css'

import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';


import db from '../../db/application-db';

//https://sag1v.github.io/react-elastic-carousel
//https://www.npmjs.com/package/@itseasy21/react-elastic-carousel fork v18
import Carousel from '@itseasy21/react-elastic-carousel'

import Product from '../Product/Product';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Icon from "../Icon/Icon";
import AppContent from "../AppContent/AppContent";
import {onDOMContentLoaded} from "bootstrap/js/src/util";
import {DataContext} from "../../context/DataContext";
import {Link, useNavigate, useParams} from "react-router-dom";
import MenuWithChildren from "./MenuWithChildren";

class SidebarNav extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getRootMenuProductsWithAssets();
            this.setState({ loadFailed: false, isLoading: false, isCollapsed: this.props.navOpen });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    _toggleSidebar = async () => {
        await this.context.toggleSidebar();

        // if(this.context.sideBarClosed) {
        //     let collections = document.getElementsByClassName("pro-menu-item pro-sub-menu open")
        //
        //     for (let i = 0; i < collections.length; i++) {
        //         collections[i].classList.remove('open');
        //     }
        //
        //     let subCollections = document.getElementsByClassName("react-slidedown pro-inner-list-item");
        //     for (let i = 0; i < subCollections.length; i++) {
        //         if(!subCollections[i].classList.contains('closed')){
        //             await this._sleep(500);
        //             subCollections[i].classList.add('closed');
        //         }
        //     }
        // }

    }

    _setOpen = () => {
        if(this.context.sideBarClosed) {
            this._toggleSidebar();
        }
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { rootMenuProductsWithAssets } = dataContext;

                if(this.state.isLoading) {
                    return (
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading && rootMenuProductsWithAssets) {
                    return (
                        <ProSidebar className={this.context.sideBarClosed ? 'collapsed' : ''}>
                            <SidebarHeader>
                                <Link to={'/'}>
                                    <img src={'/assets/images/elis-logo.png'} width={45}/>
                                </Link>
                            </SidebarHeader>
                            <SidebarContent>
                                {
                                    rootMenuProductsWithAssets.map((menu, index) => {
                                        return(
                                            <Menu iconShape="square" key={index}>
                                                {
                                                    !menu.children &&
                                                    <MenuItem icon={<><Icon color="#00B9B0" size={this.context.sideBarClosed ? 65: 45} icon={`ico-${menu.title.toString().toLowerCase()}`}/> {this.context.sideBarClosed ? <span>{menu.title.toString()}</span> : ''}</>}>
                                                        <Link to={'/products/'+ menu.Id + '/' + menu.parentId}>
                                                            {menu.title}
                                                        </Link>
                                                    </MenuItem>
                                                }
                                                {
                                                    menu.children &&
                                                    <MenuWithChildren toggleSidebar={this._setOpen} root={true} parentId={0} menu_id={menu.Id} menu_name={menu.title} />
                                                }
                                            </Menu>
                                        );
                                    })
                                }

                            </SidebarContent>
                            <SidebarFooter>
                                <a onClick={this._toggleSidebar}><Icon color="#E3000B" size={20}
                                                                       icon={this.context.sideBarClosed ? 'ico-arrow-right' : 'ico-arrow-left'}/></a>
                            </SidebarFooter>
                        </ProSidebar>
                    )
                }
            }}</DataContext.Consumer>
        )
    }
}
SidebarNav.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();
    return <SidebarNav {...props} navigation={navigation} params={params} />;
}




