import React, { useContext, useState } from 'react';
import RegistrationContextProvider from './context/RegistrationContext';

// Local VARS
import { STYLES } from './Styles';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// Screens
import Layout from "./components/Layout/Layout";
import AuthLoadingScreen from './screens/AuthLoadingScreen';
import AuthLoginScreen from './screens/AuthLoginScreen';
import CategoryIndexScreen from "./screens/CategoryIndexScreen";

import GroupingIndexScreen from "./screens/GroupingIndexScreen";
import HomeScreen from "./screens/HomeScreen";
import CategoriesIndexScreen from "./screens/CategoriesIndexScreen";
import ProductGroupIndexScreen from "./screens/ProductGroupIndexScreen";
import ItemViewScreen from "./screens/ItemViewScreen";
import DeveloperScreen from "./screens/DeveloperScreen";
import WelcomeScreen from "./screens/WelcomeScreen";
import ProductInformationScreen from "./screens/ProductInformationScreen";
import CategoryItemScreen from "./screens/CategoryItemScreen";
import CategoryGroupItemScreen from "./screens/CategoryGroupItemScreen";
import ProductGroupItemScreen from "./screens/ProductGroupItemScreen";

export const Auth = () => {
    return (
        <Routes>
            <Route path="/" element={<AuthLoadingScreen />} />
        </Routes>
    )
}

export const Main = () => {
    return (
        <Routes>
            <Route exact path="/" element={<AuthLoadingScreen />} />
            <Route exact path="/home" element={<Layout><WelcomeScreen /></Layout>} />
            {/*<Route exact path="/home" element={<Layout><ProductInformationScreen /></Layout>} />*/}
            {/*<Route exact path="/categories" element={<Layout><CategoriesIndexScreen /></Layout>} />*/}
            {/*<Route exact path="/group/:id/:name" element={<Layout><GroupingIndexScreen /></Layout>} />*/}
            {/*<Route exact path="/category/:id" element={<Layout><CategoryItemScreen /></Layout>} />*/}
            {/*<Route exact path="/category/:id/:name" element={<Layout><CategoryItemScreen /></Layout>} />*/}
            <Route exact path="/category-group/:id" element={<Layout><CategoryGroupItemScreen /></Layout>} />
            {/*<Route exact path="/category-group/:id/:name" element={<Layout><CategoryGroupItemScreen /></Layout>} />*/}
            <Route exact path="/products/:id/:parent" element={<Layout><ProductGroupItemScreen /></Layout>} />
            <Route exact path="/products/:id/:parent/:name" element={<Layout><ProductGroupItemScreen /></Layout>} />
            <Route exact path="/product/:id" element={<Layout><ProductInformationScreen /></Layout>} />
            <Route exact path="/product/:id/:name" element={<Layout><ProductInformationScreen /></Layout>} />
            {
                (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                <Route exact path="/dev_djr922" element={<Layout><DeveloperScreen /></Layout>} />
            }
        </Routes>
    )
}
