/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
import * as Api from './api';
// lodash - https://lodash.com/
import _ from 'lodash';
// axios - https://github.com/axios/axios
import axios from 'axios';

// Helpers
import { getSession, getEndpoint } from '../../Helpers';

/*******************************************************************************
 * Responses
 ******************************************************************************/

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * login
 * @param {obj} payload of post data
 * @return {promise} containing the response data
 */
export const login = async ( username, password) => {

    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
    };

    const login_promise = new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint( '/user/login', null, '1' ),
            headers: headers,
            data: {
                username: username,
                password: password
            }
        })
            .then(( response ) => {
                resolve( response );
            })
            .catch(( error ) => {
                reject( error );
            });
    });

    return login_promise;
}
