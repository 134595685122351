// IndexedDB - https://dexie.org/
import Dexie from 'dexie'
import { useLiveQuery } from "dexie-react-hooks";

// Init the browser db
const db = new Dexie('Elis-Application');

// Version increment for Schema changes
db.version(5).stores({
    assetManagement: "&Id,assetId,menuId,productId,position",
    assets: "&Id,name,path,alt",
    category: "&Id,name,description",
    colourAttributes: "&Id,colourId,name,productcode,position",
    // colourManagement: "&Id,colourId,productId",
    colours: "&Id,name,hex,productId,position",
    filterPolymorphic: "&Id,productId,filterId,position",
    menu: "&Id,title,description,parentId,children,[Id+parentId]",
    product: "&Id,name,description,rankingId",
    productAttributes: "&Id,productId,name,data,position",
    productFilters: "&Id,filterName",
    productMenuManagement: "&Id,menuId,productId,position",
    ranking: "&Id,name",
    // locker: "++id,&productId,&customerId",
    // customer:"++id,name,email"
});

db.open().catch(function (e) {
    console.error("Open failed: " + e);
});

export default db;
