export const assetManagementSeed = [
    {
        Id:1,
        assetId:2,
        menuId:null,
        productId:1,
        position:0
    },
    {
        Id:2,
        assetId:114,
        menuId:null,
        productId:2,
        position:0
    },
    {
        Id:3,
        assetId:3,
        menuId:null,
        productId:7,
        position:0
    },
    {
        Id:4,
        assetId:4,
        menuId:null,
        productId:87,
        position:0
    },
    {
        Id:5,
        assetId:5,
        menuId:null,
        productId:88,
        position:0
    },
    {
        Id:6,
        assetId:6,
        menuId:null,
        productId:89,
        position:0
    },
    {
        Id:7,
        assetId:1,
        menuId:null,
        productId:249,
        position:0
    },
    {
        Id:8,
        assetId:1,
        menuId:null,
        productId:242,
        position:0
    },
    {
        Id:9,
        assetId:1,
        menuId:null,
        productId:241,
        position:0
    },
    {
        Id:10,
        assetId:1,
        menuId:null,
        productId:240,
        position:0
    },
    {
        Id:11,
        assetId:7,
        menuId:null,
        productId:90,
        position:0
    },
    {
        Id:12,
        assetId:1,
        menuId:null,
        productId:239,
        position:0
    },
    {
        Id:13,
        assetId:1,
        menuId:null,
        productId:238,
        position:0
    },
    {
        Id:14,
        assetId:8,
        menuId:null,
        productId:91,
        position:0
    },
    {
        Id:15,
        assetId:1,
        menuId:null,
        productId:237,
        position:0
    },
    {
        Id:16,
        assetId:1,
        menuId:null,
        productId:236,
        position:0
    },
    {
        Id:17,
        assetId:9,
        menuId:null,
        productId:65,
        position:0
    },
    {
        Id:18,
        assetId:1,
        menuId:null,
        productId:235,
        position:0
    },
    {
        Id:19,
        assetId:10,
        menuId:null,
        productId:63,
        position:0
    },
    {
        Id:20,
        assetId:1,
        menuId:null,
        productId:234,
        position:0
    },
    {
        Id:21,
        assetId:1,
        menuId:null,
        productId:233,
        position:0
    },
    {
        Id:22,
        assetId:1,
        menuId:null,
        productId:232,
        position:0
    },
    {
        Id:23,
        assetId:1,
        menuId:null,
        productId:231,
        position:0
    },
    {
        Id:24,
        assetId:1,
        menuId:null,
        productId:230,
        position:0
    },
    {
        Id:25,
        assetId:1,
        menuId:null,
        productId:229,
        position:0
    },
    {
        Id:26,
        assetId:1,
        menuId:null,
        productId:228,
        position:0
    },
    {
        Id:27,
        assetId:11,
        menuId:null,
        productId:63,
        position:0
    },
    {
        Id:28,
        assetId:1,
        menuId:null,
        productId:222,
        position:0
    },
    {
        Id:29,
        assetId:1,
        menuId:null,
        productId:221,
        position:0
    },
    {
        Id:30,
        assetId:1,
        menuId:null,
        productId:218,
        position:0
    },
    {
        Id:31,
        assetId:1,
        menuId:null,
        productId:64,
        position:0
    },
    {
        Id:32,
        assetId:1,
        menuId:null,
        productId:213,
        position:0
    },
    {
        Id:33,
        assetId:1,
        menuId:null,
        productId:212,
        position:0
    },
    {
        Id:34,
        assetId:12,
        menuId:null,
        productId:54,
        position:0
    },
    {
        Id:35,
        assetId:13,
        menuId:null,
        productId:54,
        position:0
    },
    {
        Id:36,
        assetId:1,
        menuId:null,
        productId:211,
        position:0
    },
    {
        Id:37,
        assetId:1,
        menuId:null,
        productId:205,
        position:0
    },
    {
        Id:38,
        assetId:1,
        menuId:null,
        productId:203,
        position:0
    },
    {
        Id:39,
        assetId:1,
        menuId:null,
        productId:202,
        position:0
    },
    {
        Id:40,
        assetId:14,
        menuId:null,
        productId:55,
        position:0
    },
    {
        Id:41,
        assetId:15,
        menuId:null,
        productId:55,
        position:0
    },
    {
        Id:42,
        assetId:1,
        menuId:null,
        productId:200,
        position:0
    },
    {
        Id:43,
        assetId:16,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:44,
        assetId:17,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:45,
        assetId:22,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:46,
        assetId:23,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:47,
        assetId:1,
        menuId:null,
        productId:198,
        position:0
    },
    {
        Id:48,
        assetId:1,
        menuId:null,
        productId:197,
        position:0
    },
    {
        Id:49,
        assetId:1,
        menuId:null,
        productId:195,
        position:0
    },
    {
        Id:50,
        assetId:1,
        menuId:null,
        productId:193,
        position:0
    },
    {
        Id:51,
        assetId:18,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:53,
        assetId:24,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:54,
        assetId:25,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:55,
        assetId:1,
        menuId:null,
        productId:192,
        position:0
    },
    {
        Id:56,
        assetId:19,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:57,
        assetId:20,
        menuId:null,
        productId:71,
        position:0
    },
    {
        Id:58,
        assetId:1,
        menuId:null,
        productId:190,
        position:0
    },
    {
        Id:62,
        assetId:30,
        menuId:null,
        productId:56,
        position:0
    },
    {
        Id:63,
        assetId:1,
        menuId:null,
        productId:188,
        position:0
    },
    {
        Id:64,
        assetId:1,
        menuId:null,
        productId:187,
        position:0
    },
    {
        Id:65,
        assetId:26,
        menuId:null,
        productId:56,
        position:0
    },
    {
        Id:66,
        assetId:1,
        menuId:null,
        productId:19,
        position:0
    },
    {
        Id:67,
        assetId:27,
        menuId:null,
        productId:57,
        position:0
    },
    {
        Id:68,
        assetId:194,
        menuId:null,
        productId:20,
        position:0
    },
    {
        Id:69,
        assetId:195,
        menuId:null,
        productId:20,
        position:0
    },
    {
        Id:70,
        assetId:193,
        menuId:null,
        productId:186,
        position:0
    },
    {
        Id:71,
        assetId:78,
        menuId:null,
        productId:185,
        position:0
    },
    {
        Id:72,
        assetId:79,
        menuId:null,
        productId:183,
        position:0
    },
    {
        Id:73,
        assetId:78,
        menuId:null,
        productId:182,
        position:0
    },
    {
        Id:74,
        assetId:1,
        menuId:null,
        productId:184,
        position:0
    },
    {
        Id:75,
        assetId:1,
        menuId:null,
        productId:181,
        position:0
    },
    {
        Id:76,
        assetId:1,
        menuId:null,
        productId:180,
        position:0
    },
    {
        Id:77,
        assetId:1,
        menuId:null,
        productId:179,
        position:0
    },
    {
        Id:78,
        assetId:1,
        menuId:null,
        productId:178,
        position:0
    },
    {
        Id:79,
        assetId:190,
        menuId:null,
        productId:84,
        position:0
    },
    {
        Id:80,
        assetId:186,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:81,
        assetId:191,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:82,
        assetId:187,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:83,
        assetId:188,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:84,
        assetId:189,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:85,
        assetId:192,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:86,
        assetId:28,
        menuId:null,
        productId:78,
        position:0
    },
    {
        Id:87,
        assetId:29,
        menuId:null,
        productId:78,
        position:0
    },
    {
        Id:88,
        assetId:185,
        menuId:null,
        productId:86,
        position:0
    },
    {
        Id:90,
        assetId:28,
        menuId:null,
        productId:79,
        position:0
    },
    {
        Id:91,
        assetId:29,
        menuId:null,
        productId:79,
        position:0
    },
    {
        Id:92,
        assetId:21,
        menuId:null,
        productId:52,
        position:0
    },
    {
        Id:93,
        assetId:12,
        menuId:null,
        productId:51,
        position:0
    },
    {
        Id:94,
        assetId:12,
        menuId:null,
        productId:50,
        position:0
    },
    {
        Id:95,
        assetId:31,
        menuId:null,
        productId:50,
        position:0
    },
    {
        Id:96,
        assetId:31,
        menuId:null,
        productId:51,
        position:0
    },
    {
        Id:97,
        assetId:32,
        menuId:null,
        productId:53,
        position:0
    },
    {
        Id:98,
        assetId:21,
        menuId:null,
        productId:53,
        position:0
    },
    {
        Id:99,
        assetId:33,
        menuId:null,
        productId:52,
        position:0
    },
    {
        Id:100,
        assetId:34,
        menuId:null,
        productId:76,
        position:0
    },
    {
        Id:101,
        assetId:36,
        menuId:null,
        productId:76,
        position:0
    },
    {
        Id:102,
        assetId:35,
        menuId:null,
        productId:76,
        position:0
    },
    {
        Id:103,
        assetId:37,
        menuId:null,
        productId:62,
        position:0
    },
    {
        Id:104,
        assetId:38,
        menuId:null,
        productId:62,
        position:0
    },
    {
        Id:105,
        assetId:39,
        menuId:null,
        productId:62,
        position:0
    },
    {
        Id:106,
        assetId:22,
        menuId:null,
        productId:62,
        position:0
    },
    {
        Id:107,
        assetId:40,
        menuId:null,
        productId:77,
        position:0
    },
    {
        Id:108,
        assetId:42,
        menuId:null,
        productId:81,
        position:0
    },
    {
        Id:109,
        assetId:43,
        menuId:null,
        productId:81,
        position:0
    },
    {
        Id:110,
        assetId:44,
        menuId:null,
        productId:82,
        position:0
    },
    {
        Id:111,
        assetId:45,
        menuId:null,
        productId:82,
        position:0
    },
    {
        Id:112,
        assetId:46,
        menuId:null,
        productId:74,
        position:0
    },
    {
        Id:113,
        assetId:47,
        menuId:null,
        productId:74,
        position:0
    },
    {
        Id:114,
        assetId:48,
        menuId:null,
        productId:75,
        position:0
    },
    {
        Id:115,
        assetId:49,
        menuId:null,
        productId:75,
        position:0
    },
    {
        Id:116,
        assetId:1,
        menuId:null,
        productId:58,
        position:0
    },
    {
        Id:118,
        assetId:50,
        menuId:null,
        productId:59,
        position:0
    },
    {
        Id:119,
        assetId:44,
        menuId:null,
        productId:60,
        position:0
    },
    {
        Id:120,
        assetId:51,
        menuId:null,
        productId:60,
        position:0
    },
    {
        Id:121,
        assetId:52,
        menuId:null,
        productId:80,
        position:0
    },
    {
        Id:122,
        assetId:53,
        menuId:null,
        productId:80,
        position:0
    },
    {
        Id:123,
        assetId:55,
        menuId:null,
        productId:80,
        position:0
    },
    {
        Id:124,
        assetId:54,
        menuId:null,
        productId:83,
        position:0
    },
    {
        Id:125,
        assetId:56,
        menuId:null,
        productId:83,
        position:0
    },
    {
        Id:126,
        assetId:1,
        menuId:null,
        productId:177,
        position:0
    },
    {
        Id:127,
        assetId:1,
        menuId:null,
        productId:48,
        position:0
    },
    {
        Id:128,
        assetId:182,
        menuId:null,
        productId:175,
        position:0
    },
    {
        Id:129,
        assetId:181,
        menuId:null,
        productId:174,
        position:0
    },
    {
        Id:130,
        assetId:177,
        menuId:null,
        productId:173,
        position:0
    },
    {
        Id:131,
        assetId:178,
        menuId:null,
        productId:173,
        position:0
    },
    {
        Id:132,
        assetId:179,
        menuId:null,
        productId:173,
        position:0
    },
    {
        Id:133,
        assetId:179,
        menuId:null,
        productId:173,
        position:0
    },
    {
        Id:134,
        assetId:196,
        menuId:null,
        productId:172,
        position:0
    },
    {
        Id:135,
        assetId:176,
        menuId:null,
        productId:172,
        position:0
    },
    {
        Id:137,
        assetId:175,
        menuId:null,
        productId:171,
        position:0
    },
    {
        Id:139,
        assetId:174,
        menuId:null,
        productId:170,
        position:0
    },
    {
        Id:140,
        assetId:1,
        menuId:null,
        productId:47,
        position:0
    },
    {
        Id:141,
        assetId:1,
        menuId:null,
        productId:92,
        position:0
    },
    {
        Id:142,
        assetId:57,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:143,
        assetId:58,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:144,
        assetId:59,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:145,
        assetId:60,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:146,
        assetId:61,
        menuId:null,
        productId:73,
        position:0
    },
    {
        Id:147,
        assetId:62,
        menuId:null,
        productId:46,
        position:0
    },
    {
        Id:148,
        assetId:63,
        menuId:null,
        productId:46,
        position:0
    },
    {
        Id:149,
        assetId:64,
        menuId:null,
        productId:40,
        position:0
    },
    {
        Id:150,
        assetId:65,
        menuId:null,
        productId:40,
        position:0
    },
    {
        Id:151,
        assetId:1,
        menuId:null,
        productId:39,
        position:0
    },
    {
        Id:152,
        assetId:1,
        menuId:null,
        productId:43,
        position:0
    },
    {
        Id:153,
        assetId:1,
        menuId:null,
        productId:42,
        position:0
    },
    {
        Id:154,
        assetId:1,
        menuId:null,
        productId:41,
        position:0
    },
    {
        Id:155,
        assetId:1,
        menuId:null,
        productId:97,
        position:0
    },
    {
        Id:156,
        assetId:1,
        menuId:null,
        productId:98,
        position:0
    },
    {
        Id:157,
        assetId:1,
        menuId:null,
        productId:99,
        position:0
    },
    {
        Id:158,
        assetId:1,
        menuId:null,
        productId:32,
        position:0
    },
    {
        Id:159,
        assetId:66,
        menuId:null,
        productId:30,
        position:0
    },
    {
        Id:160,
        assetId:1,
        menuId:null,
        productId:31,
        position:0
    },
    {
        Id:161,
        assetId:1,
        menuId:null,
        productId:37,
        position:0
    },
    {
        Id:162,
        assetId:1,
        menuId:null,
        productId:33,
        position:0
    },
    {
        Id:163,
        assetId:67,
        menuId:null,
        productId:100,
        position:0
    },
    {
        Id:164,
        assetId:68,
        menuId:null,
        productId:100,
        position:0
    },
    {
        Id:165,
        assetId:69,
        menuId:null,
        productId:100,
        position:0
    },
    {
        Id:166,
        assetId:70,
        menuId:null,
        productId:100,
        position:0
    },
    {
        Id:167,
        assetId:71,
        menuId:null,
        productId:34,
        position:0
    },
    {
        Id:168,
        assetId:1,
        menuId:null,
        productId:70,
        position:0
    },
    {
        Id:169,
        assetId:74,
        menuId:null,
        productId:68,
        position:0
    },
    {
        Id:170,
        assetId:74,
        menuId:null,
        productId:67,
        position:0
    },
    {
        Id:171,
        assetId:75,
        menuId:null,
        productId:69,
        position:0
    },
    {
        Id:172,
        assetId:76,
        menuId:null,
        productId:66,
        position:0
    },
    {
        Id:173,
        assetId:77,
        menuId:null,
        productId:103,
        position:0
    },
    {
        Id:174,
        assetId:78,
        menuId:null,
        productId:104,
        position:0
    },
    {
        Id:175,
        assetId:77,
        menuId:null,
        productId:104,
        position:0
    },
    {
        Id:176,
        assetId:79,
        menuId:null,
        productId:102,
        position:0
    },
    {
        Id:177,
        assetId:77,
        menuId:null,
        productId:102,
        position:0
    },
    {
        Id:178,
        assetId:183,
        menuId:null,
        productId:169,
        position:0
    },
    {
        Id:179,
        assetId:184,
        menuId:null,
        productId:169,
        position:0
    },
    {
        Id:180,
        assetId:168,
        menuId:null,
        productId:168,
        position:0
    },
    {
        Id:181,
        assetId:169,
        menuId:null,
        productId:168,
        position:0
    },
    {
        Id:182,
        assetId:80,
        menuId:null,
        productId:105,
        position:0
    },
    {
        Id:183,
        assetId:170,
        menuId:null,
        productId:168,
        position:0
    },
    {
        Id:184,
        assetId:81,
        menuId:null,
        productId:106,
        position:0
    },
    {
        Id:185,
        assetId:82,
        menuId:null,
        productId:107,
        position:0
    },
    {
        Id:186,
        assetId:171,
        menuId:null,
        productId:168,
        position:0
    },
    {
        Id:187,
        assetId:1,
        menuId:null,
        productId:38,
        position:0
    },
    {
        Id:188,
        assetId:83,
        menuId:null,
        productId:108,
        position:0
    },
    {
        Id:189,
        assetId:84,
        menuId:null,
        productId:108,
        position:0
    },
    {
        Id:190,
        assetId:85,
        menuId:null,
        productId:108,
        position:0
    },
    {
        Id:191,
        assetId:86,
        menuId:null,
        productId:109,
        position:0
    },
    {
        Id:192,
        assetId:87,
        menuId:null,
        productId:109,
        position:0
    },
    {
        Id:193,
        assetId:88,
        menuId:null,
        productId:109,
        position:0
    },
    {
        Id:194,
        assetId:89,
        menuId:null,
        productId:110,
        position:0
    },
    {
        Id:195,
        assetId:90,
        menuId:null,
        productId:110,
        position:0
    },
    {
        Id:196,
        assetId:98,
        menuId:null,
        productId:110,
        position:0
    },
    {
        Id:197,
        assetId:92,
        menuId:null,
        productId:111,
        position:0
    },
    {
        Id:198,
        assetId:93,
        menuId:null,
        productId:112,
        position:0
    },
    {
        Id:199,
        assetId:94,
        menuId:null,
        productId:113,
        position:0
    },
    {
        Id:200,
        assetId:95,
        menuId:null,
        productId:114,
        position:0
    },
    {
        Id:201,
        assetId:96,
        menuId:null,
        productId:115,
        position:0
    },
    {
        Id:202,
        assetId:97,
        menuId:null,
        productId:116,
        position:0
    },
    {
        Id:203,
        assetId:98,
        menuId:null,
        productId:117,
        position:0
    },
    {
        Id:204,
        assetId:1,
        menuId:null,
        productId:118,
        position:0
    },
    {
        Id:205,
        assetId:99,
        menuId:null,
        productId:29,
        position:0
    },
    {
        Id:206,
        assetId:1,
        menuId:null,
        productId:21,
        position:0
    },
    {
        Id:207,
        assetId:1,
        menuId:null,
        productId:23,
        position:0
    },
    {
        Id:208,
        assetId:1,
        menuId:null,
        productId:25,
        position:0
    },
    {
        Id:209,
        assetId:1,
        menuId:null,
        productId:22,
        position:0
    },
    {
        Id:210,
        assetId:1,
        menuId:null,
        productId:24,
        position:0
    },
    {
        Id:211,
        assetId:1,
        menuId:null,
        productId:26,
        position:0
    },
    {
        Id:212,
        assetId:100,
        menuId:null,
        productId:121,
        position:0
    },
    {
        Id:213,
        assetId:101,
        menuId:null,
        productId:121,
        position:0
    },
    {
        Id:214,
        assetId:102,
        menuId:null,
        productId:122,
        position:0
    },
    {
        Id:215,
        assetId:103,
        menuId:null,
        productId:122,
        position:0
    },
    {
        Id:216,
        assetId:106,
        menuId:null,
        productId:124,
        position:0
    },
    {
        Id:217,
        assetId:107,
        menuId:null,
        productId:125,
        position:0
    },
    {
        Id:218,
        assetId:108,
        menuId:null,
        productId:126,
        position:0
    },
    {
        Id:219,
        assetId:107,
        menuId:null,
        productId:127,
        position:0
    },
    {
        Id:220,
        assetId:109,
        menuId:null,
        productId:128,
        position:0
    },
    {
        Id:221,
        assetId:110,
        menuId:null,
        productId:128,
        position:0
    },
    {
        Id:222,
        assetId:111,
        menuId:null,
        productId:9,
        position:0
    },
    {
        Id:223,
        assetId:3,
        menuId:null,
        productId:14,
        position:0
    },
    {
        Id:224,
        assetId:112,
        menuId:null,
        productId:6,
        position:0
    },
    {
        Id:225,
        assetId:1,
        menuId:null,
        productId:5,
        position:0
    },
    {
        Id:226,
        assetId:1,
        menuId:null,
        productId:129,
        position:0
    },
    {
        Id:227,
        assetId:1,
        menuId:null,
        productId:130,
        position:0
    },
    {
        Id:228,
        assetId:113,
        menuId:null,
        productId:131,
        position:0
    },
    {
        Id:229,
        assetId:115,
        menuId:null,
        productId:3,
        position:0
    },
    {
        Id:230,
        assetId:1,
        menuId:null,
        productId:4,
        position:0
    },
    {
        Id:231,
        assetId:116,
        menuId:null,
        productId:132,
        position:0
    },
    {
        Id:232,
        assetId:117,
        menuId:null,
        productId:132,
        position:0
    },
    {
        Id:233,
        assetId:118,
        menuId:null,
        productId:133,
        position:0
    },
    {
        Id:234,
        assetId:119,
        menuId:null,
        productId:133,
        position:0
    },
    {
        Id:235,
        assetId:120,
        menuId:null,
        productId:15,
        position:0
    },
    {
        Id:236,
        assetId:121,
        menuId:null,
        productId:18,
        position:0
    },
    {
        Id:237,
        assetId:122,
        menuId:null,
        productId:17,
        position:0
    },
    {
        Id:238,
        assetId:124,
        menuId:null,
        productId:17,
        position:0
    },
    {
        Id:239,
        assetId:123,
        menuId:null,
        productId:13,
        position:0
    },
    {
        Id:240,
        assetId:125,
        menuId:null,
        productId:12,
        position:0
    },
    {
        Id:241,
        assetId:1,
        menuId:null,
        productId:11,
        position:0
    },
    {
        Id:242,
        assetId:1,
        menuId:null,
        productId:16,
        position:0
    },
    {
        Id:243,
        assetId:1,
        menuId:null,
        productId:10,
        position:0
    },
    {
        Id:244,
        assetId:126,
        menuId:null,
        productId:134,
        position:0
    },
    {
        Id:245,
        assetId:127,
        menuId:null,
        productId:134,
        position:0
    },
    {
        Id:246,
        assetId:128,
        menuId:null,
        productId:134,
        position:0
    },
    {
        Id:247,
        assetId:1,
        menuId:null,
        productId:135,
        position:0
    },
    {
        Id:248,
        assetId:1,
        menuId:null,
        productId:136,
        position:0
    },
    {
        Id:249,
        assetId:1,
        menuId:null,
        productId:137,
        position:0
    },
    {
        Id:250,
        assetId:129,
        menuId:null,
        productId:138,
        position:0
    },
    {
        Id:251,
        assetId:1,
        menuId:null,
        productId:139,
        position:0
    },
    {
        Id:252,
        assetId:130,
        menuId:null,
        productId:140,
        position:0
    },
    {
        Id:253,
        assetId:1,
        menuId:null,
        productId:141,
        position:0
    },
    {
        Id:254,
        assetId:131,
        menuId:null,
        productId:142,
        position:0
    },
    {
        Id:255,
        assetId:1,
        menuId:null,
        productId:143,
        position:0
    },
    {
        Id:256,
        assetId:132,
        menuId:null,
        productId:144,
        position:0
    },
    {
        Id:257,
        assetId:1,
        menuId:null,
        productId:145,
        position:0
    },
    {
        Id:258,
        assetId:133,
        menuId:null,
        productId:146,
        position:0
    },
    {
        Id:259,
        assetId:134,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:260,
        assetId:140,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:261,
        assetId:142,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:262,
        assetId:143,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:263,
        assetId:135,
        menuId:null,
        productId:148,
        position:0
    },
    {
        Id:264,
        assetId:141,
        menuId:null,
        productId:148,
        position:0
    },
    {
        Id:265,
        assetId:136,
        menuId:null,
        productId:149,
        position:0
    },
    {
        Id:266,
        assetId:137,
        menuId:null,
        productId:150,
        position:0
    },
    {
        Id:267,
        assetId:138,
        menuId:null,
        productId:151,
        position:0
    },
    {
        Id:268,
        assetId:147,
        menuId:null,
        productId:151,
        position:0
    },
    {
        Id:269,
        assetId:139,
        menuId:null,
        productId:152,
        position:0
    },
    {
        Id:270,
        assetId:148,
        menuId:null,
        productId:152,
        position:0
    },
    {
        Id:271,
        assetId:144,
        menuId:null,
        productId:153,
        position:0
    },
    {
        Id:272,
        assetId:149,
        menuId:null,
        productId:153,
        position:0
    },
    {
        Id:273,
        assetId:145,
        menuId:null,
        productId:154,
        position:0
    },
    {
        Id:274,
        assetId:146,
        menuId:null,
        productId:155,
        position:0
    },
    {
        Id:275,
        assetId:150,
        menuId:null,
        productId:155,
        position:0
    },
    {
        Id:276,
        assetId:1,
        menuId:null,
        productId:8,
        position:0
    },
    {
        Id:277,
        assetId:151,
        menuId:null,
        productId:35,
        position:0
    },
    {
        Id:278,
        assetId:152,
        menuId:null,
        productId:35,
        position:0
    },
    {
        Id:279,
        assetId:153,
        menuId:null,
        productId:35,
        position:0
    },
    {
        Id:280,
        assetId:154,
        menuId:null,
        productId:156,
        position:0
    },
    {
        Id:281,
        assetId:155,
        menuId:null,
        productId:156,
        position:0
    },
    {
        Id:282,
        assetId:156,
        menuId:null,
        productId:156,
        position:0
    },
    {
        Id:283,
        assetId:1,
        menuId:null,
        productId:28,
        position:0
    },
    {
        Id:284,
        assetId:157,
        menuId:null,
        productId:157,
        position:0
    },
    {
        Id:285,
        assetId:158,
        menuId:null,
        productId:158,
        position:0
    },
    {
        Id:286,
        assetId:1,
        menuId:null,
        productId:49,
        position:0
    },
    {
        Id:287,
        assetId:1,
        menuId:null,
        productId:159,
        position:0
    },
    {
        Id:288,
        assetId:1,
        menuId:null,
        productId:160,
        position:0
    },
    {
        Id:289,
        assetId:1,
        menuId:null,
        productId:161,
        position:0
    },
    {
        Id:290,
        assetId:1,
        menuId:null,
        productId:162,
        position:0
    },
    {
        Id:291,
        assetId:159,
        menuId:null,
        productId:85,
        position:0
    },
    {
        Id:292,
        assetId:160,
        menuId:null,
        productId:85,
        position:0
    },
    {
        Id:293,
        assetId:161,
        menuId:null,
        productId:85,
        position:0
    },
    {
        Id:294,
        assetId:1,
        menuId:null,
        productId:163,
        position:0
    },
    {
        Id:295,
        assetId:1,
        menuId:null,
        productId:164,
        position:0
    },
    {
        Id:296,
        assetId:1,
        menuId:null,
        productId:165,
        position:0
    },
    {
        Id:297,
        assetId:162,
        menuId:null,
        productId:166,
        position:0
    },
    {
        Id:298,
        assetId:163,
        menuId:null,
        productId:166,
        position:0
    },
    {
        Id:299,
        assetId:164,
        menuId:null,
        productId:167,
        position:0
    },
    {
        Id:300,
        assetId:165,
        menuId:null,
        productId:167,
        position:0
    },
    {
        Id:301,
        assetId:166,
        menuId:null,
        productId:167,
        position:0
    },
    {
        Id:302,
        assetId:167,
        menuId:null,
        productId:167,
        position:0
    },
    {
        Id:303,
        assetId:70,
        menuId:null,
        productId:101,
        position:0
    },
    {
        Id:304,
        assetId:183,
        menuId:null,
        productId:176,
        position:0
    },
    {
        Id:305,
        assetId:104,
        menuId:null,
        productId:123,
        position:0
    },
    {
        Id:307,
        assetId:105,
        menuId:null,
        productId:123,
        position:0
    },
    {
        Id:308,
        assetId:63,
        menuId:null,
        productId:93,
        position:0
    }
]