import React from 'react';

import { DataContext } from "../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import BackButton from "../components/BackButton/BackButton";
import ProductCarousel from "../components/Product/ProductCarousel";
import ProductCopy from "../components/Product/ProductCopy";

class ProductInformationScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false
        };
    }

    _getItemId = () => {
        return this.props.params.id;
    }

    _getItemName = () => {
        return this.props.params.name;
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getProductById(this._getItemId());
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { productById } = dataContext;

                if(this.state.isLoading && productById === null) {
                    return(
                        <>
                            <LoadingSpinner />
                        </>
                    )
                }

                if(!this.state.isLoading && productById !== null) {
                    return(
                        <>
                            <BackButton classname={'product-page'} />
                            <div className="container">
                                <div className="row vh-85">
                                    <div className="col-12 col-md-6">
                                        <ProductCarousel />
                                    </div>
                                    <div className="col-12 col-md-6 scrollable-div">
                                        <ProductCopy product={productById} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }

            }}</DataContext.Consumer>
        );
    };
};

ProductInformationScreen.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();

    return <ProductInformationScreen {...props} navigation={navigation} params={params} />;
}
