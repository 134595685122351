import React from 'react';
import { DataContext } from "../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import {Button, Col, Row, Container} from "react-bootstrap";

// service
import * as DataService from '../services/data'

// config
import CONFIG  from '../Config'

// db
import db from '../db/application-db';
import { categorySeed } from  '../db/seeds/Categories'
import { productSeed } from  '../db/seeds/Products'
import { groupSeed } from  '../db/seeds/Groups'

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'

class DeveloperScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            await this._getTotalRecordCount();
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _destroyIndexDB = () => {
        db.delete();
    }

    _initIndexDB = () => {
        db.open().catch(function (e) {
            console.error("Open failed: " + e);
        });
    }

    _seedIndexDB = async target => {
        await DataService?.['seed' + target.charAt(0).toUpperCase() + target.slice(1)]();
    }

    _getTotalRecordCount = async () => {
        db.tables.forEach(function (table) {
            db?.[table.name]?.toArray()
                .then((response) => { this.setState({[table.name]: response})})
                .catch((error) => { this.setState({[table.name]: null })})
        }.bind(this)); // bind this to for each otherwise you lose scope, this being DeveloperScreen component
    }

    _renderConfigItem =  (name, value) => {
        return(
            <tr>
                <td style={{ textAlign: 'left', borderBottom: '1px dashed #00B9B0', fontSize: '14px'}} valign={'top'}>{name}</td>
                <td style={{ textAlign: 'right', borderBottom: '1px dashed #00B9B0', fontSize: '14px'}} valign={'top'}>{ value || 'Not Set'}</td>
            </tr>
        )
    }

    _renderSeedButtons = () => {
        return db.tables.map((table, index) =>{
            return(
                <Button
                    key={index}
                    className={'button--green'}
                    style={{width: '100%', marginTop: '15px', textAlign:'left'}}
                    onClick={() => this._seedIndexDB(table.name)}
                >
                    Seed IndexDB {table.name.charAt(0).toUpperCase() + table.name.slice(1)}
                </Button>
            );
        });

    }

    _renderHeading = (heading_name) => {
        return(
            <>
                <h1 style={{ fontSize: '16px', fontWeight: '800', paddingTop: '50px'}}>{heading_name}</h1>
                <hr />
            </>
        )
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const {  } = dataContext;
                if(!this.state.isLoading) {
                    return(
                        <>
                            <Container>
                                <Row>
                                    <Col style={{textAlign: 'center'}}>
                                        { this._renderHeading('IndexDB Commands')}
                                        <Button className={'button--green'} style={{width: '100%', textAlign: 'left'}} onClick={this._destroyIndexDB}>Destroy Table IndexDB</Button>
                                        <Button className={'button--green'} style={{width: '100%', marginTop: '15px', textAlign: 'left'}} onClick={this._initIndexDB}>Init Table IndexDB</Button>
                                        { this._renderSeedButtons() }
                                    </Col>
                                    <Col style={{textAlign: 'center'}}>
                                        { this._renderHeading('IndexDB Stats')}
                                        <table style={{width:'100%'}}>
                                            <tbody>
                                                { this._renderConfigItem('Total Categories', this.state?.categories?.length)}
                                                { this._renderConfigItem('Total Groups', this.state?.groups?.length)}
                                                { this._renderConfigItem('Total Products', this.state?.products?.length)}
                                                { this._renderConfigItem('Total Customer', this.state?.customer?.length)}
                                                { this._renderConfigItem('Total Locker', this.state?.locker?.length)}
                                            </tbody>
                                        </table>
                                        { this._renderHeading('Current Config Settings')}
                                        <h6 style={{ textAlign:'left' }}>Key</h6>
                                        <div style={{ textAlign:'left' }}>
                                            c: config item<br/>
                                            n: node environment item
                                        </div>
                                        <hr />
                                        <table style={{width:'100%'}}>
                                            <tbody>
                                                { this._renderConfigItem('n: environment', process.env.NODE_ENV)}
                                                { this._renderConfigItem('c: app_env', CONFIG?.env?.app_env)}
                                                { this._renderConfigItem('c: APIVersion', CONFIG?.env?.defaultAPIVersion)}
                                                { this._renderConfigItem('c: app_name', CONFIG?.env?.app_name)}
                                                { this._renderConfigItem('c: host-production', CONFIG?.host?.production)}
                                                { this._renderConfigItem('c: host-staging', CONFIG?.host?.staging)}
                                                { this._renderConfigItem('c: host-localhost', CONFIG?.host?.localhost)}
                                                { this._renderConfigItem('c: assets-production', CONFIG?.assets?.production)}
                                                { this._renderConfigItem('c: assets-staging', CONFIG?.assets?.staging)}
                                                { this._renderConfigItem('c: hero-words', CONFIG?.hero?.words.map((word) => `${word}`).join('\n'))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Container>

                        </>
                    )
                }
                if(this.state.isLoading) {
                    return(
                        <>
                            <LoadingSpinner />
                        </>
                    )
                }
            }}</DataContext.Consumer>
        );
    };
};

DeveloperScreen.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();

    return <DeveloperScreen {...props} navigation={navigation} params={params} />;
}
