import React from 'react';
import './ProductGridItem.scoped.css'

import Icon from "../Icon/Icon";
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

// context
import { DataContext } from "../../context/DataContext";
import {truncateWithEllipses} from "../../Helpers";

class ProductGridItem extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {
        super( props );
        this.state = {
            imagePath: 'https://via.placeholder.com/150'
        };
    }

    _init = async () => {
        if (this._isMounted) {
            await this.context.getProductAssetsById(this.props.gridItem.Id).then(data =>{

                if(data && data?.length > 0){
                    this.setState({
                        primaryImage: '/assets/products/' + data[0].path
                    })
                } else{
                    this.setState({
                        primaryImage: '/assets/products/ymjronP3JYv9ilRz4wik83StyxDi4X-metaZXpnaWYuY29tLWdpZi1tYWtlci53ZWJw-.webp'
                    })
                }
            });
// console.log(this.context.productAssetsById);
//             if(this.context.productAssetsById > 0){
//                 this.setState({
//                     primaryImage: '/assets/products/' + this.context.productAssetsById[0][0].path
//                 })
//             } else{
//                 this.setState({
//                     primaryImage: '/assets/products/ymjronP3JYv9ilRz4wik83StyxDi4X-metaZXpnaWYuY29tLWdpZi1tYWtlci53ZWJw-.webp'
//                 })
//             }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    render() {
        return (
            <>
                <Card className={'gridItem border-0'}>
                    <Card.Img variant="top" src={ this.state.primaryImage } />
                    <Card.Body>
                        <Card.Title className={'product-title text-center font-weight-bold mb-2 mt-2'}>{this.props.gridItem.name}</Card.Title>
                        {   (this.props.showDescription === undefined || this.props.showDescription === true) &&
                            <Card.Text className={'text-center font-weight-normal'} dangerouslySetInnerHTML={{__html: truncateWithEllipses(this.props.gridItem.description) }} />
                        }
                        {
                            (this.props.showButtons === undefined || this.props.showButtons === true) &&
                            <Button variant={'primary'} className={'button--red'}>EXPLORE</Button>
                        }
                    </Card.Body>
                </Card>
            </>
        )
    }
}

ProductGridItem.contextType = DataContext;

export default function( props ) {
    return <ProductGridItem {...props} />;
}


