import React from 'react';

// context
import { DataContext } from "../context/DataContext";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import Hero from "../components/Hero/Hero";
import Intro from "../components/Intro/Intro";

class HomeScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getAllCategories();
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <DataContext.Consumer>{( dataContext ) => {
                const { categoryList } = dataContext;
                if(this.state.isLoading){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading && categoryList != null){
                    return (
                        <>
                            <Hero video_url={'/assets/videos/elis_presentation_video.mp4'} />
                            <Intro categoryList={categoryList} />
                        </>
                    )
                }
            }}</DataContext.Consumer>
        );
    };
};

HomeScreen.contextType = DataContext;

export default function( props ) {
    return <HomeScreen {...props} />;
}
