import React, { Component, createContext } from 'react';

// services
import * as AuthService from '../services/auth';

// helpers
import { logError } from '../Helpers'

export const AuthContext = createContext();

class AuthContextProvider extends Component {

    _isMounted = false;

    constructor( props ) {
        super(props);

        this.state = {
            isAuthenticated: false,
            authenticationRequired: false
        }
    }

    /**
     * login with user supplied email and password
     * @returns
     */
    login = async (email, password) => {
        return new Promise(( resolve, reject ) => {
            AuthService.login( email, password )
                .then( data => {
                    resolve( data );
                })
                .catch( error => {
                    const errorDetail = {
                        error: error
                    };

                    logError( 'Login error located in the AuthService', 'AuthContext->login', errorDetail );
                    reject( error );
                });
        });
    };

    render () {
        return (
            <AuthContext.Provider value={{
                ...this.state,
            }}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export default AuthContextProvider;
